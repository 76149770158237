import React, {useEffect, useState} from 'react'

export default function DigitalSATAnswerTable(props){
    const currTest = props.test
    const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= 850);

    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    },[])
    const handleResize = () => {setIsSmallWidth(window.innerWidth <= 850)}

    const getDate = (date) => {
        const mdy = date.split("/")
        const month = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ][mdy[0]-1]
        const day = mdy[1]
        const year = mdy[2]
        const suffix = ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (day - (day % 10) !== 10) * day % 10]
        return `${month} ${day}${suffix} ${year}`
    }

    if (currTest === '') return (<></>)
    return(
        <>
            <h3>Date: {getDate(currTest.date)}</h3>
            <div className="scroll-container">
                <div>
                    <h3>Reading/Writing (Mod1):</h3>
                    <table className="answer-key-table">
                        <tbody className="answer-key-table-body">
                            <tr className="answer-key-table-row">
                                <td>{isSmallWidth ? "Q" : "Question"}</td>
                                {currTest.mod1_graded.map((bool,i)=>{
                                    return <td key={i} value={i} className="answer-key-table-element">{i+1}</td>
                                })}
                            </tr>
                            <tr className="answer-key-table-row">
                                <td style={{paddingRight:"0.5rem"}}>{isSmallWidth ? "A" : "Your Answer"}</td>
                                {currTest.mod1_answers.split(",").map((answer, i) => {
                                    return <td key={i} value={answer} className="answer-key-table-element" style={currTest.mo1_graded === null ? {}:{backgroundColor: currTest.mod1_graded[i] ? null:'red'}}>{answer}</td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Reading/Writing (Mod2): </h3>
                    <table className="answer-key-table">
                        <tbody className="answer-key-table-body">
                            <tr className="answer-key-table-row">
                                <td>{isSmallWidth ? "Q " : "Question"}</td>
                                {currTest.mod2_graded.map((bool,i)=>{
                                    return <td key={i} value={i} className="answer-key-table-element">{i+1}</td>
                                })}
                            </tr>
                            <tr className="answer-key-table-row">
                                <td style={{paddingRight:"0.5rem"}}>{isSmallWidth ? "A" : "Your Answer"}</td>
                                {currTest.mod2_answers.split(",").map((answer, i) => {
                                    return <td key={i} value={answer} className="answer-key-table-element" style={currTest.mod2_graded === null ? {}:{backgroundColor: currTest.mod2_graded[i] ? null:'red'}}>{answer}</td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Math (Mod3):</h3>
                    <table className="answer-key-table">
                        <tbody className="answer-key-table-body">
                            <tr className="answer-key-table-row">
                                <td>{isSmallWidth ? "Q " : "Question"}</td>
                                {currTest.mod3_graded.map((bool,i)=>{
                                    return <td key={i} value={i} className="answer-key-table-element">{i+1}</td>
                                })}
                            </tr>
                            <tr className="answer-key-table-row">
                                <td style={{paddingRight:"0.5rem"}}>{isSmallWidth ? "A" : "Your Answer"}</td>
                                {currTest.mod3_answers.split(",").map((answer, i) => {
                                    return <td key={i} value={answer} className="answer-key-table-element" style={currTest.mod3_graded === null ? {}:{backgroundColor: currTest.mod3_graded[i] ? null:'red'}}>{answer}</td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Math (Mod4):</h3>
                    <table className="answer-key-table">
                        <tbody className="answer-key-table-body">
                            <tr className="answer-key-table-row">
                                <td>{isSmallWidth ? "Q " : "Question"}</td>
                                {currTest.mod4_graded.map((bool,i)=>{
                                    return <td key={i} value={i} className="answer-key-table-element">{i+1}</td>
                                })}
                            </tr>
                            <tr className="answer-key-table-row">
                                <td style={{paddingRight:"0.5rem"}}>{isSmallWidth ? "A" : "Your Answer"}</td>
                                {currTest.mod4_answers.split(",").map((answer, i) => {
                                    return <td key={i} value={answer} className="answer-key-table-element" style={currTest.mod4_graded === null ? {}:{backgroundColor: currTest.mod4_graded[i] ? null:'red'}}>{answer}</td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}