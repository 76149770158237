import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import SAT from "./Components/SAT"
import ACT from "./Components/ACT"
import DigitalSAT from './Components/DigitalSAT'
import VocabPracticeHistory from './Components/VocabPracticeHistory'
import './StyleComponents/Dashboard.css'
import {useLogin} from "../LoginContext"
import DashboardNew from './Components/DashboardNew'

export default function Dashboard(){
    const [student, setStudent] = useState('')
    const [testList, setTestList] = useState('')
    const [testType, setTestType] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const {setIsLoggedIn} = useLogin()
    const navigate = useNavigate()
    // eslint-disable-next-line
    useEffect(()=>{
        setStudent(getStudent())
    }, [])
    
    async function getStudent(){
        if (localStorage.getItem('user') === null || localStorage.getItem('user') === ''){
            alert("Please login")
            navigate("/Login")
        }
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers: {
                    "JWT":("Bearer "+localStorage.getItem('user'))
                }
            })
            setStudent(response.data.user)
            if (response.data.user.role === 7){
                navigate("/Admin")
                return
            }
            if (response.data.user.role === 3){
                navigate("/TeacherDashboard")
                return
            }
            if (response.data.user.role === 2){
                navigate("/ParentDashboard")
                return
            }
            localStorage.setItem('user', response.data.JWT)
            setIsLoading(false)
            return response.data
        } catch(err){
            alert('Please login');
            localStorage.clear('user')
            setIsLoggedIn(false)
            navigate("/Login");
        }
        if (student === null){
            localStorage.clear('user')
            setIsLoggedIn(false)
            navigate("/Login");
        }
    }

    const changeTest = (e) =>{
        if (e.target.value === "SAT"){
            setTestList(student.SAT);
            setTestType("SAT");
        } else if (e.target.value === "ACT"){
            setTestList(student.ACT);
            setTestType("ACT");
        } else if (e.target.value === "Digital SAT"){
            setTestList(student.Digital_SAT)
            setTestType("DigitalSAT")
        } else if (e.target.value === "PSAT"){
            setTestList(student.PSAT)
            setTestType("PSAT")
        } else if (e.target.value === "Vocab"){
            console.log(student)
            setTestList(student.Vocab)
            setTestType("Vocab")
        }
    }
    
    const getTestType = () => {
        if (testType === "SAT"){
            if (testList) testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
            return <SAT testList={testList}/>
        } else if (testType === "ACT"){
            if (testList) testList.sort(function(a,b){return new Date(a.date) - new Date(b.date)})
            return <ACT testList={testList}/>
        } else if (testType === "DigitalSAT"){
            if (testList) testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
            return <DigitalSAT testList={testList}/>
        } else if (testType === "PSAT"){
            if (testList) testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
            return <DigitalSAT testList={testList} isPSAT={true}/>
        } else if (testType === "Vocab"){
            return <VocabPracticeHistory vocabList={testList}/>
        }
        return <></>
    }

    const goToDigitalTesting = () => {
        const newTab = window.open("https://www.tenaflyprepacademy.com/DigitalTesting", "_blank")
        newTab.focus()
    }

    if (isLoading) return(<h2 className="loading">Checking Credentials...</h2>)
    else{
        return (    
            <>
                <DashboardNew student={student}/>
            </>
        );
    }
}