import React, {useState, useEffect, useRef} from 'react'
import './FlippableCard.css'
/*
https://github.com/WebDevSimplified/React-Flashcard-App/blob/master/src/Flashcard.js
https://github.com/WebDevSimplified/React-Flashcard-App/blob/master/src/App.css
https://www.youtube.com/watch?v=hEtZ040fsD8
*/

export default function FlippableCard(props){
    const[flipped, setFlipped] = useState(false)
    const[height, setHeight] = useState('')

    const frontRef = useRef()
    const backRef = useRef()

    const setMaxHeight = () => {
        setHeight(Math.max(frontRef.current.getBoundingClientRect().height,
            backRef.current.getBoundingClientRect().height))
    }

    useEffect(()=>{
        window.addEventListener('resize', setMaxHeight)
        setMaxHeight()
    }, [])

    return(
        <>
            <div className="flippable-card-container">
                <div className={`flippable-card ${flipped ? 'flipped' : ''}`}
                    style={{height: height}}
                    onClick={()=>setFlipped(!flipped)}
                >
                    <div className="front" ref={frontRef}>
                        {props.front}
                        {props.hasButton ? <button className="flippable-card-button">{props.button}</button>:<></>}
                    </div>
                    <div className="back" ref={backRef}>
                        {props.back}
                        <button className="flippable-card-button">Click to flip back</button>
                    </div>
                </div>
            </div>
        </>
    )
}