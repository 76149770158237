import React, {useState} from 'react'
import axios from 'axios'
import './AssignHomework.css'

export default function GiveVocabSubmissions(){
    const [studentList, setStudentList] = useState([])
    const [assignedStudents, setAssignedStudents] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const [num, setNum] = useState("")

    async function getStudents(){
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/allStudents",{
                headers: {"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            setStudentList(response.data)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    async function assignHomework(){
        if (assignedStudents.length === 0){
            alert("You have not chosen students to assign homework for")
            return
        }
        if (num.length === 0 || !/^[0-9]+$/.test(num) || isNaN(parseInt(num))){
            alert("Please enter a valid amount of submission attempts you will be assigning.")
            return
        }
        const data = {
            "JWT": `Bearer ${localStorage.getItem('user')}`,
            "student_emails": assignedStudents.map((stu)=>stu.email).join(","),
            "add_submissions": num
        }
        try{
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/vocab/add",data)
            console.log(response.data === 0)
            if (response.data === 0){
                alert("You have added submissions for your students.")
                setAssignedStudents([])
                setNum("")
            } else{
                alert("Something unexpected went wrong")
            }
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    function addStudent(stu){
        for (var i = 0; i < assignedStudents.length; i++){
            if (stu.email === assignedStudents[i].email) return
        }
        setAssignedStudents((prevState)=>[...prevState, stu])
    }

    function removeStudent(stu){
        for (var i = 0; i < assignedStudents.length; i++){
            if (stu.email === assignedStudents[i].email){
                var list = [...assignedStudents]
                list.splice(i,1)
                setAssignedStudents(list)
                return
            }
        }
    }

    return(
        <>
            <div className="choose-students-wrapper">
                <div className="select-students-container">
                    <button onClick={(e)=>getStudents()}>Get All Students</button>
                    <div><label htmlFor="s1">Search: </label><input id="s1" onChange={(e)=>setSearch(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {studentList.filter((stu)=>{
                            return search === '' ? stu : (`${stu.first_name}${stu.last_name}${stu.email}`).toLowerCase().includes(search)
                        }).map((stu)=>{
                            return <li onClick={()=>addStudent(stu)} key={stu.email}>{`${stu.first_name} ${stu.last_name} (${stu.email})`}</li>
                        })}
                    </ul>
                </div>
                <div className="select-students-container">
                    <span>Assigned Students</span>
                    <div><label htmlFor="s2">Search: </label><input id="s2" onChange={(e)=>setSearch2(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {assignedStudents.filter((stu)=>{
                            return search2 === '' ? stu : (`${stu.first_name}${stu.last_name}${stu.email}`).toLowerCase().includes(search2)
                        }).map((stu)=>{
                            return <li onClick={()=>removeStudent(stu)} key={stu.email}>{`${stu.first_name} ${stu.last_name} (${stu.email})`}</li>
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <label htmlFor="sb"># of submissions to add: </label>
                <input id="sb" value={num} onChange={(e)=>setNum(e.target.value)}/>
            </div>
            <button onClick={()=>assignHomework()}>Add Submissions</button>
        </>
    )
}