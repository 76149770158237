import React, {useState, useEffect} from 'react';
import '../StyleComponents/ACT.css'

export default function ACT({testList}) {
    const[currTest, setCurrTest] = useState('');
    const[smallWidth, setSmallWidth] = useState(window.innerWidth < 1000)

    const handleResize = () => {setSmallWidth(window.innerWidth < 1000)}
    useEffect(()=>{
        window.addEventListener('resize',handleResize)
        return () => {window.removeEventListener('resize',handleResize)}
    },[])

    const chooseTest = (e) => {
        for (var i = 0 ; i < testList.length; i++){
            if (testList[i].date === e.target.value){
                setCurrTest(testList[i])
                return;
            }
        }
        setCurrTest('');
    }

    return(
        <>
            <h3>Pick your ACT test to view: &nbsp;
                <select onChange={chooseTest}>
                    <option value = ""/>
                    {testList.slice(0).reverse().map((allTests, i) => {
                        return <option key={i} value={allTests.date}>{allTests.date}</option>;
                    })}
                </select>
            </h3>
            {currTest ? 
                <div>
                    <div>
                        <table className="act-results-table">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td style={{fontWeight:"bold"}}>Raw Score</td>
                                    <td style={{fontWeight:"bold"}}>Scaled Score</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:"bold"}}>English</td>
                                    <td>{`${currTest.raw_scores[0]}/75`}</td>
                                    <td><span style={{fontSize:"2rem",fontWeight:"bold"}}>{currTest.scaled_scores[0]}</span><span>/36</span></td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:"bold"}}>Math</td>
                                    <td>{`${currTest.raw_scores[1]}/60`}</td>
                                    <td><span style={{fontSize:"2rem",fontWeight:"bold"}}>{currTest.scaled_scores[1]}</span><span>/36</span></td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:"bold"}}>Reading</td>
                                    <td>{`${currTest.raw_scores[2]}/40`}</td>
                                    <td><span style={{fontSize:"2rem",fontWeight:"bold"}}>{currTest.scaled_scores[2]}</span><span>/36</span></td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:"bold"}}>Science</td>
                                    <td>{`${currTest.raw_scores[3]}/40`}</td>
                                    <td><span style={{fontSize:"2rem",fontWeight:"bold"}}>{currTest.scaled_scores[3]}</span><span>/36</span></td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:"bold",fontSize:"1.5rem"}}>Total</td>
                                    <td></td>
                                    <td><span style={{fontSize:"2rem",fontWeight:"bold"}}>{currTest.scaled_scores[4]}</span><span>/36</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span><div className="orange-square"/>Incorrect</span><br/><br/>
                    {smallWidth ? 
                    <div className="act-answer-container-vertical">
                        <div>
                            <span>English</span>
                            <table>
                                <tbody>
                                    {currTest.english_answers.split(",").map((e,i)=>{
                                        return <tr>
                                            <td>{i+1}</td>
                                            <td style={{background:(currTest.english_graded[i] ? "" : "tomato")}}>{e}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <span>Math</span>
                            <table>
                                <tbody>
                                    {currTest.math_answers.split(",").map((e,i)=>{
                                        return <tr>
                                            <td>{i+1}</td>
                                            <td style={{background:(currTest.math_graded[i] ? "" : "tomato")}}>{e}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <span>Reading</span>
                            <table>
                                <tbody>
                                    {currTest.reading_answers.split(",").map((e,i)=>{
                                        return <tr>
                                            <td>{i+1}</td>
                                            <td style={{background:(currTest.reading_graded[i] ? "" : "tomato")}}>{e}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <span>Science</span>
                            <table>
                                <tbody>
                                    {currTest.science_answers.split(",").map((e,i)=>{
                                        return <tr>
                                            <td>{i+1}</td>
                                            <td style={{background:(currTest.science_graded[i] ? "" : "tomato")}}>{e}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> :
                    <div className="act-answer-container-horizontal">
                        <span>English</span>
                        <table>
                            <tbody>
                                <tr>
                                    {currTest.english_answers.split(",").map((e,i)=>{
                                        return <td>{i+1}</td>
                                    })}
                                </tr>
                                <tr>
                                    {currTest.english_answers.split(",").map((e,i)=>{
                                        return <td style={{background:(currTest.english_graded[i] ? "" : "tomato")}}>{e}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        <span>Math</span>
                        <table>
                            <tbody>
                                <tr>
                                    {currTest.math_answers.split(",").map((e,i)=>{
                                        return <td>{i+1}</td>
                                    })}
                                </tr>
                                <tr>
                                    {currTest.math_answers.split(",").map((e,i)=>{
                                        return <td style={{background:(currTest.math_graded[i] ? "" : "tomato")}}>{e}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        <span>Reading</span>
                        <table>
                            <tbody>
                                <tr>
                                    {currTest.reading_answers.split(",").map((e,i)=>{
                                        return <td>{i+1}</td>
                                    })}
                                </tr>
                                <tr>
                                    {currTest.reading_answers.split(",").map((e,i)=>{
                                        return <td style={{background:(currTest.reading_graded[i] ? "" : "tomato")}}>{e}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        <span>Science</span>
                        <table>
                            <tbody>
                                <tr>
                                    {currTest.science_answers.split(",").map((e,i)=>{
                                        return <td>{i+1}</td>
                                    })}
                                </tr>
                                <tr>
                                    {currTest.science_answers.split(",").map((e,i)=>{
                                        return <td style={{background:(currTest.science_graded[i] ? "" : "tomato")}}>{e}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
                 : 
                <></>
            }
            <div className="act-history-container">
                <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>All Tests</span>
                <table className="act-history-table">
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td>English</td>
                            <td>Math</td>
                            <td>Reading</td>
                            <td>Science</td>
                            <td>Total</td>
                        </tr>
                        {testList.map((test,i)=>{
                            return <tr>
                                <td className="act-history-td">{test.date}</td>
                                <td className="act-history-td">{test.scaled_scores[0]}</td>
                                <td className="act-history-td">{test.scaled_scores[1]}</td>
                                <td className="act-history-td">{test.scaled_scores[2]}</td>
                                <td className="act-history-td">{test.scaled_scores[3]}</td>
                                <td className="act-history-td">{test.scaled_scores[4]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}