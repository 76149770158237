import React, {useEffect} from 'react'
import './StyleComponents/BookClub.css'
import Contacts from './Components/Contacts'

export default function BookClub(){
    useEffect(()=>{   
        const options = {
            rootMargin: '0px',
            threshold: 0.1
        }
  
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)
  
        const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
        hiddenElements.forEach((element)=>observer.observe(element))
  
        return () => {
            observer.disconnect()
        }
    },[])

    return(
        <>
            <div style={{overflow:"hidden"}}>
                <div className="book-club-title">
                    <span>Book Club</span>
                </div>
                <h3 style={{padding:"0 1rem 0 1rem"}}>Current Reading: "The Cheating Cell: How Evolution Helps Us Understand and Treat Cancer" by Athena Aktipis</h3>
                <h4>Google Classroom <a href="https://classroom.google.com/u/0/c/NTc4NTU1NDQ0MjEy" target="_blank" rel="noreferrer">link</a> for Members</h4>
                <div className="book-club-divider">
                    <img src="open-book.svg" alt="book"/>
                    <div className="orange-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                    <div className="teal-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                </div>
                <div className="book-club-content-container">
                    <div className="book-club-content-title">
                        <span><div className="book-club-title-background rose"/>Mission</span>
                    </div>
                    <div className="book-club-content">
                        <p>
                            We're on a mission to kindle a love for reading in students. 
                            Books open doors to <b>adventure, knowledge, and personal growth</b>.
                        </p>
                        <p>
                            Our goal is to make reading enjoyable and enriching for all. 
                            Join our book club to nurture a lifelong passion for literature, one page at a time.
                        </p>
                    </div>
                </div>
                <div className="book-club-divider">
                    <img src="open-book.svg" alt="book"/>
                    <div className="teal-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                    <div className="orange-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                </div>
                <div className="book-club-content-container">
                    <div className="book-club-content-title toggle-on">
                        <span><div className="book-club-title-background violet"/>Choice of Reading</span>
                    </div>
                    <div className="book-club-content">
                        <p>
                            We select books that <b>entertain, challenge, and expand young minds.</b> 
                            Our diverse, thought-provoking titles encourage deep exploration of themes and characters, broadening perspectives. 
                        </p>
                        <p>
                            Join our literary journey for critical thinking and empathy beyond the pages.
                        </p>
                    </div>
                    <div className="book-club-content-title toggle-off">
                        <span><div className="book-club-title-background violet"/>Choice of Reading</span>
                    </div>
                </div>
                <div className="book-club-divider">
                    <img src="open-book.svg" alt="book"/>
                    <div className="orange-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                    <div className="teal-divider-circle"/>
                    <img src="open-book.svg" alt="book"/>
                </div>
                <div className="book-club-content-container">
                    <div className="book-club-content-title">
                        <span><div className="book-club-title-background green"/>Schedule</span>
                    </div>
                    <div className="book-club-content">
                        <p>
                            Our <b>weekly Zoom meetings</b> go beyond gatherings; they breathe life into books. 
                        </p>
                        <p>
                            Get ready to dive into each book with <b>pre-reading questions</b>, your roadmap to explore details and themes. 
                            These questions foster active reading, encouraging critical thinking and deep engagement with the text.
                        </p>
                        <p>
                            Additionally, we maintain a vibrant discussion forum on <b>Google Classroom</b>, providing a platform for ongoing conversations and deeper exploration of the readings.
                        </p>
                        <p>
                            <u style={{cursor:"pointer"}} onClick={()=>window.scrollTo({top:document.body.scrollHeight, behavior: "smooth"})}>Contact us</u> for more information.
                        </p>
                    </div>
                </div>
            </div>
            
            <br/>
            <Contacts/>
        </>
    )
}