import React, {useEffect} from "react";
import CourseCard from './Components/CourseCard'
import Contacts from './Components/Contacts'

export default function Courses() {
    useEffect(()=>{
        const options = {
          rootMargin: '0px',
          threshold: 0.05
        }
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible')
            }
          })
        }, options)
    
        const hiddenElements = document.querySelectorAll('.course-card, .contacts-container')
        hiddenElements.forEach((element)=>observer.observe(element))
    
        return () => {
          observer.disconnect()
        }
    },[])

  return (
    <>
      <div className="course-card-wrapper">
        <CourseCard 
            title={"USA Computing Olympiad (USACO)"} 
            hasDescription={true}
            description={[<p>The USA Computing Olympiad is a problem solving competition 
                where answers are coded in <b>C++, Java, or Python</b>. This competition is 
                hosted to seek talent to represent USA for the International Olympiad of Informatics.</p>,
                <p>The competition is broken down into many tiers of difficulty 
                and so there are numerous awards to be given out.</p>]}
            whatWeTeach={[<p>We teach <b>data structures and algorithms</b> commonly used 
                in competitive programming. We also teach problem solving 
                techniques that are often unique to competitive programming.</p>,
                <p>Since all languages are permitted in competitive programming, 
                we do not require you to learn C++.</p>
            ]}
            hasLink={true}
            to={"http://usaco.org/index.php"}/>
        <CourseCard 
            title={"American Math Competition (AMC)"}
            hasDescription={true}
            description={[<p>The American Math Competition is a once a year math 
                competition held by the Mathematical Association of 
                America to recruit the brightest minds around the nation. 
                This competition is designed to test problem solving and creativity.</p>,
                <p>The test is broken down into 3 age categories:</p>,
                <ul style={{paddingLeft: '1em'}}>
                    <li>8th grade and below - AMC8</li>
                    <li>10th grade and below - AMC10</li>
                    <li>12th grade and below - AMC12</li>
                </ul>]}
            whatWeTeach={[
                <p>We teach students <b>advanced math theories and problem solving techniques</b> that 
                are never taught in the common core curriculum.</p>,
                <p>If your school does not administer the AMC, our 
                academy is able to accommodate you.</p>
            ]}
            hasLink={true}
            to={"https://www.maa.org/math-competitions/about-amc"}/>
        <CourseCard 
            title={"Columbia Science Honors Program (SHP)"}
            hasDescription={true}
            description={[<p>The Columbia Science Honors Program offers students 
                to attend <b>lectures at Columbia University every Saturday</b>. 
                These lectures teach students in depth topics of STEM that 
                go far beyond any high school curriculum. Popular courses 
                include algorithms, particle physics, epidemiology, etc.</p>,
                <p>Columbia chooses only the best students each year to ensure 
                that their students are able to follow their lecture material 
                and pacing.</p>]}
            whatWeTeach={[
                <p>We guide you through the application process as it progresses.</p>,
                <p>We also teach a variety of <b>STEM subjects</b> to prepare you for the 
                entrance exam.</p>
            ]}
            hasLink={true}
            to={"http://www.columbia.edu/cu/shp/"}/>
      </div>
      <br/>
      <Contacts/>
    </>
  );
}
