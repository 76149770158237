import React, {useState} from 'react'
import './VocabPracticeHistory.css'

export default function VocabPracticeHistory({vocabList}){
    const [currVocab, setCurrVocab] = useState(null)
    console.log(vocabList)
    if (vocabList === undefined || vocabList === ""){return <></>}
    return(
        <>
            <div>
                <h1>Vocab Training History</h1>
                <div style={{padding:"0 1rem 0 1rem"}}>
                    <table className="vocab-history-table">
                        <tbody>
                            <tr style={{fontWeight:"bold"}}>
                                <td style={{borderBottom:"2px black solid",borderRight:"1px black solid"}}>Date and Time</td>
                                <td style={{borderBottom:"2px black solid"}}>Grade</td>
                            </tr>
                            {vocabList.map((vocab)=>{
                                return <tr className="vocab-history-tr" onClick={()=>setCurrVocab(vocab)}>
                                    <td style={{borderRight:"1px black solid",borderTop:"1px black solid"}}>{vocab.date.substring(0,vocab.date.length-3)}</td>
                                    <td style={{borderTop:"1px black solid"}}>{vocab.grade}/6</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                
                <div style={{textAlign:"left",paddingLeft:"1rem",fontSize:"0.75rem",color:"darkred"}}><span>**Click dates to see more detail</span></div>
                
            </div>
            {currVocab === null ? <></> : 
            <div className="curr-vocab-container">
                <h3>Viewing details for {currVocab.date.substring(0,currVocab.date.length-3)}</h3>
                <span>Your Submission: </span>
                <p>{currVocab.student_submission}</p>
                <span>AI Response: </span>
                <p>{currVocab.ai_response}</p>
            </div>}
        </>
    )
}