import React, {useState} from 'react'
import axios from 'axios'

export default function GetDigitalSATAnswers(){
    const [studentList, setStudentList] = useState([]);
    const [testList, setTestList] = useState([]);

    const getStudents = async () => {
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/allStudents", {
                headers:{
                    "JWT" : `Bearer ${localStorage.getItem('user')}`
                }
            })
            setStudentList(response.data)
            console.log(response.data)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    const getTests = async () => {
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/allDigitalSAT", {
                headers: {
                    "JWT" : `Bearer ${localStorage.getItem('user')}`
                }
            })
            setTestList(response.data)
            console.log(response.data)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }

    function changeTest(e){
        e.preventDefault()
        if (e.target.value === '') return
        for (var i = 0; i < testList.length; i++){
            //find test
        }
    }

    return(
        <>
            <button onClick={getStudents}>Get All Students</button>
            <button onClick={getTests}>Get All Tests</button>
            <select onChange={(e)=>changeTest(e)}>

            </select>
            <div className="student-selecting-wrapper">
                <div className="student-selecting-container">

                </div>
                <div className="student-selecting-container">
                    
                </div>
            </div>
        </>
    )
}