import React from 'react'
import './CourseCard.css'

export default function CourseCard(props){
    return(
        <>
            <div className="course-card">
                <div className="course-card-body">
                    <h2>{props.title}</h2>
                    {props.hasDescription ? props.description.map((text, i) => {
                        return <p key={i} className="course-card-description">{text}</p>
                    }) : <></>}
                    {props.hasLink ? <a className="course-card-link" href={props.to} target="_blank" rel="noreferrer">Official Website</a> : <></>}
                    <h3>What We Teach</h3>
                    {props.whatWeTeach.map((text,i)=>{
                        return <p key={i} className="course-card-description">{text}</p>
                    })}
                </div>
            </div>
        </>
    )
}