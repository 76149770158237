import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import HomeworkCalendar from '../Components/HomeworkCalendar'
import './GetAllHomeworkBundles.css'

export default function GetAllHomeworkBundles(){
    const [currBundle, setCurrBundle] = useState(null)
    const [selectedDate, setSelectedDate] = useState('')
    const [homeworkList, setHomeworkList] = useState([])
    const [homeworkListToday, setHomeworkListToday] = useState([])
    const [dates, setDates] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const refs = useRef([])

    useEffect(()=>{
        setHomeworkListToday([])
        homeworkList.forEach(hw=>{
            if (hw.due_date === selectedDate){
                setHomeworkListToday((prevState)=>[...prevState,hw])
            }
        })
    }, [selectedDate])
    useEffect(()=>{
        if (currBundle && currBundle !== '') refs.current = refs.current.slice(0, currBundle.questions.length)
    }, [currBundle])
    
    async function getBundles(){
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/getAllHomeworkBundles",{
                headers: {"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            setHomeworkList(response.data)
            response.data.forEach((hw)=>{
                setDates((prevState)=>({...prevState, [hw.due_date]:true}))
            })
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    const tally = (studentAnswers) => {
        let count = 0
        for (var i = 0; i < studentAnswers.length; i++){
            var correct = false
            currBundle.questions[i].answer.split("~").map(c=>{
                if (c === studentAnswers[i]) correct = true
            })
            if (correct) count++
        }
        return count
    }

    const checkAnswer = (val, index) => {
        var correct = false
        currBundle.questions[index].answer.split("~").map((c)=>{
            if (c === val) correct = true
        })
        return correct
    }

    function scrollToQuestion(index){
        const position = refs.current[index].getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.1
        window.scrollTo({top: position, behavior: "smooth"})
    }

    return(
        <>
            <button onClick={()=>getBundles()}>Get All Homework Bundles</button>
            <div className="find-homework-wrapper">
                <div className="select-bundle-container">
                    <h3>Find By Date</h3>
                    <HomeworkCalendar setSelectedDate={setSelectedDate} complete={[]} incomplete={dates}/>
                    <div>
                        <span style={{fontWeight:"bold"}}>{selectedDate}</span>
                        <ul style={{listStyle:"none"}}>
                            {homeworkListToday.map(hw=>{
                                return <li key={`~${hw.name}`} onClick={()=>setCurrBundle(hw)}>{hw.name}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className="select-bundle-container">
                    <h3>Find by Name</h3>
                    <div><label htmlFor="search">Search: </label><input onChange={(e)=>setSearch(e.target.value.toLowerCase())} id="search"/></div>
                    <ul style={{listStyle:"none"}}>
                        {homeworkList.filter((hw)=>{
                            return (search === '' ? hw : hw.name.toLowerCase().includes(search))})
                            .map((hw)=>{
                                return <li key={hw.name} onClick={()=>setCurrBundle(hw)}>{hw.name}</li>
                        })}
                    </ul>
                    
                </div>
            </div>
            { currBundle ? 
                <div style={{padding:"1rem"}}>
                    <h1>{currBundle.name}</h1>
                    <div style={{paddingBottom:"1rem"}}><label htmlFor="s2">Find Student: </label><input onChange={(e)=>setSearch2(e.target.value.toLowerCase())} id="s2"/></div>
                    {currBundle.questions.map((hw,i)=>{
                        return <div className="homework-question-container" key={`!${hw.question_id}`} ref={el => refs.current[i] = el}>
                            <div className="homework-question-info">
                                <p>{`Question #${i+1}`}</p>
                                <p>{`Answer: ${hw.answer.split("~").join(" or ")}`}</p>
                                <p>{hw.category}</p>
                                <p>{hw.type}</p>
                                <p>{hw.difficulty === 1 ? "Easy" : (hw.difficulty === 2 ? "Medium" : "Hard")}</p>
                            </div>
                            <div className="homework-image-container">
                                <img src={hw.url} alt="q"/>
                            </div>
                            <div className="homework-student-answers">
                                <ul style={{listStyle:"none"}}>
                                    {Object.entries(currBundle.student_answers).filter(([key,value])=>{
                                        return (search2 === '' ? key : key.split(",")[0].toLowerCase().includes(search2))
                                    }).map(([key,value])=>{
                                        return <li style={{color:(checkAnswer(value.split("~")[i],i) ? "green" : "red")}} id={`#${key}`}>{`${key.split(",")[0]}: ${value.split("~")[i]}`}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    })}
                    {Object.entries(currBundle.student_answers).filter(([key,value])=>{
                        return (search2 === '' ? key : key.split(",")[0].toLowerCase().includes(search2))
                    }).map(([key,value])=>{
                        return <div className="student-answers">
                            <span style={{fontWeight:"bold"}}>{key.split(",")[0]}: </span>
                            {value.split("~").map((val,index)=>{
                                return <span style={{color:(checkAnswer(val,index) ? "green":"red")}}><b style={{cursor:"pointer"}} onClick={()=>scrollToQuestion(index)}>{`${index+1}) `}</b>{val} &nbsp;&nbsp;&nbsp;</span>
                            })}
                            <span>Grade: {tally(value.split("~"))}/{currBundle.questions.length}</span>
                        </div>
                    })}
                </div> :
                <></>
            }
        </>
    )
}