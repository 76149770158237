import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import HomeworkCalendar from './Components/HomeworkCalendar'
import {useLogin} from "../LoginContext"
import './StyleComponents/Homework.css'

export default function Homework(){
    const [student, setStudent] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString('en-US'))
    const [homeworkList, setHomeworkList] = useState({})
    const [currHomework, setCurrHomework] = useState('')
    const [complete, setComplete] = useState({})
    const [incomplete, setIncomplete] = useState({})
    const [studentAnswers, setStudentAnswers] = useState([])
    const refs = useRef([])
    const {setIsLoggedIn} = useLogin()
    const navigate = useNavigate()
    // eslint-disable-next-line
    useEffect(()=>{
        setStudent(getStudent())
    }, [])
    useEffect(()=>{
        if (currHomework !== '') refs.current = refs.current.slice(0,currHomework.questions.length)
    }, [currHomework])
    
    async function getStudent(){
        if (localStorage.getItem('user') === null || localStorage.getItem('user') === ''){
            alert("Please login")
            navigate("/Login")
        }
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers: {
                    "JWT":("Bearer "+localStorage.getItem('user'))
                }
            });
            setStudent(response.data.user)
            if (response.data.user.role === 7){
                navigate("/Admin")
                return;
            }
            if (response.data.user.role === 3){
                navigate("/TeacherDashboard")
                return;
            }
            if (response.data.user.role === 2){
                navigate("/ParentDashboard")
                return
            }
            localStorage.setItem('user', response.data.JWT)
            setIsLoading(false)
            setComplete({})
            setIncomplete({})
            setHomeworkList([])
            response.data.user.homework.forEach((homework) => {
                if (`${response.data.user.first_name} ${response.data.user.last_name},${response.data.user.email}` in homework.student_answers) {
                    setComplete((prevState) => ({...prevState, [homework.due_date]: true}))
                } else {
                    setIncomplete((prevState) => ({...prevState, [homework.due_date]: true}))
                }
                setHomeworkList((prevState) => ({
                    ...prevState,
                    [homework.due_date]: [...(prevState[homework.due_date] || []), homework],
                }))
            })
            return response.data
        } catch(err){
            alert('Please login');
            localStorage.clear('user')
            setIsLoggedIn(false)
            navigate("/Login");
        }
        if (student === null){
            localStorage.clear('user')
            setIsLoggedIn(false)
            navigate("/Login");
        }
    }

    const pickHomework = (hwbundle) => {
        if (currHomework !== '' && hwbundle.name === currHomework.name) return
        setCurrHomework(hwbundle)
        var list = []
        hwbundle.questions.forEach(()=>list.push(" "))
        setStudentAnswers(list)
    }

    function scrollToQuestion(index){
        const position = refs.current[index].getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.1
        window.scrollTo({top: position, behavior: "smooth"})
    }

    async function submitHomework(){
        if (localStorage.getItem('user') === null || localStorage.getItem('user') === ''){
            alert("Please login")
            navigate("/Login")
        }
        //todo: prevent students from putting ~ into frq answers
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/homework/grade',{
                "JWT":localStorage.getItem('user'),
                "answers":studentAnswers.join("~"),
                "homework_bundle_id":currHomework.name
            })
            if (response.data === 0){
                alert("Homework submitted.")
                getStudent()
            }
            else if (response.data === 10) alert("You were not found in the database, please try logging out and back in.")
            else if (response.data === 7) alert("This homework was not in our database.")
            else if (response.data === 5) alert("This homework doesn't match with the homework in our database. You potentially added tildas (~) to your free response answers")
            else alert("Unknown error. Try again later.")
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    const changeAnswer = (e,i) => {
        if (currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`]) return
        const updatedAnswers = [...studentAnswers]
        updatedAnswers[i] = e.target.value
        setStudentAnswers(updatedAnswers)
    }

    function getHomeworkToday(){
        if (homeworkList[selectedDate]){
            return (
                <div className="choose-homework-container">
                    {
                        homeworkList[selectedDate].map((hwbundle,i)=>{
                            return (
                                <div key={`${hwbundle.name}${i}`} style={{display:"flex",cursor:"pointer"}}>
                                    {hwbundle.student_answers[`${student.first_name} ${student.last_name},${student.email}`] ? 
                                        <div className="blue-circle"/> :
                                        <div className="green-circle"/>
                                    }
                                        <div onClick={()=>pickHomework(hwbundle)} key={`hw${i}`}>{`${i+1}. ${hwbundle.name}`}</div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        return <></>
    }

    function getHomeworkQuestions(){
        if (homeworkList[selectedDate]){
            return(
                <div className="homework-container">
                    <h2>{currHomework === '' ? "" : (`${currHomework.name} (Due: ${currHomework.due_date})`)}</h2>
                    {
                        currHomework === '' ? <></> : (currHomework.questions.map((question,i)=>{
                            return (
                                <div className="question-container" key={`hwq${i}`} ref={el => refs.current[i] = el}>
                                    <div className="homework-question-image-container">
                                        <img src={question.url} className="homework-question-image" alt="hwq"/>
                                    </div>
                                    <div className="student-response-container">
                                        <span>Question #{i+1}</span>
                                        <br/>
                                        {
                                            currHomework.questions[i].FRQ ? (
                                                currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`] ? 
                                                <input
                                                    className="homework-frq-input"
                                                    value={currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`].split("~")[i]}
                                                    disabled
                                                /> :
                                                <input maxLength="50" className="homework-frq-input" onChange={(e)=>changeAnswer(e,i)}/>)
                                                : 
                                                <form>
                                                    {["A","B","C","D"].map((letter)=>{
                                                        return <>
                                                            <input
                                                                type="radio"
                                                                value={letter}
                                                                key={`${i}${letter}`}
                                                                id={`${i}${letter}`}
                                                                className="homework-radio-input"
                                                                onChange={(e)=>changeAnswer(e,i)}
                                                                checked={currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`] ? (currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`].split("~")[i] === `${letter}`) : (studentAnswers[i] === `${letter}`)}
                                                            />
                                                            <label htmlFor={`${i}${letter}`}>{letter}</label>
                                                        </>
                                                    })}
                                                </form>
                                        }
                                    </div>
                                </div>
                            )
                        }))
                    }
                </div>
            )
        }
        return <></>
    }

    function getStudentAnswers(){
        if (currHomework){
            return (
                <>
                    <h3>Your Answers (Click to scroll to them)</h3>
                    <div className="all-answers-container">
                        {
                            studentAnswers.length > 0 ? 
                            <>
                                {studentAnswers.map((ans, i) => {
                                    return (
                                        <div className="answer-cell" key={`${i}sa`}>
                                            <span onClick={()=>scrollToQuestion(i)} style={{fontWeight:"bold",cursor:"pointer"}}>{`${i+1}. `}</span>
                                            <span>{currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`] ? `${currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`].split("~")[i]}` : `${studentAnswers[i]}`}</span>
                                        </div>
                                    )
                                })}
                            </> :
                            <>
                            </>
                        }
                    </div>
                    {currHomework.student_answers[`${student.first_name} ${student.last_name},${student.email}`] ? 
                        <></> :
                        <button className="submit-homework-button" onClick={()=>submitHomework()}>Submit Homework</button>
                    }
                </>
            )
        }
        return <></>
    }

    if (isLoading) return(<h2 className="loading">Checking Credentials...</h2>)
    else{
        return (    
            <>
                <div className="homework-page">
                    <h1> Hello {student.first_name} {student.last_name}</h1>
                    <HomeworkCalendar 
                        setSelectedDate={setSelectedDate}
                        complete={complete} 
                        incomplete={incomplete}
                    />
                    <div style={{display:"flex"}}>
                        <span>Todo:</span>
                        <div className="green-circle"/>
                        <span style={{marginLeft: "1rem"}}>Completed:</span>
                        <div className="blue-circle"/>
                    </div>
                    <h2>{`Date: ${selectedDate}`}</h2>
                    {getHomeworkToday()}
                    {getHomeworkQuestions()}
                    {getStudentAnswers()}
                </div>
            </>
        );
    }
}