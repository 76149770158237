import './ReviewDigitalSAT.css'
import React from 'react'

export default function ReviewDigitalSAT({test}){
    console.log(test)
    if (test === undefined || test === null || test === '') return <h2>No test to review</h2>
    return(
        <>
            <h2>Reading/Writing Module 1</h2>
            <div className="review-questions-grid-wrapper">
                {test.questions.filter((q) => {return q.module === 1 && q.mr === "Reading"})
                .sort((a,b)=>a.number-b.number)
                .map((q,i) => { return(
                    <div className="review-question-container">
                        <h3 className="review-question-number">{`Q${i+1}`}</h3>
                        <img src={q.url}></img>
                    </div>
                )})}
            </div>
            <h2>Reading/Writing Module 2</h2>
            <div className="review-questions-grid-wrapper">
                {test.questions.filter((q) => {return q.module === 2 && q.mr === "Reading"})
                .sort((a,b)=>a.number-b.number)
                .map((q,i) => { return(
                    <div className="review-question-container">
                        <h3>{`Q${i+1}`}</h3>
                        <img src={q.url}></img>
                    </div>
                )})}
            </div>
            <h2>Math Module 1</h2>
            <div className="review-questions-grid-wrapper">
                {test.questions.filter((q) => {return q.module === 1 && q.mr === "Math"})
                .sort((a,b)=>a.number-b.number)
                .map((q,i) => { return(
                    <div className="review-question-container">
                        <h3>{`Q${i+1}`}</h3>
                        <img src={q.url}></img>
                    </div>
                )})}
            </div>
            <h2>Math Module 2</h2>
            <div className="review-questions-grid-wrapper">
                {test.questions.filter((q) => {return q.module === 2 && q.mr === "Math"})
                .sort((a,b)=>a.number-b.number)
                .map((q,i) => { return(
                    <div className="review-question-container">
                        <h3>{`Q${i+1}`}</h3>
                        <img src={q.url}></img>
                    </div>
                )})}
            </div>
        </>
    )
}