import React, {useState, useEffect} from 'react';
import axios from 'axios';

export default function GetAllTests(){
    const [currTest, setCurrTest] = useState('')
    const [SATorACT, setSATorACT] = useState('')
    const [SATTestList, setSATTestList] = useState([])
    const [ACTTestList, setACTTestList] = useState([])

    useEffect(()=>{
        getSATs();
        getACTs();
    }, [])

    const getSATs = async () => {
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/answers/SAT', {
                headers: {"JWT":("Bearer "+localStorage.getItem('user'))}
            })
            setSATTestList(response.data)
        } catch(err){

        }

    }

    const getACTs = async () => {
        try{
            const response = await axios.get('httpe://www.tenaflyprepacademy.com/server/answers/ACT', {
                headers: {"JWT":("Bearer "+localStorage.getItem('user'))}
            })
            setACTTestList(response.data)
        } catch(err){

        }
    }

    const changeSAT = (e) => {
        setSATorACT("SAT")
        for (var i = 0; i < SATTestList.length; i++){
            if (SATTestList[i].test_id === e.target.value){
                setCurrTest(SATTestList[i])
                return
            }
        }
        setCurrTest('')
    }

    const changeACT = (e) => {
        setSATorACT("ACT")
        for (var i = 0; i < ACTTestList.length; i++){
            if (ACTTestList[i].test_id === e.target.value){
                setCurrTest(ACTTestList[i])
                return
            }
        }
        setCurrTest('')
    }

    return(
        <>
            <div>
                <br></br>
                SAT: 
                <select onChange={changeSAT}>
                    <option></option>
                    {SATTestList.map(test => {
                        return <option>{test.test_id}</option>
                    })}
                </select>
                &nbsp;&nbsp;&nbsp;ACT:
                <select onChange={changeACT}>
                    <option></option>
                    {ACTTestList.map(test => {
                        return <option>{test.test_id}</option>
                    })}
                </select>
            </div>
            <DisplayAnswerKey SATorACT={SATorACT} currTest={currTest}/>
        </>
    )
}

function DisplayAnswerKey(props){
    if (props.SATorACT === "") return (<></>)
    const currTest = props.currTest
    if (props.SATorACT === "SAT"){
        if (currTest === "") return <></>

        const questionNums = []
        for (var i = 1; i <= 52; i++) questionNums.push(i)

        const scaleNums = []
        for (var j = 0; j <= 58; j++) scaleNums.push(j);

        return(
            <>
                <p style={{color: 'darkred'}}>Use this query to view all answer keys</p>
                <h2>SAT {currTest.test_id}</h2>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Reading</td>
                            {questionNums.map(i => {
                                if (i < 41) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.reading.split(",").map((answer, i)=>{
                                if (i < 40) return <td className="answer-key-table-element">{answer}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Reading</td>
                            {questionNums.map(i=>{
                                if (i > 40) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.reading.split(",").map((answer,i)=>{
                                if (i > 39) return <td className="answer-key-table-element">{answer}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Writing</td>
                            {questionNums.map(i=>{
                                if (i <= 44) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.writing.split(",").map((answer)=>{
                                return <td className="answer-key-table-element">{answer}</td>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math (No Calc)</td>
                            {questionNums.map(i=>{
                                if (i < 21) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.math_nocalc.split(",").map((answer)=>{
                                return <td className="answer-key-table-element">{answer.split("&").join(" or ")}</td>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math (Calc)</td>
                            {questionNums.map(i=>{
                                if (i < 31) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.math_calc.split(",").map((answer,i)=>{
                                if (i < 30) return <td className="answer-key-table-element">{answer}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math (Calc)</td>
                            {questionNums.map(i=>{
                                if (i < 39 && i > 30) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Answer</td>
                            {currTest.math_calc.split(",").map((answer,i)=>{
                                if (i > 29 && i < 38) return <td className="answer-key-table-element">{answer.split("&").join(" or ")}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <h2>{currTest.test_id} Scale Chart</h2>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Reading Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i < 31) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.reading_scale.split(",").map((scale,i)=>{
                                if (i < 31) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Reading Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i >= 31 && i < 53) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.reading_scale.split(",").map((scale,i)=>{
                                if (i >= 31 && i < 53) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br><br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Writing Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i < 31) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.writing_scale.split(",").map((scale,i)=>{
                                if (i < 31) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Writing Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i >= 31 && i < 45) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.writing_scale.split(",").map((scale,i)=>{
                                if (i >= 31 && i < 45) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br><br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i < 31) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.math_scale.split(",").map((scale,i)=>{
                                if (i < 31) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math Raw Score</td>
                            {scaleNums.map(i=>{
                                if (i >= 31 && i < 59) return <td className="answer-key-table-element">{i}</td>
                                else return <></>
                            })}
                        </tr>
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Scaled Score</td>
                            {currTest.math_scale.split(",").map((scale,i)=>{
                                if (i >= 31 && i < 59) return <td className="answer-key-table-element">{scale}</td>
                                else return <></>
                            })}
                        </tr>
                    </tbody>
                </table>
            </>
        )
    } else{
        return(
            <>
                <h1>ACT {currTest.test_id}</h1>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">English</td>
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">English</td>
                        </tr>
                    </tbody>
                </table>
                <br></br><br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math</td>
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Math</td>
                        </tr>
                    </tbody>
                </table>
                <br></br><br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Reading</td>
                        </tr>
                    </tbody>
                </table>
                <br></br><br></br>
                <table className="answer-key-table">
                    <tbody className="answer-key-table-body">
                        <tr className="answer-key-table-row">
                            <td className="answer-key-table-element">Science</td>
                        </tr>
                    </tbody>
                </table>
                <br></br>
                
            </>
        )
    }
}