import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import '../StyleComponents/SchedulerSmall.css'
import '../StyleComponents/SchedulerLarge.css'

export default function SchedulerSmall(){
    const monthColor = ["lightblue","mistyrose","#77DD77","#E0B0FF","bisque","#FF8674","orange","gold","#E6E6FA","#C8B560","#C9C0BB","#3BB9FF"]
    const [isWeeklyCalendar, setIsWeeklyCalendar] = useState(false)
    const [dateToday, setDateToday] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [weekDates, setWeekDates] = useState([new Date(), new Date(), new Date(), new Date(), new Date(), new Date(), new Date()])
    const [detailPopup, setDetailPopup] = useState(false)
    const [confirmVerifyPopup, setConfirmVerifyPopup] = useState(false)
    const [teacherNotes, setTeacherNotes] = useState('')

    const [lessons, setLessons] = useState({})
    const [currLesson, setCurrLesson] = useState({})
    const [studentsAttended, setStudentsAttended] = useState([])
    const [studentsAbsent, setStudentsAbsent] = useState([])    

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    const daysInMonth = Array.from({ length: new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate() }, (_, i) => i + 1)
    const blankDays = Array.from({ length: (new Date(dateToday.getFullYear(), dateToday.getMonth(),1).getDay()) }, (_) => " ")
    const daysToShow = [...blankDays, ...daysInMonth]

    function hashTime(time){
        const parts = time.split(":")
        const hour = parseInt(parts[0])*60
        const minutes = parseInt(parts[1][0])*10+parseInt(parts[1][1])
        return hour + minutes + (parts[1][2] === "P" && hour < 12 ? 12*60 : 0)
    }
    function updateLessons(list){
        if (list){
            list.sort((a,b)=>{
                const diff = a.date_as_int - b.date_as_int
                return diff === 0 ? (hashTime(a.start_time) - hashTime(b.start_time)) : (diff) 
            })
            const map = {}
            for (var i = 0; i < list.length; i++){
                if (!(list[i].date in map)){
                    map[list[i].date] = []
                }
                map[list[i].date].push(list[i])
            }
            setLessons(map)
        }
    }
    async function getLessons(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers:{"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            updateLessons(response.data.user.lessons)
        } catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
        getLessons()
        updateWeekDates(new Date())
    },[])
    useEffect(()=>{
        if (currLesson){
            setTeacherNotes(currLesson.teacher_notes ? currLesson.teacher_notes : "")
        }
    },[currLesson])

    function getFullDate(d){
        const day = parseInt(d.getDate())
        var suffix = "th"
        if (day%10 === 1 && Math.floor(day/10) !== 1) suffix = "st"
        else if (day%10 === 2 && Math.floor(day/10) !== 1) suffix = "nd"
        else if (day%10 === 3 && Math.floor(day/10) !== 1) suffix = "rd"
        return `${daysOfWeek[d.getDay()]}, ${months[parseInt(d.getMonth())]} ${day}${suffix}`
    }
    function getPrevWeek(){
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()-7)
        setDateToday(newDate)
        updateWeekDates(newDate)
    }
    function getNextWeek(){
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()+7)
        setDateToday(newDate)
        updateWeekDates(newDate)
    }
    function selectDay(d){
        const newDate = new Date(d)
        setSelectedDate(newDate)
    }
    function updateWeekDates(start){
        const dayOfWeek = start.getDay()
        const newWeekDates = []
        for (var i = 0; i < 7; i++){
            const newDay = new Date(start)
            newDay.setDate(newDay.getDate()-dayOfWeek+i)
            newWeekDates.push(newDay)
        }
        setWeekDates(newWeekDates)
    }
    function checkDecoration(d){
        if (d.getDate() === selectedDate.getDate() &&
            d.getMonth() === selectedDate.getMonth() &&
            d.getFullYear() === selectedDate.getFullYear()
        ) return true
        return false
    }

    function openLesson(lesson){
        closeLesson()
        setCurrLesson(lesson)
        if (lesson.student_attendance){
            setStudentsAbsent(Object.keys(lesson.student_attendance))
        }
        setDetailPopup(true)
    }
    function closeLesson(){
        setCurrLesson({})
        setDetailPopup(false)
        setConfirmVerifyPopup(false)
        setStudentsAbsent([])
        setStudentsAttended([])
    }

    const handleAttendanceCheck = (student, isChecked) => {
        if (isChecked) {
            setStudentsAttended((prev) => [...prev, student]);
            setStudentsAbsent((prev) => prev.filter((s) => s !== student));
        } else {
            setStudentsAbsent((prev) => [...prev, student]);
            setStudentsAttended((prev) => prev.filter((s) => s !== student));
        }
    }
    async function verifyLesson(){
        const postData ={
            JWT:`Bearer ${localStorage.getItem('user')}`,
            students_attended:studentsAttended.join("~"),
            students_absent:studentsAbsent.join("~"),
            date:currLesson.date,
            start_time:currLesson.start_time,
            teacher_email:currLesson.teacher_email,
            teacher_notes:teacherNotes
        }
        console.log(postData)
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/verify/lesson',postData)
            if (response.data){
                getLessons()
                alert("Class has been verified")
                closeLesson()
            } else{
                alert("Unknown error. Please try again later.")
            }
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }

    return(
        <>
            <div className="teacher-toggle-calendar-button"
                onClick={()=>setIsWeeklyCalendar(!isWeeklyCalendar)}
            >
                <img style={{width:"1rem",height:"1rem"}} src="calendar-2.svg" alt="cross"/><br/>
                <span>{isWeeklyCalendar ? "Weekly" : "Monthly"}</span>
            </div>
            {isWeeklyCalendar ? 
                <div>
                    <div className="day-selector-bar">
                        {weekDates.map((d,i)=>{
                            return <div 
                                className="day-selector" 
                                key={"mtwtfss-"+i} 
                                style={{cursor:"pointer",borderRadius:"10px",color:(checkDecoration(d) ? "green" : "black"),background:(checkDecoration(d) ? "rgba(221, 221, 221, 0.5)" : "")}}
                                onClick={()=>{selectDay(d)}}
                            >
                                <span>{daysOfWeek[d.getDay()].substring(0,3)}</span><br/>
                                <span style={{textDecoration:(checkDecoration(d) ? "underline" : "none")}}>{`${d.getMonth()+1}/${d.getDate()}`}</span>
                            </div>
                        })}
                    </div> 
                    <div style={{paddingTop:"0.5rem",display:"flex",justifyContent:"space-between"}}>
                        <button onClick={()=>getPrevWeek()}>{"<"}</button>
                        <span style={{fontWeight:"bold",fontSize:"1.25rem"}}>{getFullDate(selectedDate)}</span>
                        <button onClick={()=>getNextWeek()}>{">"}</button>
                    </div>
                </div>
                : 
                <div className="scheduler-calendar-container-small">
                    <div className="scheduler-calendar-header" style={{background:`${monthColor[dateToday.getMonth()]}`}}>
                        <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1))}>{'<'}</button>
                        <span>{dateToday.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                        <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 1))}>{'>'}</button>
                    </div>
                    <table className="scheduler-calendar-small">
                        <tbody>
                            <tr style={{fontWeight:"bold"}}>
                                <td>Sun</td>
                                <td>Mon</td>
                                <td>Tue</td>
                                <td>Wed</td>
                                <td>Thu</td>
                                <td>Fri</td>
                                <td>Sat</td>
                            </tr>
                            {[...Array(6)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                    {[...Array(7)].map((_, colIndex) => {
                                    const dayIndex = rowIndex * 7 + colIndex;
                                    const day = daysToShow[dayIndex];
                                    const dateString = `${dateToday.getMonth()+1}/${day}/${dateToday.getFullYear()}`
                                    return (
                                        <td key={dayIndex} className="scheduler-calendar-date-small">
                                            {
                                                day === undefined || day === " " ? <></> : 
                                                <>
                                                    <span>{day}</span>
                                                    {lessons[dateString] ? 
                                                        <div className="calendar-blue-circle" onClick={()=>setSelectedDate(new Date(dateToday.getFullYear(),dateToday.getMonth(),day))}>
                                                            <div className="calendar-lesson-count">{lessons[dateString].length}</div>
                                                        </div> : ""}
                                                </>
                                            }
                                        </td>
                                    );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>{getFullDate(selectedDate)}</span>
                </div>
            }
            <br/>
            <div style={{paddingBottom:"5rem"}}>
                {lessons[`${selectedDate.getMonth()+1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`] ? 
                    (lessons[`${selectedDate.getMonth()+1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`].map((l,i)=>{
                        return <Lesson key={`les-${i}`} l={l} openLesson={openLesson} i={i}/>
                    })) : 
                    <></>
                }
            </div>
            {detailPopup ? 
                <div className="scheduler-small-popup-container">
                    <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeLesson()}>Close</div>
                    <span className="lesson-detail-title">Lesson Details</span><br/>
                    {currLesson.verified ? 
                        <span style={{color:"green"}}>Verified <img style={{width:"1rem", height:"1rem"}} src="green-check.svg" alt="check"/></span> 
                        : <span style={{color:"darkred"}}>Unverified</span>}
                    <div className="lesson-detail-container">
                        <div className="lesson-detail"><b>Date: </b>{daysOfWeek[dateToday.getDay()] + ", " + currLesson.date}</div>
                        <div className="lesson-detail"><b>Start Time: </b>{currLesson.start_time}</div>
                        <div className="lesson-detail"><b>Class Duration: </b>{`${Math.floor(currLesson.duration/60)} hrs ${currLesson.duration%60} mins`}</div>
                        <div className="lesson-detail"><b>Teacher: </b>{`${currLesson.teacher_first_name ? currLesson.teacher_first_name : ""} ${currLesson.teacher_last_name ? currLesson.teacher_last_name[0] : ""}.`}</div>
                        <div className="lesson-detail"><b>Student Attendance: </b></div>
                        {currLesson.student_attendance ? Object.entries(currLesson.student_attendance).map(([key,val],i)=>{
                            const parts = key.split(",")
                            return <div key={`attend-${currLesson.date}-${currLesson.start_time}-${i}`}>
                                <input
                                    type="checkbox"
                                    id={`student-${i}`}
                                    onChange={(e)=>handleAttendanceCheck(key,e.target.checked)}
                                />
                                <label htmlFor={`student-${i}`}>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</label>
                                {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (currLesson.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                            </div>
                        }) : <></>}
                        <div className="lesson-detail"><b>Administrator Notes: </b>{`"${currLesson.admin_notes ? currLesson.admin_notes : ""}"`}</div>
                        <div className="lesson-detail">
                        <b>Teacher Notes: </b>
                            <textarea id="book-lesson-notes"
                                onChange={(e)=>setTeacherNotes(e.target.value)}
                                value={teacherNotes}
                                rows="4"
                                style={{width:"90%"}}
                            />
                        </div>                        <button className="lesson-detail-button" onClick={()=>setConfirmVerifyPopup(true)}>Verify Lesson</button>
                        {confirmVerifyPopup ? <button className="lesson-detail-button" style={{color:"red"}} onClick={()=>verifyLesson()}>Confirm Verify</button> : <></>}
                    </div>                    
                </div> : <></>
            }
        </>
    )
}

function Lesson({l, openLesson, i}){
    const [isExpanded, setIsExpanded] = useState(false)
    const contentRef = useRef()
    const collapseContentRef = useRef()
    const [contentHeight, setContentHeight] = useState(0)
    useEffect(() => {
        if (isExpanded){
            setContentHeight(contentRef.current.scrollHeight)
        } else{
            setContentHeight(2*collapseContentRef.current.scrollHeight)
        }
    }, [isExpanded])

    return(
        <>
            <div 
                ref={contentRef} 
                className={`lesson-detail-container${isExpanded ? " expanded" : ""}`}
                style={{height:`${contentHeight}px`, border:"1px black solid"}}
            >
                <div 
                    className="lesson-detail-bar" 
                    onClick={()=>setIsExpanded(!isExpanded)}
                    style={{background:(l.verified ? "rgb(0, 230, 0)" : "darkred")}}
                />
                {isExpanded ? <div>
                    <h2>Class {i+1}</h2>
                    {l.verified ? <span style={{color:"green"}}>Verified <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/></span> : <span style={{color:"darkred"}}>Unverified</span>}
                    </div>: <></>
                }
                <div ref={collapseContentRef}>
                    <div className="lesson-detail"><b>Start Time: </b>{l.start_time}</div>
                    <div className="lesson-detail"><b>Duration: </b>{`${Math.floor(l.duration/60)} hr(s) ${l.duration%60} mins`}</div>
                </div>
                {isExpanded ? <div>
                    <div className="lesson-detail"><b>Teacher: </b>{`${l.teacher_first_name ? l.teacher_first_name : ""} ${l.teacher_last_name ? l.teacher_last_name[0] : ""}.`}</div>
                    <div className="lesson-detail"><b>Student Attendance:</b>
                        {l.student_attendance ? Object.entries(l.student_attendance).map(([key,val],i)=>{
                            const parts = key.split(",")
                            return <div key={`attend-${l.date}-${l.start_time}-${i}`}>
                                <span>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</span>
                                {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (l.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                            </div>
                        }) : <></>}
                    </div>
                    <div className="lesson-detail"><b>Administrator Notes: </b>{`"${l.admin_notes ? l.admin_notes : ""}"`}</div>
                    <div className="lesson-detail"><b>Teacher Notes: </b>{`"${l.teacher_notes ? l.teacher_notes : ""}"`}</div>
                    <button className="lesson-detail-button" onClick={(e)=>openLesson(l,e)}>Click for verification or deletion</button>
                </div> : <></>}
                </div>
        </>
    )
}