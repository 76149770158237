import React, {useState,useEffect,useRef} from 'react'
import SATScoreDisplay from './SATScoreDisplay'
import '../StyleComponents/SATAdvanced.css'
import {ResponsiveLine} from '@nivo/line'

export default function SATAdvanced({student}){
    const [testList,setTestList] = useState([])
    const [chronologicalTestList, setChronologicalTestList] = useState([])
    const [currTest, setCurrTest] = useState(null)
    const [ascDec, setAscDec] = useState(true)
    const [currSort, setCurrSort] = useState("Date")
    const [displayAmount, setDisplayAmount] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(()=>{
        if (student && student.SAT){
          setTestList([...student.SAT].sort((a,b)=>{return b.date_as_int - a.date_as_int}))
          setChronologicalTestList([...student.SAT].sort((a,b)=>{return a.date_as_int - b.date_as_int}))
        }
        setDisplayAmount(5)
        window.addEventListener('resize',handleResize)
        return ()=>window.removeEventListener('resize',handleResize)
    },[student])
    const handleResize = () => {setScreenWidth(window.innerWidth)}

    function sortByDate(){
        var bool = ascDec
        if (currSort === "Date"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? (b.date_as_int-a.date_as_int) : (a.date_as_int-b.date_as_int)})
        setTestList(newList)
        setCurrSort("Date")
    }
    function sortByReading(){
        var bool = ascDec
        if (currSort === "Reading"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (a.reading_scaled===b.reading_scaled ? (b.date_as_int-a.date_as_int) : (b.reading_scaled-a.reading_scaled)) 
            : 
            (a.reading_scaled===b.reading_scaled ? (b.date_as_int-a.date_as_int) : (a.reading_scaled-b.reading_scaled))})
        setTestList(newList)
        setCurrSort("Reading")
    }
    function sortByWriting(){
        var bool = ascDec
        if (currSort === "Writing"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (a.writing_scaled===b.writing_scaled ? (b.date_as_int-a.date_as_int) : (b.writing_scaled-a.writing_scaled)) 
            : 
            (a.writing_scaled===b.writing_scaled ? (b.date_as_int-a.date_as_int) : (a.writing_scaled-b.writing_scaled))})
        setTestList(newList)
        setCurrSort("Writing")
    }
    function sortByMath(){
        var bool = ascDec
        if (currSort === "Math"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (a.math_scaled===b.math_scaled ? (b.date_as_int-a.date_as_int) : (b.math_scaled-a.math_scaled)) 
            : 
            (a.math_scaled===b.math_scaled ? (b.date_as_int-a.date_as_int) : (a.math_scaled-b.math_scaled))})
        setTestList(newList)
        setCurrSort("Math")
    }
    function sortByTotal(){
        function getTotal(test){
            return test.reading_scaled+test.writing_scaled+test.math_scaled
        }
        var bool = ascDec
        if (currSort === "Total"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (getTotal(a)===getTotal(b) ? (b.date_as_int-a.date_as_int) : (getTotal(b)-getTotal(a))) 
            : 
            (getTotal(a)===getTotal(b) ? (b.date_as_int-a.date_as_int) : (getTotal(a)-getTotal(b)))})
        setTestList(newList)
        setCurrSort("Total")
    }
    function selectTest(test){
        setCurrTest(test)
    }
    function getYY(date){
        const parts = date.split("/")
        return `${parts[0]}/${parts[1]}/${parts[2].substring(2)}`
    }
    if (testList.length === 0){
        return (
            <>
                <h1>You don't have any tests to view.</h1>
            </>
        )
    }
    return(
        <>
            <div style={{width:"min(90rem,100%)"}}>
                <h1>SAT</h1>
            </div>
            <div className="SAT-advanced-list-container">
                <table className="SAT-advanced-table">
                    <tbody>
                        <tr>
                            <td>
                                Date
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByDate()}>
                                    <path style={{opacity:(!ascDec && currSort==="Date" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Date" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "R" : "Reading"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByReading()}>
                                    <path style={{opacity:(!ascDec && currSort==="Reading" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Reading" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "W" : "Writing"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByWriting()}>
                                    <path style={{opacity:(!ascDec && currSort==="Writing" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Writing" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "M" : "Math"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByMath()}>
                                    <path style={{opacity:(!ascDec && currSort==="Math" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Math" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "T" : "Total"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByTotal()}>
                                    <path style={{opacity:(!ascDec && currSort==="Total" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Total" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                        </tr>
                        {testList.map((test,i)=>{
                            return <tr key={`test-${i}`}
                                className="SAT-advanced-test-selector"
                                onClick={()=>selectTest(test)}
                            >
                                <td style={{paddingRight:"0.25rem"}}>{screenWidth < 600 ? (getYY(test.date)) : test.date}</td>
                                <td>{test.reading_scaled}</td>
                                <td>{test.writing_scaled}</td>
                                <td>{test.math_scaled}</td>
                                <td>{test.reading_scaled+test.writing_scaled+test.math_scaled}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div style={{textAlign:"left",color:"darkred",fontSize:"0.75rem"}}>**Click on a test to see details</div>
            </div>
            {currTest ? 
                <div className="SAT-advanced-currtest-container">
                    <SATScoreDisplay student={student} test={currTest} testType={"PaperSAT"} displayingMostRecent={false}/>
                    {screenWidth < 1000 ? <div style={{display:"flex"}}>
                        <div className="SAT-advanced-answer-vertical">
                            <span>Reading</span>
                            <table>
                                <tbody>
                                    {currTest.reading_answers.split(",").map((ans,i)=>{
                                        return <tr key={`read-${i}`}>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                            >{i+1}</td>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                style={{background:(currTest.reading_graded[i] ? "" : "coral")}}
                                            >{ans}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="SAT-advanced-answer-vertical">
                            <span>Writing</span>
                            <table>
                                <tbody>
                                    {currTest.writing_answers.split(",").map((ans,i)=>{
                                        return <tr key={`write-${i}`}>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                            >{i+1}</td>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                style={{background:(currTest.writing_graded[i] ? "" : "coral")}}
                                            >{ans}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="SAT-advanced-answer-vertical">
                            <span>No Calc</span>
                            <table>
                                <tbody>
                                    {currTest.math_nocalc_answers.split(",").map((ans,i)=>{
                                        return <tr key={`nocalc-${i}`}>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                            >{i+1}</td>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                style={{background:(currTest.math_nocalc_graded[i] ? "" : "coral")}}
                                            >{ans}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="SAT-advanced-answer-vertical">
                            <span>Calc</span>
                            <table>
                                <tbody>
                                    {currTest.math_calc_answers.split(",").map((ans,i)=>{
                                        return <tr key={`calc-${i}`}>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                            >{i+1}</td>
                                            <td 
                                                className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                style={{background:(currTest.math_calc_graded[i] ? "" : "coral")}}
                                            >{ans}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>:
                    <div>
                        <span>Reading</span>
                        <div className="SAT-advanced-student-answers">
                            <table>
                                <tbody>
                                    <tr>
                                        {currTest.reading_answers.split(",").map((_,i)=>{
                                            return <td key={`read-${i}`} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        {currTest.reading_answers.split(",").map((ans,i)=>{
                                            return <td key={`read-stu-${i}`} style={{background:(currTest.reading_graded[i] ? "" : "coral")}} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span>Writing</span>
                        <div className="SAT-advanced-student-answers">
                            <table>
                                <tbody>
                                    <tr>
                                        {currTest.writing_answers.split(",").map((_,i)=>{
                                            return <td key={`write-${i}`} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        {currTest.writing_answers.split(",").map((ans,i)=>{
                                            return <td key={`write-stu-${i}`} style={{background:(currTest.writing_graded[i] ? "" : "coral")}} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span>Math (No Calc)</span>
                        <div className="SAT-advanced-student-answers">
                            <table>
                                <tbody>
                                    <tr>
                                        {currTest.math_nocalc_answers.split(",").map((_,i)=>{
                                            return <td key={`nocalc-${i}`} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        {currTest.math_nocalc_answers.split(",").map((ans,i)=>{
                                            return <td key={`nocalc-stu-${i}`} style={{background:(currTest.math_nocalc_graded[i] ? "" : "coral")}} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span>Math (Calc)</span>
                        <div className="SAT-advanced-student-answers">
                            <table>
                                <tbody>
                                    <tr>
                                        {currTest.math_calc_answers.split(",").map((_,i)=>{
                                            return <td key={`calc-${i}`} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        {currTest.math_calc_answers.split(",").map((ans,i)=>{
                                            return <td key={`calc-stu-${i}`} style={{background:(currTest.math_calc_graded[i] ? "" : "coral")}} className={`SAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                </div> : 
                <></>
            }
            <div>
                <NivoLine testList={chronologicalTestList} displayAmount={displayAmount} screenWidth={screenWidth}/>
                <div style={{padding:"0 1rem 1rem 1rem",textAlign:"left"}}>
                    <label htmlFor="select-test-number">Displaying </label>
                    <select id="select-test-number" onChange={(e)=>{setDisplayAmount(parseInt(e.target.value))}} value={displayAmount}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={100}>All</option>
                    </select>
                    <label htmlFor="select-test-number">&nbsp;&nbsp;Tests</label>
                </div>
            </div>
        </>
    )
}

function NivoLine({testList, displayAmount, screenWidth}){
  const customColors = ['#ff595e', '#12d078', '#ff7b00', '#037cfe']
  const graphRef = useRef(null)
  function parseDate(date){
    const parts = date.split("/")
    return `${parts[0]}/${parts[1]}`
  }

  var readingData = {"id":"Reading","data":[]}
  var writingData = {"id":"Writing","data":[]}
  var mathData = {"id":"Math","data":[]}
  var totalData = {"id":"Total","data":[]}
  var testMap = {}
  for (var i = 0; i < testList.length; i++){
    const currTest = testList[i]
    testMap[currTest.date] = testMap[currTest.date] ?? []
    testMap[currTest.date].push([currTest.reading_scaled,currTest.writing_scaled,currTest.math_scaled,currTest.reading_scaled+currTest.writing_scaled+currTest.math_scaled])
  }
  Object.keys(testMap).forEach((key)=>{
    const val = testMap[key]
    if (val){
      var maxReading = val[0][0]
      var maxWriting = val[0][1]
      var maxMath = val[0][2]
      var maxTotal = val[0][3]
      if (val.length > 1){
        for (var i = 1; i < val.length; i++){
          maxReading = Math.max(maxReading,val[i][0])
          maxWriting = Math.max(maxWriting,val[i][1])
          maxMath = Math.max(maxMath,val[i][2])
          maxTotal = Math.max(maxTotal,val[i][3])
        }
      }
      readingData.data.push({
        "x":parseDate(key),
        "y":maxReading,
        "full_date":key
      })
      writingData.data.push({
        "x":parseDate(key),
        "y":maxWriting,
        "full_date":key
      })
      mathData.data.push({
        "x":parseDate(key),
        "y":maxMath,
        "full_date":key
      })
      totalData.data.push({
        "x":parseDate(key),
        "y":maxTotal,
        "full_date":key
      })
    }
  })

  const customToolTip = ({point}) => {
    const date = point.data.full_date
    var styles = {}
    if (graphRef && screenWidth < 600){
        if (point.x < graphRef.current.getBoundingClientRect().width/3){
            styles = {
                position:"absolute",
                transform:"translateY(-50%)",
                left:"20px"
            }
        } else {
            styles = {
                position:"absolute",
                transform:"translateY(-50%)",
                right:"20px"
            }
        }
    }

    return(
      <div className="custom-tool-tip" style={styles}>
        <div>
          <strong>Date: </strong> {date}
        </div>
        {testMap[date].length > 1 ?
          <div>
            {testMap[date].map((test,i)=>{
              return <div key={`ttt-${date}-${i}`}>
                <div><strong>Test {i+1}</strong></div>
                <div><strong>Reading: </strong>{test[0]}</div>
                <div><strong>Writing: </strong>{test[1]}</div>
                <div><strong>Math: </strong>{test[2]}</div>
                <div><strong>Total: </strong>{test[0]+test[1]+test[2]}</div>
              </div>
            })}
          </div> :
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{display:"flex"}}><div style={{background:customColors[3],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Total:</strong></td>
                  <td>{testMap[date][0][3]}</td>
                </tr>
                <tr>
                <td style={{display:"flex",paddingRight:"0.5rem"}}><div style={{background:customColors[0],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Reading:</strong></td>
                  <td>{testMap[date][0][0]}</td>
                </tr>
                <tr>
                <td style={{display:"flex"}}><div style={{background:customColors[1],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Writing:</strong></td>
                  <td>{testMap[date][0][1]}</td>
                </tr>
                <tr>
                <td style={{display:"flex"}}><div style={{background:customColors[2],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Math:</strong></td>
                  <td>{testMap[date][0][2]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
  
    return(
        <>
            <div className="SAT-Nivoline" ref={graphRef}>
                <ResponsiveLine
                    data={[readingData,writingData,mathData,totalData]}
                    tooltip={customToolTip}
                    margin={{ top: 50, right: (screenWidth < 600 ? 20 : 110), bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 1650,
                        stacked: false,
                        reverse: false
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Date',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Score',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={customColors}
                    curve="monotoneX"
                    lineWidth={3}
                    pointSize={7}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={7}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: (screenWidth < 600 ? 'top-left' : 'right'),
                            direction: (screenWidth < 600 ? 'row' : 'column'),
                            justify: false,
                            translateX: (screenWidth < 600 ? 0 : 100),
                            translateY: (screenWidth < 600 ? -30 : 0),
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: (screenWidth < 600 ? 60 : 80),
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        </>
    )
}