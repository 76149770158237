import React, {useState, useEffect} from 'react'
import axios from 'axios'
import SchedulerSmall from './Components/SchedulerSmall'
import SchedulerLarge from './Components/SchedulerLarge'

export default function Scheduler() {
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth < 800)
  const [isLoading, setIsLoading] = useState(true)

  async function checkUser(){
    try{
      const response = await axios.get('https://www.tenaflyprepacademy.com/server/check/admin',{
        headers: {
          "JWT":("Bearer "+localStorage.getItem('user'))
        }
      })
      if (response.data){
        setIsLoading(false)
      } else{
        alert("You need to log in as admin")
      }
    } catch(err){
      console.log(err)
      alert("You need to log in as admin")
    }
  }
  const handleResize = () => {setIsSmallWidth(window.innerWidth < 800)}
  useEffect(()=>{
    checkUser()
    window.addEventListener('resize', handleResize)
    return () => {window.removeEventListener('resize', handleResize)}
  },[])

  if (isLoading) return(<h2 className="loading">Checking Credentials...</h2>)
  return(
    <>
      <br/>
      {isSmallWidth ? <SchedulerSmall/> : <SchedulerLarge/>}
    </>
  )
}
