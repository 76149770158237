import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ScheduleSmall from './Components/ScheduleSmall'
import ScheduleLarge from './Components/ScheduleLarge'

export default function ScheduleChecker() {
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth < 800)
  const [isLoading, setIsLoading] = useState(true)

  async function checkUser(){
    try{
      const response = await axios.get('https://www.tenaflyprepacademy.com/server/check/teacher',{
        headers: {"JWT":("Bearer "+localStorage.getItem('user'))}
      })
      if (response.data){
        setIsLoading(false)
      } else{
        alert("You need to log in")
      }
    } catch(err){
      console.log(err)
      alert("You need to log in")
    }
  }
  const handleResize = () => {setIsSmallWidth(window.innerWidth < 800)}
  useEffect(()=>{
    checkUser()
    window.addEventListener('resize', handleResize)
    return () => {window.removeEventListener('resize', handleResize)}
  },[])

  if (isLoading) return(<h2 className="loading">Checking Credentials...</h2>)
  return(
    <>
      <br/>
      {isSmallWidth ? <ScheduleSmall/> : <ScheduleLarge/>}
    </>
  )
}
