import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';

export default function DeleteTest(){
    const errRef = useRef()

    const [errMsg, setErrMsg] = useState('')
    const [studentEmail, setStudentEmail] = useState('')
    const [testId, setTestId] = useState('')
    const [date, setDate] = useState('')
    const [testType, setTestType] = useState('')

    useEffect(()=>{
        setErrMsg('')}, [studentEmail, studentEmail, testId, date, testType]
    );

    const submitForm = async (e) =>{
        e.preventDefault();
        if (testType === "SAT"){
            try{
                const response = await axios.post('https://www.tenaflyprepacademy.com/server/delete/SAT', {
                    "email": studentEmail,
                    "test_id": testId,
                    "date": date,
                    "JWT":("Bearer "+localStorage.getItem('user'))
                });
                console.log(response.data);
                if (response.data) alert("Successfully deleted test");
                else setErrMsg("Delete unsuccessfuly. Please double check information.");
            } catch{
                setErrMsg('No server response. Please try again later.');
            }
            return
        }
        if (testType === "ACT"){
            try{
                const response = await axios.post('http://www.tenaflyprepacademy.com/server/delete/ACT',{
                    "email": studentEmail,
                    "test_id": testId,
                    "date": date,
                    "JWT":("Bearer "+localStorage.getItem('user'))
                });
                console.log(response.data);
                if (response.data) alert("Successfully deleted test")
                else setErrMsg("Delete unsuccessfuly. Please double check information.")
            } catch(err){
                setErrMsg('No server response. Please try again later.');
            }
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color:'darkred'}}>Please double check that all the information is correct.</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Test Type (SAT or ACT): <input className="admin-input" onChange={e=>setTestType(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Student Email: <input className="admin-input" onChange={e=>setStudentEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Test Date (mm/dd/yyyy): <input className="admin-input" onChange={e=>setDate(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Test ID: <input className="admin-input" onChange={e=>setTestId(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Delete Test</button>
            </form>
        </>
    );
}