import React from 'react'
import { useNavigate } from 'react-router-dom';
import {LoadScript} from '@react-google-maps/api';
import GMap from "./GMap";
import './Contacts.css'

export default function Contacts(){
    const navigate = useNavigate()
    function getDirections(){
        window.open("https://www.google.com/maps/dir//Tenafly+Prep+Academy,+Tenafly,+NJ/@40.9252068,-74.0358252,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x89c2f19653b04609:0x3adf23ab581d0cb7!2m2!1d-73.9657847!2d40.9250983!3e0", "_blank")
    }

    return (
        <>
            <div className="contacts-container">
                <div className="back-to-top-button" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
                    <img src="scroll-up-arrow-white.svg" alt="totop" className="back-to-top-icon"/>
                </div>
                <h1>Contact Us</h1>
                <div className="contacts-wrapper">
                    <div>
                        <LoadScriptOnlyIfNeeded googleMapsApiKey={process.env.REACT_APP_GMAP_API_KEY}>
                            <GMap/>
                        </LoadScriptOnlyIfNeeded>
                    </div>
                    <div>
                        <h4 style={{marginBottom:"0"}}>Address: <a href="https://www.google.com/maps/dir//Tenafly+Prep+Academy,+Tenafly,+NJ/@40.9252068,-74.0358252,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x89c2f19653b04609:0x3adf23ab581d0cb7!2m2!1d-73.9657847!2d40.9250983!3e0"
                            target="_blank"
                            rel="noreferrer"
                            style={{color:'white'}}>20 Washington Street, Tenafly, NJ, 07670</a> &nbsp;
                            <button onClick={() =>  
                                navigator.clipboard.writeText("20 Washington Street, Tenafly, NJ, 07670")}>
                                Copy Address
                            </button>
                        </h4>
                        <button className="contacts-button" onClick={()=>getDirections()}>Get Directions</button>
                        <h4 style={{marginBottom:"0"}}>Phone: (201)-390-2381 &nbsp;
                            <button onClick={()=>
                                navigator.clipboard.writeText("201-390-2381")}>
                                Copy Phone#
                            </button>
                        </h4>
                        <button className="contacts-button" onClick={()=>window.location.href = `tel:201-390-2381`}>Call Now</button>
                        <h4>Email: TenaflyAcademy26@gmail.com &nbsp;
                            <button onClick={()=>
                                navigator.clipboard.writeText("tenaflyacademy26@gmail.com")}>
                                Copy Email
                            </button>
                        </h4>
                        <h4 style={{textDecoration:"underline",cursor:"pointer"}} onClick={()=>navigate("/FAQ")}>
                            Click here for common questions about our system.
                        </h4>
                        {/* <img src="TPA-front.jpg" alt="front" className="TPA-front"/> */}
                    </div>
                </div>
            </div>
        </>
    )
}

class LoadScriptOnlyIfNeeded extends LoadScript {
    componentDidMount() {
      const cleaningUp = true
      const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
      const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
      if (!isAlreadyLoaded && isBrowser) {
        // @ts-ignore
        if (window.google && !cleaningUp) {
          console.error("google api is already presented")
          return
        }
  
        this.isCleaningUp().then(this.injectScript)
      }
  
      if (isAlreadyLoaded) {
        this.setState({ loaded: true })
      }
    }
  }