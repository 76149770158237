import React, {useEffect, useState, useRef} from 'react'
import '../StyleComponents/DashboardBig.css'
import DashboardAlert from './DashboardAlert'
import DashboardOverview from './DashboardOverview'
import { useNavigate } from 'react-router-dom'
import SATAdvanced from './SATAdvanced'
import DigitalSATAdvanced from './DigitalSATAdvanced'
import ACTAdvanced from './ACTAdvanced'
import PSATAdvanced from './PSATAdvanced'
import VocabPracticeHistory from './VocabPracticeHistory'
import DisplayHomework from './DisplayHomework'

export default function DashboardParentNew({parent}){
    const [whatDoing, setWhatDoing] = useState("Home")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [displaySub, setDisplaySub] = useState(false)
    const [displayStudentSub, setDisplayStudentSub] = useState(false)
    const [currStudent, setCurrStudent] = useState(null)
    const subRef = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        window.addEventListener('resize',handleResize)
        return () => {window.removeEventListener('resize',handleResize)}
    },[])
    const handleResize = () => {setScreenWidth(window.innerWidth)}
    // console.log(parent)

    const toDigitalSAT = () => {
        changeWhatDoing("DigitalSAT")
        scrollToTop()
    }
    const toPSAT = () => {
        changeWhatDoing("PSAT")
        scrollToTop()
    }
    const toACT = () => {
        changeWhatDoing("ACT")
        scrollToTop()
    }
    const toSAT = () => {
        changeWhatDoing("SAT")
        scrollToTop()
    }
    function scrollToTop(){document.documentElement.scrollTop = 0;}

    const selectStudent = (email) => {
        for (var i = 0; i < parent.students.length; i++){
            if (parent.students[i].email === email){
                setCurrStudent(parent.students[i])
                return
            }
        }
        setCurrStudent(null)
    }
    function changeWhatDoing(toDo){
        if (whatDoing === toDo) return
        setWhatDoing(toDo)
    }
    const getComponent = () => {
        if (whatDoing === "Home"){
            return(
                <>
                    <DashboardOverview student={currStudent} toDigitalSAT={toDigitalSAT} toPSAT={toPSAT} toACT={toACT} toSAT={toSAT}/>
                </>
            )
        } else if (whatDoing === "DigitalSAT"){
            return(
                <>
                    <DigitalSATAdvanced student={currStudent}/>
                </>
            )
        } else if (whatDoing === "PSAT"){
            return(
                <>
                    <PSATAdvanced student={currStudent}/>
                </>
            )
        } else if (whatDoing === "ACT"){
            return(
                <>
                    <ACTAdvanced student={currStudent}/>
                </>
            )
        } else if (whatDoing === "SAT"){
            return(
                <SATAdvanced student={currStudent}/>
            )
        } else if (whatDoing === "Vocab"){
            return(
                <>
                    <VocabPracticeHistory vocabList={currStudent ? currStudent.Vocab : ""}/>
                </>
            )
        } else if (whatDoing === "Homework"){
            if (currStudent){
                const homeworkList = [...currStudent.homework]
                homeworkList.sort(function(a, b) {
                    const dateA = new Date(a.due_date);
                    const dateB = new Date(b.due_date);
                    if (dateA < dateB) {
                        return 1; 
                    }
                    if (dateA > dateB) {
                        return -1; 
                    }
                    return 0;
                })
                return <DisplayHomework homeworkList={homeworkList} student={currStudent}/>
            }
            return <>
                <h1>There are no homeworks assigned.</h1>
            </>            
        }
    }

    return(
        <>
            <div className="big-dashboard-container">
                {screenWidth < 950 ? 
                    <div className="dashboard-bottombar">
                        <div 
                            className="dashboard-bottombar-button" 
                            onClick={()=>setWhatDoing("Home")}
                            style={{background:(whatDoing === "Home" ? "rgb(230,230,230)" : "")}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill={whatDoing === "Home" ? "blue" : "black"}>
                                <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z"/>
                            </svg><br/>
                            <span style={{color:(whatDoing === "Home" ? "blue" : "black")}}>{screenWidth < 350 ? "Home" : "Overview"}</span>
                        </div>
                        <div 
                            className="dashboard-bottombar-button" 
                            onClick={()=>setDisplaySub(!displaySub)} 
                            ref={subRef}
                            style={{background:((whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "rgb(230,230,230)" : ""),borderTopLeftRadius:"0",borderTopRightRadius:"0"}}
                            onMouseOver={()=>setDisplaySub(true)}
                            onMouseLeave={()=>setDisplaySub(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill={(whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "blue" : "black"}>
                                <path d="m105-233-65-47 200-320 120 140 160-260 109 163q-23 1-43.5 5.5T545-539l-22-33-152 247-121-141-145 233ZM863-40 738-165q-20 14-44.5 21t-50.5 7q-75 0-127.5-52.5T463-317q0-75 52.5-127.5T643-497q75 0 127.5 52.5T823-317q0 26-7 50.5T795-221L920-97l-57 57ZM643-217q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm89-320q-19-8-39.5-13t-42.5-6l205-324 65 47-188 296Z"/>
                            </svg><br/>
                            <span style={{color:((whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "blue" : "black")}}>Scores</span>
                            <div className="dashboard-submenu" style={{display:(displaySub ? "" : "none")}}>
                                <div onClick={()=>setWhatDoing("SAT")}>SAT</div>
                                <div onClick={()=>setWhatDoing("DigitalSAT")}>Digital SAT</div>
                                <div onClick={()=>setWhatDoing("ACT")}>ACT</div>
                                <div onClick={()=>setWhatDoing("PSAT")}>PSAT</div>
                                <div onClick={()=>setWhatDoing("Homework")}>Homework</div>
                                <div onClick={()=>setWhatDoing("Vocab")}>Vocab</div>
                            </div>
                        </div>
                        <div 
                            className="dashboard-bottombar-button" 
                            onClick={()=>setDisplayStudentSub(!displayStudentSub)} 
                            ref={subRef}
                            style={{background:((whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "rgb(230,230,230)" : ""),borderTopLeftRadius:"0",borderTopRightRadius:"0"}}
                            onMouseOver={()=>setDisplayStudentSub(true)}
                            onMouseLeave={()=>setDisplayStudentSub(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill="black">
                                <path d="M560-680v-80h320v80H560Zm0 160v-80h320v80H560Zm0 160v-80h320v80H560Zm-240-40q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM80-160v-76q0-21 10-40t28-30q45-27 95.5-40.5T320-360q56 0 106.5 13.5T522-306q18 11 28 30t10 40v76H80Zm86-80h308q-35-20-74-30t-80-10q-41 0-80 10t-74 30Zm154-240q17 0 28.5-11.5T360-520q0-17-11.5-28.5T320-560q-17 0-28.5 11.5T280-520q0 17 11.5 28.5T320-480Zm0-40Zm0 280Z"/>
                            </svg><br/>
                            <span>{currStudent ? currStudent.first_name : "Select a Student"}</span>
                            <div className="dashboard-submenu" style={{display:(displayStudentSub ? "" : "none")}}>
                                {parent.students ? parent.students.map((stu)=>{
                                    return <div key={`select${stu.email}`}
                                        onClick={()=>setCurrStudent(stu)}
                                    >
                                        {stu.first_name}
                                    </div>
                                }) : <></>}
                            </div>
                        </div>
                    </div> :
                    <div className="dashboard-sidebar">
                        <div className="dashboard-sidebar-info">
                            <img src="login-icon-white.png" alt="icon" style={{width:"5rem",height:"5rem"}}/><br/>
                            <span>Parent Dashboard</span><br/>
                            <div>
                                <span 
                                    style={{fontSize:"1rem"}}
                                    onChange={(e)=>selectStudent(e.target.value)}
                                >
                                    Select Student: 
                                </span><br/>
                                <select onChange={(e)=>selectStudent(e.target.value)}>
                                    <option value=" ">------------</option>
                                    {parent.students ? parent.students.map((stu)=>{
                                        return <option value={`${stu.email}`} key={`${stu.email}`}>
                                            {`${stu.first_name} ${stu.last_name}`}
                                        </option>
                                    }) : <></>}
                                </select>
                            </div>
                            {/* <span>{student.first_name} {student.last_name}</span> */}
                        </div>
                        <div className="dashboard-sidebar-nav">
                            <div 
                                style={{background:(whatDoing === "Home" ? "lightblue" : ""),color:(whatDoing === "Home" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Home")}
                            >
                                Overview
                            </div>
                            <div className="dashboard-sidebar-title">
                                Results
                            </div>
                            <div 
                                style={{background:(whatDoing === "SAT" ? "lightblue" : ""),color:(whatDoing === "SAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("SAT")}
                            >
                                SAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "DigitalSAT" ? "lightblue" : ""),color:(whatDoing === "DigitalSAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("DigitalSAT")}
                            >
                                Digital SAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "ACT" ? "lightblue" : ""),color:(whatDoing === "ACT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("ACT")}
                            >
                                ACT
                            </div>
                            <div 
                                style={{background:(whatDoing === "PSAT" ? "lightblue" : ""),color:(whatDoing === "PSAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("PSAT")}
                            >
                                PSAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "Homework" ? "lightblue" : ""),color:(whatDoing === "Homework" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Homework")}
                            >
                                Homework
                            </div>
                            <div 
                                style={{background:(whatDoing === "Vocab" ? "lightblue" : ""),color:(whatDoing === "Vocab" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Vocab")}
                            >
                                Vocab
                            </div>
                        </div>
                    </div>
                }
                <div className="big-dashboard-content-container">
                    {getComponent()}
                </div>
            </div>
            
        </>
    )
}