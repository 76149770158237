import React, {useState, useEffect, useRef} from "react"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import {useLogin} from "./LoginContext"
import './pages/StyleComponents/NavBar.css'

export default function Navbar() {
  const {isLoggedIn, setIsLoggedIn} = useLogin()
  const [open, setOpen] = useState(false)
  const [openPrograms, setOpenPrograms] = useState(false)
  const [smallWidth, setSmallWidth] = useState(window.innerWidth <= 940)
  const sidebarRef = useRef()

  useEffect(()=>{
    window.addEventListener('click',handleClick)
    return ()=>window.removeEventListener('click',handleClick)
  },[])

  const handleClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)){
      closeSidebar()
    }
  }
  const logoutButton = (e) => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
  }
  const changeNavbar = () => {setSmallWidth(window.innerWidth <= 940)}
  window.addEventListener('resize', changeNavbar)
  
  const clickHamburger = () => {setOpen(!open)}
  const closeSidebar = () => {setOpen(false)}

  if (!smallWidth) return (
    <nav className="nav">
      <Link to="/" className="site-title">
        <img src="home-icon-white.png" alt="home" className="home-icon"/>
        Tenafly Prep Academy
      </Link>
      <ul className="nav-ul">
        <CustomLink to="/DigitalTestingIntro">Digital Testing</CustomLink>
        <CustomLink to="/DigitalSAT">Digital SAT</CustomLink>
        <CustomLink to="/Vocab">Vocab</CustomLink>
        <li onClick={()=>setOpenPrograms(true)} onPointerOver={()=>setOpenPrograms(true)} onPointerOut={()=>setOpenPrograms(false)} className="dropdown-element">
          <a href="#">Programs</a>
          {openPrograms ? 
            <ul className="dropdown-menu">
              <CustomLink to="/Summer">Summer 2024</CustomLink>
              <CustomLink to="/Courses">Course Catalog</CustomLink>
              <CustomLink to="/BookClub">Book Club</CustomLink>
            </ul> : 
            <></>
          }
        </li>
        <CustomLink to="/FAQ">FAQ</CustomLink>
      </ul>
      <ul className="nav-ul">
        {isLoggedIn ? <CustomLink to="/Dashboard">Dashboard</CustomLink> : <></>}
        {isLoggedIn ? 
          (<Link to="/Login" onClick={logoutButton}>Logout&nbsp;<img src="logout-icon-white.png" alt="logout" className="loginout-icon"/></Link>) :
          (<CustomLink to="/Login">Login<img src="login-icon-white.png" alt="login" className="loginout-icon"/></CustomLink>)
        }
      </ul>
    </nav>
  ); else return(
    <div ref={sidebarRef}>
      {open ? 
        (
          <div className="sidebar">
            <ul>
                <CustomLink to="/" onClick={closeSidebar}>Home</CustomLink>
                <CustomLink to="/DigitalTestingIntro" onClick={closeSidebar}>Digital Testing</CustomLink>
                <CustomLink to="/DigitalSAT" onClick={closeSidebar}>Digital SAT</CustomLink>
                <CustomLink to="/Vocab" onClick={closeSidebar}>Vocab</CustomLink>
                <CustomLink to="/Courses" onClick={closeSidebar}>Courses</CustomLink>
                <li><a href="https://classroom.google.com/u/0/c/NTc4NTU1NDQ0MjEy" target="_blank" rel="noreferrer">Book Club</a></li>
                <CustomLink to="/FAQ" onClick={closeSidebar}>FAQ</CustomLink>
            </ul>
          </div>
        ) : <></>}
      <nav className="nav">
        <div onClick={clickHamburger}>
          <div className={open ? "nav-icon2 open" : "nav-icon2"}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className="nav-ul">
          {isLoggedIn ? <CustomLink to="/Dashboard">Dashboard</CustomLink> : <></>}
          {isLoggedIn ? 
            (<Link to="/Login" onClick={logoutButton}>Logout&nbsp;<img src="logout-icon-white.png" alt="logout" style={{width:'1.25rem'}}/></Link>) :
            (<CustomLink to="/Login">Login<img src="login-icon-white.png" alt="login" style={{width:'1.25rem'}}/></CustomLink>)
          }
        </ul>
      </nav>
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
