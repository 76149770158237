import React, {useMemo} from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

export default function GMap(){
    const mapStyles = {        
        height: "60vh",
        width: "100%"};
  
    const position = useMemo(()=>({lat: 40.925223, lng: -73.965638}),[])
    
    return (
            <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={17}
            center={position}>
                <MarkerF position={position} title="Tenafly Prep Academy"/>
            </GoogleMap>
    )
}