import React, {useState, useRef} from 'react'
import './CodeDemo.css'

export default function CodeDemo(){
    const outputRef = useRef()
    const algoRef = useRef()
    const [algorithm, setAlgorithm] = useState("Print Evens")
    const checkPrimeAnswers = ["False","True","False","True"]
    const listNodeAnswers = ["17","5","17"]

    function changeAlgorithm(e){
        if (algoRef){
            if (e.target.value === "Print Evens"){
                setAlgorithm("Print Evens")
                algoRef.current.innerHTML = `<span>for i in range(100):</span>
                    <span>&nbsp;&nbsp;&nbsp;if i%2 == 0:</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; print(i)</span>`
            }
            else if (e.target.value === "Check Prime"){
                setAlgorithm("Check Prime")
                algoRef.current.innerHTML = `<span>def checkPrime(num):</span>
                    <span>&nbsp;&nbsp;&nbsp;if num < 2:</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return False</span>
                    <span>&nbsp;&nbsp;&nbsp;for i in range(2,num):</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if num%i == 0:</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return False</span>
                    <span>&nbsp;&nbsp;&nbsp;return True</span>
                    <br/>
                    <span>print(checkPrime(6))</span>
                    <span>print(checkPrime(11))</span>
                    <span>print(checkPrime(117))</span>
                    <span>print(checkPrime(103457))</span>`
            }
            else if (e.target.value === "ListNode"){
                setAlgorithm("ListNode")
                algoRef.current.innerHTML = `<span>class ListNode:</span>
                    <span>&nbsp;&nbsp;&nbsp;def __init__(self,val,next=None):</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;self.val = val</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;self.next = next</span>
                    <span>&nbsp;&nbsp;&nbsp;def __str__(self):</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return str(self.val)</span>
                    <br/>
                    <span>node1 = ListNode(17)</span>
                    <span>node2 = ListNode(5, node1)</span>
                    <br/>
                    <span>print(node1)</span>
                    <span>print(node2)</span>
                    <span>print(node2.next)</span>`
            }
            else{
                algoRef.current.innerHTML = ""
            }
        }
        
    }

    function run(){
        if (outputRef) {
            if (algorithm === "Print Evens"){
                var i = 0;
                function addNumber() {
                    if (i < 100) {
                        outputRef.current.innerHTML += i
                        outputRef.current.innerHTML += "<br/>"
                        outputRef.current.scrollTop = outputRef.current.scrollHeight
                        i += 2
                        setTimeout(addNumber, 50)
                    }
                }
                addNumber()
            }
            else if (algorithm === "Check Prime"){
                var j = 0;
                function primeOutput(){
                    if (j < 4){
                        outputRef.current.innerHTML += checkPrimeAnswers[j]
                        outputRef.current.innerHTML += "<br/>"
                        outputRef.current.scrollTop = outputRef.current.scrollHeight
                        j += 1
                        setTimeout(primeOutput, 50)
                    }
                }
                primeOutput()
            }
            else if (algorithm === "ListNode"){
                var k = 0;
                function listNodeOutput(){
                    if (k < 3){
                        outputRef.current.innerHTML += listNodeAnswers[k]
                        outputRef.current.innerHTML += "<br/>"
                        outputRef.current.scrollTop = outputRef.current.scrollHeight
                        k += 1
                        setTimeout(listNodeOutput, 50)
                    }
                }
                listNodeOutput()
            }
        }          
    }

    function clear(){
        if (outputRef){
            outputRef.current.innerHTML = ""
        }
    }

    return(
        <>
            <div className="code-demo-container">
                <select onChange={(e)=>changeAlgorithm(e)}>
                    <option value="Print Evens">Print Evens</option>
                    <option value="Check Prime">Check Prime</option>
                    <option value="ListNode">ListNode</option>
                </select>
                <div ref={algoRef} className="algorithm">
                    <span>for i in range(100):</span>
                    <span>&nbsp;&nbsp;&nbsp;if i%2 == 0:</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; print(i)</span>
                </div>
                <button onClick={()=>run()}>Run</button>
                <button onClick={()=>clear()}>Clear Console</button>
                <div ref={outputRef} className="output-container"/>
            </div>
        </>
    )
}