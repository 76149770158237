import React, {useState} from 'react'
import axios from 'axios'
import './AssignHomework.css'

export default function AssignHomework(){
    const [studentList, setStudentList] = useState([])
    const [assignedStudents, setAssignedStudents] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const [search3, setSearch3] = useState('')
    const [bundleList, setBundleList] = useState([])
    const [assignBundle, setAssignBundle] = useState('')

    async function getStudents(){
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/allStudents",{
                headers: {"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            setStudentList(response.data)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    async function getHomeworkBundles(){
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/getAllHomeworkBundles", {
                headers: {"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            setBundleList(response.data)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    async function assignHomework(){
        if (assignBundle === ''){
            alert("You have not chosen a homework bundle.")
            return
        }
        if (assignedStudents.length === 0){
            alert("You have not chosen students to assign homework for")
            return
        }
        try{
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/homework/assign",{
                "JWT": `Bearer ${localStorage.getItem('user')}`,
                "homework_bundle_id": assignBundle,
                "emails": assignedStudents.map((stu)=>stu.email).join(",")
            })
            if (response.data === "no bundle") alert("No homework bundle was found in the database.")
            else if (response.data === "") alert("Successfully assigned homework")
            else alert(`Homework was not successfully assigned to ${response.data}`)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    function addStudent(stu){
        for (var i = 0; i < assignedStudents.length; i++){
            if (stu.email === assignedStudents[i].email) return
        }
        setAssignedStudents((prevState)=>[...prevState, stu])
    }

    function removeStudent(stu){
        for (var i = 0; i < assignedStudents.length; i++){
            if (stu.email === assignedStudents[i].email){
                var list = [...assignedStudents]
                list.splice(i,1)
                setAssignedStudents(list)
                return
            }
        }
    }

    return(
        <>
            <div className="choose-students-wrapper">
                <div className="select-students-container">
                    <button onClick={(e)=>getStudents()}>Get All Students</button>
                    <div><label htmlFor="s1">Search: </label><input id="s1" onChange={(e)=>setSearch(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {studentList.filter((stu)=>{
                            return search === '' ? stu : (`${stu.first_name}${stu.last_name}${stu.email}`).toLowerCase().includes(search)
                        }).map((stu)=>{
                            return <li onClick={()=>addStudent(stu)} key={stu.email}>{`${stu.first_name} ${stu.last_name} (${stu.email})`}</li>
                        })}
                    </ul>
                </div>
                <div className="select-students-container">
                    <span>Assigned Students</span>
                    <div><label htmlFor="s2">Search: </label><input id="s2" onChange={(e)=>setSearch2(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {assignedStudents.filter((stu)=>{
                            return search2 === '' ? stu : (`${stu.first_name}${stu.last_name}${stu.email}`).toLowerCase().includes(search2)
                        }).map((stu)=>{
                            return <li onClick={()=>removeStudent(stu)} key={stu.email}>{`${stu.first_name} ${stu.last_name} (${stu.email})`}</li>
                        })}
                    </ul>
                </div>
                <div className="bundle-container">
                    <button onClick={(e)=>getHomeworkBundles()}>Get All Homework Bundles</button>
                    <div><label htmlFor="s3">Search: </label><input id="s3" onChange={(e)=>setSearch3(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {bundleList.filter((b)=>{
                            return search3 === '' ? b : b.name.toLowerCase().includes(search3)
                        }).map((b)=>{
                            return <li onClick={()=>setAssignBundle(b.name)}key={b.name}>{b.name}</li>
                        })}
                    </ul>
                </div>
            </div>
            <div><label htmlFor="sb">Assign Bundle Name: </label><input id="sb" value={assignBundle} readOnly/></div>
            <button onClick={()=>assignHomework()}>Assign Homework</button>
        </>
    )
}