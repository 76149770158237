import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';

export default function MakeTeacher(){
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(()=>{
        setErrMsg('')}, [email, firstName, lastName, phoneNumber]
    )

    const getSecret = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
        var res = ''
        for (var i = 0; i < 10; i++){
            res += chars.charAt(Math.floor(Math.random()*36))
        }
        return res
    }

    const submitForm = async (e) =>{
        e.preventDefault();

        if (email === ''){
            setErrMsg('Email cannot be blank');
            return;
        } else if (firstName === ''){
            setErrMsg('First name must be provided');
            return;
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/teacher/create', {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "phone_number": phoneNumber,
                "secret": (getSecret()),
                "JWT":("Bearer "+localStorage.getItem('user'))
            })
            if (response.data){
                alert('Teacher has been added to the database.')
                console.log(response.data)
                return
            }
            setErrMsg('Something went wrong. Please try again later')
            console.log(response.data)
        } catch{
            setErrMsg('No server response. Please try again later.')
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Email: <input className="admin-input" onChange={e=>setEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        First Name: <input className="admin-input" onChange={e=>setFirstName(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Last Name: <input className="admin-input" onChange={e=>setLastName(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Phone Number: <input className="admin-input" onChange={e=>setPhoneNumber(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Add Student</button>
            </form>
        </>
    );
}