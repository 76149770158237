import Navbar from "./Navbar"
import Login from "./pages/Login"
import Home from "./pages/Home"
import Account from "./pages/Dashboard"
import Admin from "./pages/Admin"
import Testing from './pages/Testing'
import StandardizedTesting from "./pages/StandardizedTesting"
import Scheduler from "./pages/Scheduler"
import Courses from "./pages/Courses"
import Dashboard from "./pages/Dashboard"
import ParentDashboard from "./pages/ParentDashboard"
import Pricing from "./pages/Pricing"
import TeacherDashboard from "./pages/TeacherDashboard"
import ScheduleChecker from './pages/ScheduleChecker'
import DigitalTesting from './pages/DigitalTesting'
import AdminTestingPage from './pages/AdminTestingPage'
import Summer2023 from './pages/Summer2023'
import Summer2024 from './pages/Summer2024'
import Homework from './pages/Homework'
import DigitalSAT from './pages/DigitalSAT'
import Review from './pages/Review'
import PSATAdvert from './pages/PSATAdvert'
import OCR from './pages/OCR'
import VocabPractice from './pages/VocabPractice'
import BookClub from './pages/BookClub'
import DigitalTestingIntro from './pages/DigitalTestingIntro'
import Help from './pages/Help'
import { Route, Routes, useLocation } from "react-router-dom"
import React, {useState, useMemo} from 'react'
import {LoginContext} from './LoginContext'
import ScrollUpButton from './pages/Components/ScrollUpButton'
import ScrollToTop from './pages/Components/ScrollToTop'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user') !== null);
  const loginMemo = useMemo(
    () => ({isLoggedIn, setIsLoggedIn}),
    [isLoggedIn]
  )
  const location = useLocation()
  return (
    <>
      <LoginContext.Provider value={loginMemo}>
        {location.pathname.toLowerCase() === "/digitaltesting" ? <></> : <Navbar/>}
        <div className="container">
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Testing" element={<Testing/>} />
            <Route path="/StandardizedTesting" element={<StandardizedTesting />} />
            <Route path="/Scheduler" element={<Scheduler />} />
            <Route path="/Courses" element={<Courses />} />
            <Route path="/Dashboard" element={<Dashboard/>} />
            <Route path="/ParentDashboard" element={<ParentDashboard/>} />
            <Route path="/Login" element={<Login/>} />
            <Route path="/Admin" element={<Admin/>}/>
            <Route path="/Pricing" element={<Pricing/>}/>
            <Route path="/TeacherDashboard" element={<TeacherDashboard/>}/>
            <Route path="/Summer" element={<Summer2024/>}/>
            <Route path="/DigitalTesting" element={<DigitalTesting/>}/>
            <Route path="/AdminTestingPage" element={<AdminTestingPage/>}/>
            <Route path="/Homework" element={<Homework/>}/>
            <Route path="/DigitalSAT" element={<DigitalSAT/>}/>
            <Route path="/Review" element={<Review/>}/>
            <Route path="/PSAT" element={<PSATAdvert/>}/>
            <Route path="/OCR" element={<OCR/>}/>
            <Route path="/Vocab" element={<VocabPractice/>}/>
            <Route path="/BookClub" element={<BookClub/>}/>
            <Route path="/DigitalTestingIntro" element={<DigitalTestingIntro/>}/>
            <Route path="/ScheduleChecker" element={<ScheduleChecker/>}/>
            <Route path="/FAQ" element={<Help/>}/>
          </Routes>
          <ScrollUpButton/>
        </div>
      </LoginContext.Provider>
    </>
  )
}

export default App
