import React from 'react'
import '../StyleComponents/DashboardAlert.css'
import { useNavigate } from 'react-router-dom'

export default function DashboardAlert({student, width}){
    const goToDigitalTesting = () => {
        const newTab = window.open("https://www.tenaflyprepacademy.com/DigitalTesting", "_blank")
        newTab.focus()
    }

    if (student.Queued_Digital_SAT){
        return(
            <div className="alert-bar" onClick={()=>goToDigitalTesting()}>
                <span style={{fontWeight:"bold"}}>You've been assigned a <span className="blinking">Digital Test.</span> {width > 800 ? <span>Click to Navigate to the Digital Testing Platform.</span> : ""}</span>
            </div>
        )
    }
    return <></>
}