import React, {useState,useEffect} from 'react'
import SATScoreDisplay from './SATScoreDisplay'
import '../StyleComponents/DashboardOverview.css'
import { useNavigate } from 'react-router-dom'

export default function DashboardOverview({student, toDigitalSAT, toPSAT, toACT, toSAT}){
    // console.log(student)
    const [SATTest, setSATTest] = useState(null)
    const [PSATTest, setPSATTest] = useState(null)
    const [ACTTest, setACTTest] = useState(null)
    const [paperSATTest,setPaperSATTest] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        if (student){
            if (student.Digital_SAT) setSATTest(student.Digital_SAT.sort((a,b)=>{return b.date_as_int - a.date_as_int})[0])
            if (student.PSAT) setPSATTest(student.PSAT.sort((a,b)=>{return b.date_as_int - a.date_as_int})[0])
            if (student.ACT) setACTTest(student.ACT.sort((a,b)=>{return new Date(b.date) - new Date(a.date)})[0])
            if (student.SAT) setPaperSATTest(student.SAT.sort((a,b)=>{return b.date_as_int - a.date_as_int})[0])
        }
    },[student])

    if (student === null || student === ''){
        return (<>
            <div>
                <h2>Please select a student.</h2>
            </div>
        </>)
    }

    function getUnfinishedHomework(){
        if (student.homework){
            const homeworkList = student.homework
            const hash = `${student.first_name} ${student.last_name},${student.email}`
            var count = homeworkList.length;
            for (var i = 0; i < homeworkList.length; i++){
                if (homeworkList[i].student_answers[hash]) count--
            }
            return count
        } else{
            return 0
        }
        
    }

    return (
        <div>
            <div className="overview-platform-links-container">
                <div className="overview-platform-links">
                    <span className="overview-platform-links-title">Homework</span><br/>
                    <span className="overview-platform-links-description">You have <span style={{fontWeight:"bold"}}>{getUnfinishedHomework()}</span> unfinished homework(s).</span><br/>
                    <button className="overview-button" onClick={()=>navigate("/Homework")}>Homework Page</button>
                </div>
                <div className="overview-platform-links">
                    <span className="overview-platform-links-title">AI Powered Vocab</span><br/>
                    <span className="overview-platform-links-description">You have <span style={{fontWeight:"bold"}}>{student.vocab_submissions ? student.vocab_submissions : 0}</span> submissions.</span><br/>
                    <button className="overview-button" onClick={()=>navigate("/Vocab")}>Vocab Page</button>
                </div>
            </div>
            {student.Digital_SAT && student.Digital_SAT.length > 0 ?
            <div className="dashboard-score-display-container">
                <SATScoreDisplay test={SATTest} displayingMostRecent={true} testType={"SAT"} nav={toDigitalSAT}/>
                <div style={{textAlign:"left"}}>
                    You've taken <span style={{fontWeight:"bold"}}>{student.Digital_SAT.length}</span> Digital SATs.
                </div>
            </div>
            : <></>}
            {student.SAT && student.SAT.length > 0 ?
            <div className="dashboard-score-display-container">
                <SATScoreDisplay test={paperSATTest} displayingMostRecent={true} testType={"PaperSAT"} nav={toSAT}/>
                <div style={{textAlign:"left"}}>
                    You've taken <span style={{fontWeight:"bold"}}>{student.Digital_SAT.length}</span> Paper SATs.
                </div>
            </div>
            : <></>}
            {student.ACT && student.ACT.length > 0 ?
            <div className="dashboard-score-display-container">
                <SATScoreDisplay test={ACTTest} displayingMostRecent={true} testType={"ACT"} nav={toACT}/>
                <div style={{textAlign:"left"}}>
                    You've taken <span style={{fontWeight:"bold"}}>{student.ACT.length}</span> ACTs.
                </div>
            </div>
            : <></>
            }
            {student.PSAT && student.PSAT.length > 0 ?
            <div className="dashboard-score-display-container">
                <SATScoreDisplay test={PSATTest} displayingMostRecent={true} testType={"PSAT"} nav={toPSAT}/>
                <div style={{textAlign:"left"}}>
                    You've taken <span style={{fontWeight:"bold"}}>{student.PSAT.length}</span> PSATs.
                </div>
            </div>
            :<></>
            }
        </div>
    )
}