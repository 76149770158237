import React from 'react'
import './Calendar.css'

export default function Calendar(props){

    return(
        <>
            <div className="calendar">
                <div className="month-title">
                    <span>{props.month}</span>
                </div>
                {props.month === "August" ? 
                    (<>
                        <table className="day">
                            <tbody>
                                <tr>
                                    <td>Su</td>
                                    <td>Mo</td>
                                    <td>Tu</td>
                                    <td>We</td>
                                    <td>Th</td>
                                    <td>Fr</td>
                                    <td>Sa</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dates">
                            <tbody>
                                <tr>
                                    <td className="rollover">28</td>
                                    <td className="rollover fill">29</td>
                                    <td className="rollover fill">30</td>
                                    <td className="rollover fill">31</td>
                                    <td className="fill">1</td>
                                    <td className="fill">2</td>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td className="fill">5</td>
                                    <td className="fill">6</td>
                                    <td className="fill">7</td>
                                    <td className="fill">8</td>
                                    <td className="fill">9</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td className="fill">12</td>
                                    <td className="fill">13</td>
                                    <td className="fill">14</td>
                                    <td className="fill">15</td>
                                    <td className="fill">16</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>19</td>
                                    <td>20</td>
                                    <td>21</td>
                                    <td>22</td>
                                    <td>23</td>
                                    <td>24</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>26</td>
                                    <td>27</td>
                                    <td>28</td>
                                    <td>29</td>
                                    <td>30</td>
                                    <td>31</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="rollover"> </td>
                                    <td className="rollover"> </td>
                                    <td className="rollover"> </td>
                                    <td className="rollover"> </td>
                                    <td className="rollover"> </td>
                                    <td className="rollover"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </>):
                    (<>
                        <table className="day">
                            <tbody>
                                <tr>
                                    <td>Su</td>
                                    <td>Mo</td>
                                    <td>Tu</td>
                                    <td>We</td>
                                    <td>Th</td>
                                    <td>Fr</td>
                                    <td>Sa</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dates">
                            <tbody>
                                <tr>
                                    <td className="rollover"> </td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td className="fill">8</td>
                                    <td className="fill">9</td>
                                    <td className="fill">10</td>
                                    <td className="fill">11</td>
                                    <td className="fill">12</td>
                                    <td>13</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td className="fill">15</td>
                                    <td className="fill">16</td>
                                    <td className="fill">17</td>
                                    <td className="fill">18</td>
                                    <td className="fill">19</td>
                                    <td>20</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td className="fill">22</td>
                                    <td className="fill">23</td>
                                    <td className="fill">24</td>
                                    <td className="fill">25</td>
                                    <td className="fill">26</td>
                                    <td>27</td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td className="fill">29</td>
                                    <td className="fill">30</td>
                                    <td className="fill">31</td>
                                    <td className="rollover fill">1</td>
                                    <td className="rollover fill">2</td>
                                    <td className="rollover">3</td>
                                </tr>
                                <tr>
                                    <td className="rollover">4</td>
                                    <td className="rollover fill">5</td>
                                    <td className="rollover fill">6</td>
                                    <td className="rollover fill">7</td>
                                    <td className="rollover fill">8</td>
                                    <td className="rollover fill">9</td>
                                    <td className="rollover">10</td>
                                </tr>
                            </tbody>
                        </table>
                    </>)
                }
            </div>
        </>
    )
}