import React, {useState, useEffect} from 'react'
import axios from 'axios'
import OpenAI from 'openai'
import './StyleComponents/VocabPractice.css'
import allWords from '../VocabWordList'
import TypingAnimation from './Components/TypingAnimation'
import LoadingIndicator from './Components/LoadingIndicator'

export default function VocabPractice(){
    const [student, setStudent] = useState(null)
    const [showDefinition, setShowDefinition] = useState(false)
    const [showExamples, setShowExamples] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [submitCount, setSubmitCount] = useState(0)
    const [AIResponse, setAIResponse] = useState('')
    const [studentSubmission, setStudentSubmission] = useState('')
    const [wordList, setWordList] = useState([])

    useEffect(()=>{
        axios.get('https://www.tenaflyprepacademy.com/server/user', {
            headers: {"jwt": `Bearer ${localStorage.getItem('user')}`}}).then(response => {
                setStudent(response.data.user)
                setSubmitCount(0)
            }).catch(error => {
                alert("Something went wrong. You are probably not logged in. Please login first.")
                setStudent(null)
                setSubmitCount(1)
                console.log(error)
            })
    },[])

    const openai = new OpenAI({apiKey: process.env.REACT_APP_CHAT_GPT_API_KEY, dangerouslyAllowBrowser: true})

    function checkIfBold(word){
        for (var i = 0; i < wordList.length; i++){
            if (word.indexOf(wordList[i].word) >= 0){
                return <b>{word} </b>
            }
        }
        return word + " "
    }
    function findBoldWords(text){
        const words = text.split(" ")
        return <p>{words.map(word=> {return checkIfBold(word)})}</p>
    }

    async function submitForGrading(){
        if (studentSubmission.length < 20){
            alert("Your submission is too short. Please type your response before submitting.")
            return
        }
        if (submitCount > 0){
            alert("You've already submitted this assignment. If you have not received a grade yet, something went wrong or you are sending too many requests")
            return
        }
        setSubmitCount(submitCount+1)
        setShowLoading(true)
        var message = `Please check if I used the following vocabulary words or their other forms correctly in the following paragraph: ${studentSubmission} 
        \nVocab words: ${wordList.map(word=>word.word).join(",")}
        \nCan you then give me a grade and breakdown for this? 1 point each for the correct usage of the vocab words and 1 final point if the paragraph was creative (You do not need to be modest about the creativity of the paragraph nor modest about whether the vocab words were used correctly).
        \nPlease structure your response like this:
        \nNo greeting required
        \nList of vocab words and brief comment on why it was used correctly/incorrectly
        \nSummarize the grading breakdown including why the points for creativity were given
        \nLast line displaying the grade in "?/6" format`
        console.log(message)
        const response = await openai.chat.completions.create({
            messages: [{role: 'user', content: message}],
            model: "gpt-3.5-turbo"
        })
        setAIResponse(response.choices[0].message.content)
        setShowLoading(false)
        const currTime = new Date()
        try{
            const serverResponse = await axios.post('https://www.tenaflyprepacademy.com/server/vocab/submit',{
                "date_and_time" : `${currTime.getMonth()+1}/${currTime.getDate()}/${currTime.getFullYear()} ${currTime.getHours()}:${currTime.getMinutes() < 10 ? "0" : ""}${currTime.getMinutes()}:${currTime.getSeconds()}`,
                "JWT": localStorage.getItem('user'),
                "student_submission": `${studentSubmission}`,
                "ai_response": `${response.choices[0].message.content}`,
                "words": wordList.join()
            })
            console.log(serverResponse)
            try{
                const getUserAgainResponse = await axios.get('https://www.tenaflyprepacademy.com/server/user', {
                    headers: {"jwt": `Bearer ${localStorage.getItem('user')}`}
                })
                setStudent(getUserAgainResponse.data.user)
            } catch(err){
                console.log("error getting user after posting")
                console.log(err)
            }
        }catch(err){
            console.log(err)
            alert("Something went wrong when saving your submission on our database.")
        }
    }

    function generateRandomWords(){
        const checkboxes = document.querySelectorAll('.vocab-checkbox')
        checkboxes.forEach(checkbox => {
        checkbox.checked = false;
        })

        setWordList([])
        const shuffleArray = (array) => {
            const shuffledArray = array.slice();
            for (let i = shuffledArray.length - 1; i > 0; i--) {
              const randomIndex = Math.floor(Math.random() * (i + 1));
              [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
            }
            return shuffledArray;
        };

        const shuffled = shuffleArray(allWords)
        for (var i = 0; i < 5; i++){
            const currWord = shuffled[i]
            setWordList(prevWordList => [...prevWordList, currWord])
        }
        if (student !== null) setSubmitCount(0)
        setStudentSubmission('')
        setAIResponse('')
    }

    return(
        <>
            <h1>AI Powered Vocab Training</h1>
            {student === null ? <></> : 
            <div className="vocab-greeting-container">
                <span className="vocab-greeting">Hello {student.first_name} {student.last_name}!</span>
                <br/>
                <span>You have <span style={{border:"2px black solid"}}>{student.vocab_submissions ? student.vocab_submissions : 0}</span> submissions left</span>
            </div>}
            <div className="vocab-instructions-container">
                <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>Instructions:</span><br/>
                <span style={{fontWeight:"bold"}}>Click on "Generate 5 Random Words" and write a short creative paragraph/story using the 5 words.</span>
            </div>
            <button className="vocab-button" onClick={()=>generateRandomWords()}>Generate 5 Random Words</button>
            <br/>
            <div className="word-list-container">
                <ul className="word-list">
                    {wordList.map((word,i)=>{
                        return <li key={word+"-"+i}>
                                    <span className="word-span">{word.word}<input className="vocab-checkbox" type="checkbox" id={`check-${word.word}-${i}`}/></span>
                                    <span className="definition-span">{` ${showDefinition ? ("Definition: ("+word.part_of_speech+") "+word.definition) : ""}`}</span>
                                    <span className="example-span">{` ${showExamples ? ("Example: "+word.example) : ""}`}</span>
                                </li>
                    })}
                </ul>
            </div>
            <div className="show-hide-button-container">
                <button className="vocab-button" onClick={()=>setShowDefinition(!showDefinition)}>{showDefinition ? "Hide Definitions" : "Show Definitions"}</button>
                <button className="vocab-button" onClick={()=>setShowExamples(!showExamples)}>{showExamples ? "Hide Examples" : "Show Examples"}</button>
            </div>
            <b className="hr anim"/>
            <div className="student-submission-container">
                <h2 style={{marginTop:"0"}}>Type Your Response:</h2>
                <textarea
                    rows="10"
                    style={{width:"80%"}}
                    onChange={(e)=>setStudentSubmission(e.target.value)}
                    value={studentSubmission}
                    placeholder="Type your response here..."
                ></textarea>
                <div>
                    <h3>Preview:</h3>
                    {findBoldWords(studentSubmission)}
                    {/* <p style={{textAlign:"left"}}>{findBoldWords(studentSubmission)}</p> */}
                </div>
            </div>
            <br/>
            {submitCount === 0 ? <button className="vocab-submit-button" onClick={()=>submitForGrading()}>Submit for Grading</button> : <></>}
            {showLoading ? <LoadingIndicator/> : <></>}
            <b className="hr anim"/>
            <h2 style={{color: 'rgb(0, 0, 155)', marginTop:"0"}}>AI Grading Response</h2>
            <div className="AI-response-div">
                <TypingAnimation text={AIResponse}/>
            </div>
        </>
    )
}