import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';

export default function MakeSATAnswer(){
    const errRef = useRef();

    const [testId, setTestId] = useState('');
    const [readingAnswers, setReadingAnswers] = useState('');
    const [readingQuestionType, setReadingQuestionType] = useState('');
    const [readingQuestionLevel, setReadingQuestionLevel] = useState('');
    const [writingAnswers, setWritingAnswers] = useState('');
    const [writingQuestionType, setWritingQuestionType] = useState('');
    const [writingQuestionLevel, setWritingQuestionLevel] = useState('');
    const [noCalcAnswers, setNoCalcAnswers] = useState('');
    const [noCalcQuestionType, setNoCalcQuestionType] = useState('');
    const [calcAnswers, setCalcAnswers] = useState('');
    const [calcQuestionType, setCalcQuestionType] = useState('');
    const [readingScale, setReadingScale] = useState('');
    const [writingScale, setWritingScale] = useState('');
    const [mathScale, setMathScale] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(()=>{
        setErrMsg('')
    },[testId, readingAnswers, readingQuestionType, readingQuestionLevel, writingAnswers, writingQuestionType,
    writingQuestionLevel, noCalcAnswers, noCalcQuestionType, calcAnswers, calcQuestionType,
    readingScale, writingScale, mathScale]);

    const submitForm = async (e) => {
        e.preventDefault();
        if (readingAnswers.split(",").length !== 52){
            setErrMsg("Check Reading Answers");
            return;
        } else if (readingQuestionType.split(",").length !== 52){
            setErrMsg("Check Reading Question Type");
            return;
        } else if (readingQuestionLevel.split(",").length !== 52){
            setErrMsg("Check Reading Question Level");
            return;
        } else if (writingAnswers.split(",").length !== 44){
            setErrMsg("Check Writing Answers");
            return;
        } else if (writingQuestionType.split(",").length !== 44){
            setErrMsg("Check Writing Question Type");
            return;
        } else if (writingQuestionLevel.split(",").length !== 44){
            setErrMsg("Check Writing Question Level");
            return;
        } else if (noCalcAnswers.split(",").length !== 20){
            setErrMsg("Check No Calc Answers");
            return;
        } else if (noCalcQuestionType.split(",").length !== 20){
            setErrMsg("Check No Calc Question Type");
            return;
        } else if (calcAnswers.split(",").length !== 38){
            setErrMsg("Check Calc Answers");
            return;
        } else if (calcQuestionType.split(",").length !== 38){
            setErrMsg("Check Calc Question Type");
            return;
        } else if (readingScale.split(",").length !== 53){
            setErrMsg("Check Reading Scale");
            return;
        } else if (writingScale.split(",").length !== 45){
            setErrMsg("Check Writing Scale");
            return;
        } else if (mathScale.split(",").length !== 59){
            setErrMsg("Check Math Scale");
            return;
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/answerkey/SAT', {
                "test_id":testId,
                "reading_answers": readingAnswers,
                "writing_answers":writingAnswers,
                "math_nocalc_answers":noCalcAnswers,
                "math_calc_answers":calcAnswers,
                "reading_question_type":readingQuestionType,
                "reading_question_difficulty":readingQuestionLevel,
                "writing_question_type":writingQuestionType,
                "writing_question_difficulty":writingQuestionLevel,
                "math_nocalc_question_type":noCalcQuestionType,
                "math_calc_question_type":calcQuestionType,
                "reading_scale":readingScale,
                "writing_scale":writingScale,
                "math_scale":mathScale,
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            if (!response.data) alert('Session expired, please sign in again.')
            else alert('Answer key successfully updated. Go to "Get All Tests" to check if the test has been updated correctly.')
        } catch{
            setErrMsg("No server response.  Please try again later.")
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Test ID (Month Year): <input className="admin-input" onChange={e=>setTestId(e.target.value)}></input> 
                    </li>
                    <li className="admin-list">
                        Reading Answers: <input className="admin-input" onChange={e => setReadingAnswers(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Reading Question Type: <input className="admin-input" onChange={e=>setReadingQuestionType(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Reading Question Level: <input className="admin-input" onChange={e=>setReadingQuestionLevel(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Writing Answers: <input className="admin-input" onChange={e=>setWritingAnswers(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Writing Question Type: <input className="admin-input" onChange={e=>setWritingQuestionType(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Writing Question Level: <input className="admin-input" onChange={e=>setWritingQuestionLevel(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        No Calc Answers: <input className="admin-input" onChange={e=>setNoCalcAnswers(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        No Calc Question Type: <input className="admin-input" onChange={e=>setNoCalcQuestionType(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Calc Answers: <input className="admin-input" onChange={e=>setCalcAnswers(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Calc Question Type: <input className="admin-input" onChange={e=>setCalcQuestionType(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Reading Scale: <input className="admin-input" onChange={e=>setReadingScale(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Writing Scale: <input className="admin-input" onChange={e=>setWritingScale(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Math Scale: <input className="admin-input" onChange={e=>setMathScale(e.target.value)}></input>
                    </li>
                </ul>
                <button type="submit">Make SAT Answer Key</button>
            </form>
        </>
    );
}