import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'

export default function MakeParent(){
    const errRef = useRef()

    const [errMsg, setErrMsg] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [confirmParentEmail, setConfirmParentEmail] = useState('')

    useEffect(()=>{
        setErrMsg('')}, [parentEmail, confirmParentEmail]
    );

    const getSecret = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
        var res = ''
        for (var i = 0; i < 10; i++){
            res += chars.charAt(Math.floor(Math.random()*36))
        }
        return res
    }

    const submitForm = async (e) =>{
        e.preventDefault()

        if (parentEmail === ''){
            setErrMsg('Student email cannot be blank')
            return
        } else if (parentEmail !== confirmParentEmail){
            setErrMsg('Emails do not match')
            return
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/parent', {
                "email": parentEmail,
                "secret": (getSecret()),
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            console.log(response)
            if (response.data === ""){
                setErrMsg('Parent already exists in database.');
                return;
            }
            alert('Parent has been added to the database.')
            console.log(response.data);
        } catch{
            setErrMsg('No server response. Please try again later.');
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color: 'darkred'}}>Only for new students. Please double check spelling upon submission</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Parent Email: <input className="admin-input" onChange={e=>setParentEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Confirm Parent Email: <input className="admin-input" onChange={e=>setConfirmParentEmail(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Add Student</button>
            </form>
        </>
    );
}