import React, {useEffect, useState, useRef} from 'react'
import '../StyleComponents/DashboardBig.css'
import DashboardAlert from './DashboardAlert'
import DashboardOverview from './DashboardOverview'
import { useNavigate } from 'react-router-dom'
import SATAdvanced from './SATAdvanced'
import DigitalSATAdvanced from './DigitalSATAdvanced'
import ACTAdvanced from './ACTAdvanced'
import PSATAdvanced from './PSATAdvanced'
import VocabPracticeHistory from './VocabPracticeHistory'
import DisplayHomework from './DisplayHomework'

export default function DashboardNew({student}){
    const [whatDoing, setWhatDoing] = useState("Home")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [displaySub, setDisplaySub] = useState(false)
    const subRef = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        window.addEventListener('resize',handleResize)
        return () => {window.removeEventListener('resize',handleResize)}
    },[])
    const handleResize = () => {setScreenWidth(window.innerWidth)}

    const toDigitalSAT = () => {
        changeWhatDoing("DigitalSAT")
        scrollToTop()
    }
    const toPSAT = () => {
        changeWhatDoing("PSAT")
        scrollToTop()
    }
    const toACT = () => {
        changeWhatDoing("ACT")
        scrollToTop()
    }
    const toSAT = () => {
        changeWhatDoing("SAT")
        scrollToTop()
    }
    function scrollToTop(){document.documentElement.scrollTop = 0;}

    function changeWhatDoing(toDo){
        if (whatDoing === toDo) return
        setWhatDoing(toDo)
    }
    const getComponent = () => {
        if (whatDoing === "Home"){
            return(
                <>
                    <DashboardAlert student={student} width={screenWidth}/>
                    <DashboardOverview student={student} toDigitalSAT={toDigitalSAT} toPSAT={toPSAT} toACT={toACT} toSAT={toSAT}/>
                </>
            )
        } else if (whatDoing === "DigitalSAT"){
            return(
                <>
                    <DigitalSATAdvanced student={student}/>
                </>
            )
        } else if (whatDoing === "PSAT"){
            return(
                <>
                    <PSATAdvanced student={student}/>
                </>
            )
        } else if (whatDoing === "ACT"){
            return(
                <>
                    <ACTAdvanced student={student}/>
                </>
            )
        } else if (whatDoing === "SAT"){
            return(
                <SATAdvanced student={student}/>
            )
        } else if (whatDoing === "Vocab"){
            return(
                <>
                    <VocabPracticeHistory vocabList={student.Vocab}/>
                </>
            )
        } else if (whatDoing === "Homework"){
            const homeworkList = [...student.homework]
            homeworkList.sort(function(a, b) {
                const dateA = new Date(a.due_date);
                const dateB = new Date(b.due_date);
                if (dateA < dateB) {
                    return 1; 
                }
                if (dateA > dateB) {
                    return -1; 
                }
                return 0;
            })
            return <DisplayHomework homeworkList={homeworkList} student={student}/>
        }
    }

    return(
        <>
            <div className="big-dashboard-container">
                {screenWidth < 950 ? 
                    <div className="dashboard-bottombar">
                        <div 
                            className="dashboard-bottombar-button" 
                            onClick={()=>setWhatDoing("Home")}
                            style={{background:(whatDoing === "Home" ? "rgb(230,230,230)" : "")}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill={whatDoing === "Home" ? "blue" : "black"}>
                                <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z"/>
                            </svg><br/>
                            <span style={{color:(whatDoing === "Home" ? "blue" : "black")}}>{screenWidth < 350 ? "Home" : "Overview"}</span>
                        </div>
                        <div 
                            className="dashboard-bottombar-button" 
                            onClick={()=>setDisplaySub(!displaySub)} 
                            ref={subRef}
                            style={{background:((whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "rgb(230,230,230)" : ""),borderTopLeftRadius:"0",borderTopRightRadius:"0"}}
                            onMouseOver={()=>setDisplaySub(true)}
                            onMouseLeave={()=>setDisplaySub(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill={(whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "blue" : "black"}>
                                <path d="m105-233-65-47 200-320 120 140 160-260 109 163q-23 1-43.5 5.5T545-539l-22-33-152 247-121-141-145 233ZM863-40 738-165q-20 14-44.5 21t-50.5 7q-75 0-127.5-52.5T463-317q0-75 52.5-127.5T643-497q75 0 127.5 52.5T823-317q0 26-7 50.5T795-221L920-97l-57 57ZM643-217q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm89-320q-19-8-39.5-13t-42.5-6l205-324 65 47-188 296Z"/>
                            </svg><br/>
                            <span style={{color:((whatDoing === "DigitalSAT" || whatDoing === "SAT" || whatDoing === "ACT" || whatDoing === "PSAT") ? "blue" : "black")}}>Scores</span>
                            <div className="dashboard-submenu" style={{display:(displaySub ? "" : "none")}}>
                                <div onClick={()=>setWhatDoing("SAT")}>SAT</div>
                                <div onClick={()=>setWhatDoing("DigitalSAT")}>Digital SAT</div>
                                <div onClick={()=>setWhatDoing("ACT")}>ACT</div>
                                <div onClick={()=>setWhatDoing("PSAT")}>PSAT</div>
                                <div onClick={()=>setWhatDoing("Homework")}>Homework</div>
                                <div onClick={()=>setWhatDoing("Vocab")}>Vocab</div>
                            </div>
                        </div>
                        <div className="dashboard-bottombar-button" onClick={()=>navigate("/Homework")}>
                            <img src="homework.svg" alt="homework" style={{height:"1.5rem",width:"1.5rem"}}/><br/>
                            <span>{screenWidth < 350 ? "HW" : "Homework"}</span>
                        </div>
                        <div className="dashboard-bottombar-button" onClick={()=>navigate("/Vocab")}>
                            <img src="dictionary.svg" alt="dict" style={{height:"1.5rem",width:"1.5rem"}}/><br/>
                            <span>Vocab</span>
                        </div>
                    </div> :
                    <div className="dashboard-sidebar">
                        <div className="dashboard-sidebar-info">
                            <img src="login-icon-white.png" alt="icon" style={{width:"5rem",height:"5rem"}}/><br/>
                            <span>{student.first_name} {student.last_name}</span>
                        </div>
                        <div className="dashboard-sidebar-nav">
                            <div 
                                style={{background:(whatDoing === "Home" ? "lightblue" : ""),color:(whatDoing === "Home" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Home")}
                            >
                                Overview
                            </div>
                            <div className="dashboard-sidebar-title">
                                Results
                            </div>
                            <div 
                                style={{background:(whatDoing === "SAT" ? "lightblue" : ""),color:(whatDoing === "SAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("SAT")}
                            >
                                SAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "DigitalSAT" ? "lightblue" : ""),color:(whatDoing === "DigitalSAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("DigitalSAT")}
                            >
                                Digital SAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "ACT" ? "lightblue" : ""),color:(whatDoing === "ACT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("ACT")}
                            >
                                ACT
                            </div>
                            <div 
                                style={{background:(whatDoing === "PSAT" ? "lightblue" : ""),color:(whatDoing === "PSAT" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("PSAT")}
                            >
                                PSAT
                            </div>
                            <div 
                                style={{background:(whatDoing === "Homework" ? "lightblue" : ""),color:(whatDoing === "Homework" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Homework")}
                            >
                                Homework
                            </div>
                            <div 
                                style={{background:(whatDoing === "Vocab" ? "lightblue" : ""),color:(whatDoing === "Vocab" ? "black" : "")}}
                                className="dashboard-sidebar-nav-item"
                                onClick={()=>changeWhatDoing("Vocab")}
                            >
                                Vocab
                            </div>
                            <div className="dashboard-sidebar-title">
                                Platforms
                            </div>
                            <div className="dashboard-sidebar-nav-item" onClick={()=>navigate("/Homework")}>
                                Homework
                            </div>
                            <div className="dashboard-sidebar-nav-item" onClick={()=>navigate("/Vocab")}>
                                Vocab
                            </div>
                        </div>
                    </div>
                }
                <div className="big-dashboard-content-container">
                    {getComponent()}
                </div>
            </div>
            
        </>
    )
}