import React, {useState} from 'react'
import axios from 'axios'

export default function MakeHomeworkQuestion(){
    const [url, setUrl] = useState('')
    const [title, setTitle] = useState('')
    const [difficulty, setDifficulty] = useState('')
    const [mr, setMr] = useState('')
    const [category, setCategory] = useState('')
    const [type, setType] = useState('')
    const [answer, setAnswer] = useState('')
    const [frq, setFrq] = useState('')

    async function submit(){
        if (url === "" ||
            title === "" ||
            (difficulty === "" || difficulty === "------") ||
            (mr === "" || mr === "------") ||
            category === "" ||
            type === "" ||
            answer === "" ||
            (frq === "" || frq === "------")){
                alert("Please double check if the information was entered correctly.")
                return
        }
        if (title.includes(",")){
            alert("Homework title cannot inclulde commas")
            return
        }
        try{
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/homework/createquestion",{
                "JWT": `Bearer ${localStorage.getItem('user')}`,
                "url": url,
                "question_id": title,
                "difficulty": (difficulty === "Easy" ? 1 : (difficulty === "Medium" ? 2 : 3)),
                "math_reading": (mr === "Math"),
                "category": category.toLowerCase(),
                "type": type.toLowerCase(),
                "answer": answer, 
                "FRQ": (frq === "Yes")
            })
            if (response.data === 0) alert("Homework question successfully added to the database.")
            else if (response.data === 1) alert("This homework question is already in the database.")
        } catch(err){
            alert("Soemthing went wrong. Please try again later.")
        }
    }
    return(
        <>
            <ul className="admin-forms">
                <li className="admin-list"><label htmlFor="url">Question Image URL: </label><input id="url" className="admin-input" onChange={(e)=>setUrl(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="title">Title: </label><input id="title" className="admin-input" onChange={(e)=>setTitle(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="diff">Difficulty: </label>
                    <select id="diff" onChange={(e)=>setDifficulty(e.target.value)}>
                        <option>------</option>
                        <option>Easy</option>
                        <option>Medium</option>
                        <option>Hard</option>
                    </select>
                </li>
                <li className="admin-list"><label htmlFor="mr">Math/Reading: </label>
                    <select id="mr" onChange={(e)=>setMr(e.target.value)}>
                        <option>------</option>
                        <option>Math</option>
                        <option>Reading/Writing</option>
                    </select>
                </li>
                <li className="admin-list"><label htmlFor="cat">Category: </label><input id="cat" className="admin-input" onChange={(e)=>setCategory(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="type">Type: </label><input id="type" className="admin-input" onChange={(e)=>setType(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="answer">Answer (Separate multiple answers with ~): </label><input id="answer" className="admin-input" onChange={(e)=>setAnswer(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="frq">Is FRQ: </label>
                    <select id="frq" onChange={(e)=>setFrq(e.target.value)}>
                        <option>------</option>
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </li>
            </ul>
            <button onClick={()=>submit()}>Create Homework Question</button>
        </>
    )
}