import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import GetAllStudents from './AdminComps/GetAllStudents'
import GetAllTests from './AdminComps/GetAllTests'
import StudentSAT from './AdminComps/StudentSAT'
import StudentACT from './AdminComps/StudentACT'
import MakeHomeworkQuestion from './AdminComps/MakeHomeworkQuestion'
import MakeHomeworkBundle from './AdminComps/MakeHomeworkBundle'
import AssignHomework from './AdminComps/AssignHomework'
import GetAllHomeworkBundles from './AdminComps/GetAllHomeworkBundles'
import './StyleComponents/Dashboard.css'

export default function Teacher() {
    const[whatDo, setWhatDo] = useState('')
    const[doing, setDoing] = useState('')
    const navigate = useNavigate()
    // eslint-disable-next-line
    useEffect(()=>{validate()},[whatDo])

    async function validate(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers: {
                    "JWT": "Bearer "+localStorage.getItem("user")
                }
            });
            if (response.data.user.role !== 3){
                alert('Unauthorized')
                navigate('/Login')
                return
            }
        } catch(err){
            alert('Please log in')
            navigate('/Login')
            return
        }
    }

    const changeWhatDo = (e) => {
        if (e.target.value === "Get All Students"){
            setWhatDo("GetAllStudents")
            setDoing(<GetAllStudents/>)
        } else if (e.target.value === 'Get All Tests'){
            setWhatDo('GetAllTests')
            setDoing(<GetAllTests/>)
        } else if (e.target.value === 'Input Student SAT Answers'){
            setWhatDo('StudentSAT')
            setDoing(<StudentSAT/>)
        } else if (e.target.value === 'Input Student ACT Answers'){
            setWhatDo('StudentACT')
            setDoing(<StudentACT/>)
        } else if (e.target.value === "Make Homework Question"){
            setWhatDo("MakeHWQ")
            setDoing(<MakeHomeworkQuestion/>)
        } else if (e.target.value === "Make Homework Bundle"){
            setWhatDo("MakeHWB")
            setDoing(<MakeHomeworkBundle/>)
        } else if (e.target.value === "Assign Homework"){
            setWhatDo("AssignHW")
            setDoing(<AssignHomework/>)
        } else if (e.target.value === "Get All Homework Bundles"){
            setWhatDo("GetAllHW")
            setDoing(<GetAllHomeworkBundles/>)
        } else{
            setWhatDo('')
            setDoing(<></>)
        }
    }

    return(
        <>
            <h1>Teacher Dashboard</h1>
            <div className="dashboard-links-div">
                <span>Click for: </span>
                <button onClick={()=>navigate("/ScheduleChecker")}>Schedule</button>
            </div>
            <div>
                Slect a service: 
                <select onChange={changeWhatDo}>
                    <option></option>
                    <option>Get All Students</option>
                    <option>Get All Tests</option>
                    <option>Get All Homework Bundles</option>
                    <option>Input Student SAT Answers</option>
                    <option>Input Student ACT Answers</option>
                    <option>Make Homework Question</option>
                    <option>Make Homework Bundle</option>
                    <option>Assign Homework</option>
                </select>
            </div>
            <div>
                {doing}
            </div>
        </>
    )
}