import React from 'react'
import './Calendar.css'

export default function CalendarPSAT2023(props){

    return(
        <>
            <div className="calendar">
                <div className="month-title">
                    <span>{props.month}</span>
                </div>
                {props.month === "September" ? 
                    (<>
                        <table className="day">
                            <tbody>
                                <tr>
                                    <td>Su</td>
                                    <td>Mo</td>
                                    <td>Tu</td>
                                    <td>We</td>
                                    <td>Th</td>
                                    <td>Fr</td>
                                    <td>Sa</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dates">
                            <tbody>
                                <tr>
                                    <td className="rollover">27</td>
                                    <td className="rollover">28</td>
                                    <td className="rollover">29</td>
                                    <td className="rollover">30</td>
                                    <td className="rollover">31</td>
                                    <td>1</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                    <td>6</td>
                                    <td className="fill-red">7</td>
                                    <td className="fill-red">8</td>
                                    <td className="fill-red">9</td>
                                </tr>
                                <tr>
                                    <td className="fill-red">10</td>
                                    <td className="fill-red">11</td>
                                    <td className="fill-red">12</td>
                                    <td className="fill-red">13</td>
                                    <td className="fill-orange">14</td>
                                    <td className="fill-orange">15</td>
                                    <td className="fill-orange">16</td>
                                </tr>
                                <tr>
                                    <td className="fill-orange">17</td>
                                    <td className="fill-orange">18</td>
                                    <td className="fill-orange">19</td>
                                    <td className="fill-orange">20</td>
                                    <td className="fill-yellow">21</td>
                                    <td className="fill-yellow">22</td>
                                    <td className="fill-yellow">23</td>
                                </tr>
                                <tr>
                                    <td className="fill-yellow">24</td>
                                    <td className="fill-yellow">25</td>
                                    <td className="fill-yellow">26</td>
                                    <td className="fill-yellow">27</td>
                                    <td className="fill">28</td>
                                    <td className="fill-green">29</td>
                                    <td className="fill-purple">30</td>
                                </tr>
                                <tr>
                                    <td className="rollover fill-green">1</td>
                                    <td className="rollover fill-green">2</td>
                                    <td className="rollover fill-green">3</td>
                                    <td className="rollover fill-green">4</td>
                                    <td className="rollover fill">5</td>
                                    <td className="rollover">6</td>
                                    <td className="rollover">7</td>
                                </tr>
                            </tbody>
                        </table>
                    </>):
                    (<>
                        <table className="day">
                            <tbody>
                                <tr>
                                    <td>Su</td>
                                    <td>Mo</td>
                                    <td>Tu</td>
                                    <td>We</td>
                                    <td>Th</td>
                                    <td>Fr</td>
                                    <td>Sa</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="dates">
                            <tbody>
                                <tr>
                                    <td className="fill-green">1</td>
                                    <td className="fill-green">2</td>
                                    <td className="fill-green">3</td>
                                    <td className="fill-green">4</td>
                                    <td className="fill">5</td>
                                    <td>6</td>
                                    <td>7</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>11</td>
                                    <td>12</td>
                                    <td>13</td>
                                    <td>14</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>16</td>
                                    <td>17</td>
                                    <td>18</td>
                                    <td>19</td>
                                    <td>20</td>
                                    <td>21</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>23</td>
                                    <td>24</td>
                                    <td>25</td>
                                    <td>26</td>
                                    <td>27</td>
                                    <td>28</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>30</td>
                                    <td>31</td>
                                    <td className="rollover">1</td>
                                    <td className="rollover">2</td>
                                    <td className="rollover">3</td>
                                    <td className="rollover">4</td>
                                </tr>
                                <tr>
                                    <td className="rollover">5</td>
                                    <td className="rollover">6</td>
                                    <td className="rollover">7</td>
                                    <td className="rollover">8</td>
                                    <td className="rollover">9</td>
                                    <td className="rollover">10</td>
                                    <td className="rollover">11</td>
                                </tr>
                            </tbody>
                        </table>
                    </>)
                }
            </div>
        </>
    )
}