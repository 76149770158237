import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import SAT from "./Components/SAT"
import ACT from "./Components/ACT"
import DigitalSAT from './Components/DigitalSAT'
import './StyleComponents/Dashboard.css'
import DashboardParentNew from './Components/DashboardParentNew'

export default function ParentDashboard(){ //todo: create dashboard
    const [isLoading, setIsLoading] = useState(true)
    const [parent, setParent] = useState('')
    const [student, setStudent] = useState('')
    const [testList, setTestList] = useState('')
    const [testType, setTestType] = useState('')
    const navigate = useNavigate()
    // eslint-disable-next-line
    useEffect(()=>{getParent()}, [])

    async function getParent(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers: {
                    "JWT":("Bearer "+localStorage.getItem('user'))
                }
            });
            if (response.data.user.role !== 2){
                alert("Please log in.")
                navigate("/Login");
                return;
            }
            setParent(response.data.user)
            setIsLoading(false)
        } catch(err){
            alert('Please login');
            navigate("/Login");
        }
        if (parent === null){
            navigate("/Login");
        }
    }

    const changeStudent =(e) => {
        const studentList = parent.students
        const name = e.target.value.split(" ")
        for (var i = 0; i < studentList.length; i++){
            const curr = studentList[i]
            if (curr.first_name === name[0] && curr.last_name === name[1]){
                setStudent(curr)
                if (testType === "SAT") setTestList(curr.SAT)
                else if (testType === "ACT") setTestList(curr.ACT)
                else if (testType === "Digital SAT") setTestList(curr.Digital_SAT)
                else if (testType === "Homework") setTestList(curr.homework)
                else setTestList([])
                return;
            }
        }
    }

    const changeTest = (e) =>{
        if (e.target.value === "SAT"){
            setTestList(student.SAT);
            setTestType("SAT");
        } else if (e.target.value === "ACT"){
            setTestList(student.ACT);
            setTestType("ACT");
        } else if (e.target.value === "Digital SAT"){
            setTestList(student.Digital_SAT)
            setTestType("Digital SAT")
        } else if (e.target.value === "PSAT"){
            setTestList(student.PSAT)
            setTestType("PSAT")
        } else if (e.target.value === "Homework"){
            setTestList(student.homework)
            setTestType("Homework")
        }
    }
    
    const getTestType = () => {
        if (testType === "SAT"){
            if (student !== ''){
                testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
                return <SAT testList={testList}/>
            }
        }
        else if (testType === "ACT"){
            if (student !== ''){
                testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
                return <ACT testList={testList}/>
            }
        } 
        else if (testType === "Digital SAT"){
            if (student !== ''){
                testList.sort(function(a,b){return a.date_as_int - b.date_as_int})
                return <DigitalSAT testList={testList}/>
            }
        }
        else if (testType === "PSAT"){
            if (student !== ''){
                testList.sort(function(a,b){return a.date_as_int-b.date_as_int})
                return <DigitalSAT testList={testList} isPSAT={true}/>
            }
        }
        else if (testType === "Homework"){
            if (student !== ''){
                testList.sort(function(a, b) {
                    const dateA = new Date(a.due_date);
                    const dateB = new Date(b.due_date);
                    if (dateA < dateB) {
                      return 1; 
                    }
                    if (dateA > dateB) {
                      return -1; 
                    }
                    return 0;
                })
                return <DisplayHomework homeworkList={testList} student={student}/>
            }
        }
    }

    if (isLoading) return(<h2 className="loading">Checking Credentials...</h2>)
    else{
        return (    
            <>
                {/* <h1>Welcome</h1>
                <div className="dashboard">
                    <div className="test-dropdown">
                        Select your student:
                        <select className="pick-test" onChange={changeStudent}>
                            <option></option>
                            {parent.students.map((student, i) => {
                                return <option key={student.email}>{student.first_name + " " + student.last_name}</option>
                            })}
                        </select>
                    </div>
                    <div className="test-dropdown">
                        Pick a test type to view: 
                        <select className="pick-test" onChange={changeTest}>
                            <option>---</option>
                            <option>Digital SAT</option>
                            <option>SAT</option>
                            <option>PSAT</option>
                            <option>ACT</option>
                            <option>Homework</option>
                        </select>
                    </div>
                    <div>
                        {getTestType()}
                    </div>
                </div> */}
                <DashboardParentNew parent={parent}/>
            </>
        )
    }
}

function DisplayHomework(props){
    const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= 600)
    const homeworkList = props.homeworkList
    const student = props.student
    const studentKey = `${student.first_name} ${student.last_name},${student.email}`

    const handleResize = () => {
        setIsSmallWidth(window.innerWidth <= 600)
    }
    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    },[])

    function getHomeworkGrade(hw){
        const studentAnswers = hw.student_answers
        if (studentAnswers[studentKey]){
            var correct = 0
            const questions = hw.questions.map(q=>{return q.answer})
            const answers = studentAnswers[studentKey].split("~")
            for (var i = 0; i < questions.length; i++){
                if (questions[i] === answers[i]) correct++
            }
            var grade = correct/questions.length * 100
            grade = Math.round(grade)
            var gradeColor = "green"
            if (grade < 90) gradeColor = "orangered"
            if (grade < 60) gradeColor = "red"
            return(
                <span style={{color:gradeColor}}>{grade+"%"}</span>
            )
        }
        return(
            <span>Incomplete</span>
        )
    }

    return(
        <>
            <table className="student-homework-table">
                <tbody>
                    <tr style={{fontWeight:"bold"}}>
                        <td>Homework Name &nbsp;</td>
                        <td>Due Date</td>
                        <td>Status</td>
                        <td>Grade</td>
                    </tr>
                    {homeworkList.map(hw=>{
                        return <tr>
                            <td>{isSmallWidth ? `${hw.name.substring(0,9)}...` : hw.name}</td>
                            <td>{hw.due_date}</td>
                            <td><img src={hw.student_answers[studentKey] ? "green-check.svg":"red-x.svg"} alt="icon" className="student-homework-icon"/></td>
                            <td>{getHomeworkGrade(hw)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    )
}