import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';

export default function StudentSAT(){
    const errRef = useRef();

    const [testId, setTestId] = useState('');
    const [date, setDate] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [readingAnswers, setReadingAnswers] = useState('');
    const [writingAnswers, setWritingAnswers] = useState('');
    const [noCalcAnswers, setNoCalcAnswers] = useState('');
    const [calcAnswers, setCalcAnswers] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(()=>{
        setErrMsg('')
    }, [testId, date, studentEmail, readingAnswers, writingAnswers, noCalcAnswers, calcAnswers])



    const submitForm = async (e) => {
        e.preventDefault();

        var dayMonthYear = date.split("/");
        if (dayMonthYear.length !== 3 || parseInt(dayMonthYear[1]) > 31 ||
        parseInt(dayMonthYear[1]) < 1 || parseInt(dayMonthYear[0]) > 12 ||
        parseInt(dayMonthYear[0]) < 1 || dayMonthYear[2].length%2 !== 0){
            setErrMsg('Date needs to be mm/dd/yyyy form');
            return;
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/testSAT',{
                "test_id": testId,
                "date": date,
                "student_email": studentEmail,
                "reading_answers": readingAnswers,
                "writing_answers": writingAnswers,
                "math_nocalc_answers": noCalcAnswers,
                "math_calc_answers": calcAnswers,
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            console.log(response.data);
            alert("Successfully updated")
        } catch(err){
            if (!err?.response) {
                setErrMsg('No Server Response');
                return;
            } else if (err.response?.status === 0){
                setErrMsg('No server response. Please try again later.');
                return;
            } else if (err.response.status === 500){
                setErrMsg('Student not found');
                return;
            }
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color: 'darkred'}}>Test ID must be in the form Month Year (Example: "May US 2022" or just "May 2022" if there is no international version)</p>
            <p style={{color: 'darkred'}}>Answers must be separated by a comma (Example: "A,B,D,D,D,B,C,A,A,C,21,1/2")</p>
            <p style={{color: 'darkred'}}>If answer is blank, put "/" (Example: "A,B,C,/,D,D,B,/,12,0.6)</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Student Email: <input className="admin-input" onChange={e=>setStudentEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Test ID (month year): <input className="admin-input" onChange={e=>setTestId(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Date Test Taken (mm/dd/yyyy): <input className="admin-input" onChange={e=>setDate(e.target.value)}/> 
                    </li>
                    <li className="admin-list">
                        Reading Answers: <input className="admin-input" onChange={e=>setReadingAnswers(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Writing Answers: <input className="admin-input" onChange={e=>setWritingAnswers(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        No Calc Answers: <input className="admin-input" onChange={e=>setNoCalcAnswers(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Calc Answers: <input className="admin-input" onChange={e=>setCalcAnswers(e.target.value)}/>
                    </li>
                </ul>
                <button type="submit">Submit SAT Test Answers</button>
            </form>
        </>
    );
}