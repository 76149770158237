import React, { useState, useEffect } from 'react';

function LoadingIndicator() {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(dots => dots.length < 3 ? dots + '.' : '');
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div style={{fontWeight:"bold",fontSize:"1.5rem", color:"darkgreen"}} className="loading-container">
      <p>Awaiting response{dots}</p>
    </div>
  );
}

export default LoadingIndicator;
