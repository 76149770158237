import React, { useState, useEffect } from 'react';

export default function TypingAnimation({ text }) {
  const [visibleText, setVisibleText] = useState('');
  
  useEffect(() => {
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      setVisibleText(text.slice(0, currentIndex + 1));
      currentIndex++;

      if (currentIndex >= text.length) {
        clearInterval(typingInterval);
      }
    }, Math.random() * 20); // Vary the interval between 50ms and 100ms

    return () => {
      clearInterval(typingInterval);
    };
  }, [text]);

  return (
    <div>{visibleText}</div>
  );
}
