import React, {useState} from 'react'
import axios from 'axios'
import ReviewDigitalSAT from './Components/ReviewDigitalSAT'

export default function Review(){
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [test,setTest] = useState(null)

    async function login(e){
        e.preventDefault()
        console.log("lmao")
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/login',{
                email: email,
                password: password
            })
            try{
                const response2 = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                    headers:{
                        "JWT": `Bearer ${response.data}`
                    }
                })
                console.log(response2.data.user)
                console.log(response2.data.user.Queued_Digital_SAT_Review)
                setTest(response2.data.user.Queued_Digital_SAT_Review)
                setIsLoggedIn(true)
            }catch(err){
                console.log(err)
                alert("Something went wrong. Try again later.")
            }
            // setIsLoggedIn(true)
            console.log(response)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    return(
        <>
            {isLoggedIn ? 
                <>
                    <ReviewDigitalSAT test={test}/>
                </> :
                <>
                    <h2>Login for Test Review:</h2>
                    <form id="login-form" onSubmit={login}>
                        <label htmlFor="email">Email: </label>
                        <input id="email" onChange={(e)=>setEmail(e.target.value)}/>
                        <br/>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" onChange={(e)=>setPassword(e.target.value)}/>
                        <button type="submit">Login</button>
                    </form> 
                </>
            }
        </>
    )
}