import React, {useState,useEffect,useRef} from 'react'
import SATScoreDisplay from './SATScoreDisplay'
import '../StyleComponents/SATAdvanced.css'
import {ResponsiveLine} from '@nivo/line'

export default function PSATAdvanced({student}){
    const [testList,setTestList] = useState([])
    const [chronologicalTestList, setChronologicalTestList] = useState([])
    const [currTest, setCurrTest] = useState(null)
    const [ascDec, setAscDec] = useState(true)
    const [currSort, setCurrSort] = useState("Date")
    const [displayAmount, setDisplayAmount] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const categories = ["Vocab (FI)", "Vocab (Context)", "Main Idea", "Reasoning", "Objective", "Data", "Segment", "Summary", "In-Text", "Format", "Evidence", "Comparative Texts", "Grammar (Transition)", "Grammar (Punctuation)", "Grammar (General)", "Total"]
    const textTypes = ["Humanities", "Social Science", "Science", "Literature", "Poetry", "General", "Total"]
    const mathCategories = ["Algebra", "Geometry", "Modeling", "Advanced Topics", "Statistics", "Data", "Total"]
    
    useEffect(()=>{
        if (student && student.PSAT){
          setTestList([...student.PSAT].sort((a,b)=>{return b.date_as_int - a.date_as_int}))
          setChronologicalTestList([...student.PSAT].sort((a,b)=>{return a.date_as_int - b.date_as_int}))
        }
        setDisplayAmount(5)
        window.addEventListener('resize',handleResize)
        return ()=>window.removeEventListener('resize',handleResize)
    },[student])
    const handleResize = () => {setScreenWidth(window.innerWidth)}

    function sortByDate(){
        var bool = ascDec
        if (currSort === "Date"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? (b.date_as_int-a.date_as_int) : (a.date_as_int-b.date_as_int)})
        setTestList(newList)
        setCurrSort("Date")
    }
    function sortByEnglish(){
        var bool = ascDec
        if (currSort === "English"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (a.english_scaled===b.english_scaled ? (b.date_as_int-a.date_as_int) : (b.english_scaled-a.english_scaled)) 
            : 
            (a.english_scaled===b.english_scaled ? (b.date_as_int-a.date_as_int) : (a.english_scaled-b.english_scaled))})
        setTestList(newList)
        setCurrSort("English")
    }
    function sortByMath(){
        var bool = ascDec
        if (currSort === "Math"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (a.math_scaled===b.math_scaled ? (b.date_as_int-a.date_as_int) : (b.math_scaled-a.math_scaled)) 
            : 
            (a.math_scaled===b.math_scaled ? (b.date_as_int-a.date_as_int) : (a.math_scaled-b.math_scaled))})
        setTestList(newList)
        setCurrSort("Math")
    }
    function sortByTotal(){
        function getTotal(test){
            return test.english_scaled+test.math_scaled
        }
        var bool = ascDec
        if (currSort === "Total"){
            setAscDec((prev)=>!prev)
            bool = !bool
        } else{
            setAscDec(true)
            bool = true
        }
        const newList = [...testList]
        newList.sort((a,b)=>{return bool ? 
            (getTotal(a)===getTotal(b) ? (b.date_as_int-a.date_as_int) : (getTotal(b)-getTotal(a))) 
            : 
            (getTotal(a)===getTotal(b) ? (b.date_as_int-a.date_as_int) : (getTotal(a)-getTotal(b)))})
        setTestList(newList)
        setCurrSort("Total")
    }
    function selectTest(test){
        setCurrTest(test)
    }
    function getYY(date){
        const parts = date.split("/")
        return `${parts[0]}/${parts[1]}/${parts[2].substring(2)}`
    }
    function getQType(i){return categories[i]}
    function getTType(i){return textTypes[i]}
    function getMathType(i){return mathCategories[i]}

    if (testList.length === 0){
        return (
            <>
                <h1>You don't have any tests to view.</h1>
            </>
        )
    }
    return(
        <>
            <div style={{width:"min(90rem,100%)"}}>
                <h1>PSAT</h1>
            </div>
            <div className="SAT-advanced-list-container">
                <table className="SAT-advanced-table">
                    <tbody>
                        <tr>
                            <td>
                                Date
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByDate()}>
                                    <path style={{opacity:(!ascDec && currSort==="Date" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Date" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                R/W
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByEnglish()}>
                                    <path style={{opacity:(!ascDec && currSort==="English" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="English" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "M" : "Math"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByMath()}>
                                    <path style={{opacity:(!ascDec && currSort==="Math" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Math" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                            <td>
                                {screenWidth < 500 ? "T" : "Total"}
                                <svg viewBox="0 -960 960 960" className="sort-icon" onClick={()=>sortByTotal()}>
                                    <path style={{opacity:(!ascDec && currSort==="Total" ? "0" : "1")}} d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80Z" fill="#000"/>
                                    <path style={{opacity:(ascDec && currSort==="Total" ? "0" : "1")}} d="M600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" fill="#000"/>
                                </svg>
                            </td>
                        </tr>
                        {testList.map((test,i)=>{
                            return <tr key={`test-${i}`}
                                className="SAT-advanced-test-selector"
                                onClick={()=>selectTest(test)}
                            >
                                <td style={{paddingRight:"0.25rem"}}>{screenWidth < 600 ? (getYY(test.date)) : test.date}</td>
                                <td>{test.english_scaled}</td>
                                <td>{test.math_scaled}</td>
                                <td>{test.english_scaled+test.math_scaled}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div style={{textAlign:"left",color:"darkred",fontSize:"0.75rem"}}>**Click on a test to see details</div>
            </div>
            {currTest ? 
                <div>
                    <div className="SAT-advanced-currtest-container" style={{padding:(screenWidth < 600 ? "0 0.5rem 0 0.5rem" : "")}}>
                        <SATScoreDisplay student={student} test={currTest} testType={"PSAT"} displayingMostRecent={false}/>
                        {screenWidth < 1000 ? <div style={{display:"flex"}}>
                            <div className="SAT-advanced-answer-vertical">
                                <span>R/W Mod 1</span>
                                <table>
                                    <tbody>
                                        {currTest.mod1_answers.split(",").map((ans,i)=>{
                                            return <tr key={`read-${i}`}>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                >{i+1}</td>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                    style={{background:(currTest.mod1_graded[i] ? "" : "red")}}
                                                >{ans}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="SAT-advanced-answer-vertical">
                                <span>R/W Mod 2</span>
                                <table>
                                    <tbody>
                                        {currTest.mod2_answers.split(",").map((ans,i)=>{
                                            return <tr key={`write-${i}`}>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                >{i+1}</td>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                    style={{background:(currTest.mod2_graded[i] ? "" : "red")}}
                                                >{ans}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="SAT-advanced-answer-vertical">
                                <span>Math Mod 1</span>
                                <table>
                                    <tbody>
                                        {currTest.mod3_answers.split(",").map((ans,i)=>{
                                            return <tr key={`nocalc-${i}`}>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                >{i+1}</td>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                    style={{background:(currTest.mod3_graded[i] ? "" : "red")}}
                                                >{ans}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="SAT-advanced-answer-vertical">
                                <span>Math Mod 2</span>
                                <table>
                                    <tbody>
                                        {currTest.mod4_answers.split(",").map((ans,i)=>{
                                            return <tr key={`calc-${i}`}>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                >{i+1}</td>
                                                <td 
                                                    className={i%2===0 ? "answers-vertical even" : "answers-vertical odd"}
                                                    style={{background:(currTest.mod4_graded[i] ? "" : "red")}}
                                                >{ans}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>:
                        <div>
                            <span>R/W Mod 1</span>
                            <div className="DigitalSAT-advanced-student-answers">
                                <table>
                                    <tbody>
                                        <tr>
                                            {currTest.mod1_answers.split(",").map((_,i)=>{
                                                return <td key={`read-${i}`} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                            })}
                                        </tr>
                                        <tr>
                                            {currTest.mod1_answers.split(",").map((ans,i)=>{
                                                return <td key={`read-stu-${i}`} style={{background:(currTest.mod1_graded[i] ? "" : "red")}} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span>R/W Mod 2</span>
                            <div className="DigitalSAT-advanced-student-answers">
                                <table>
                                    <tbody>
                                        <tr>
                                            {currTest.mod2_answers.split(",").map((_,i)=>{
                                                return <td key={`write-${i}`} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                            })}
                                        </tr>
                                        <tr>
                                            {currTest.mod2_answers.split(",").map((ans,i)=>{
                                                return <td key={`write-stu-${i}`} style={{background:(currTest.mod2_graded[i] ? "" : "red")}} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span>Math Mod 1</span>
                            <div className="DigitalSAT-advanced-student-answers">
                                <table>
                                    <tbody>
                                        <tr>
                                            {currTest.mod3_answers.split(",").map((_,i)=>{
                                                return <td key={`nocalc-${i}`} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                            })}
                                        </tr>
                                        <tr>
                                            {currTest.mod3_answers.split(",").map((ans,i)=>{
                                                return <td key={`nocalc-stu-${i}`} style={{background:(currTest.mod3_graded[i] ? "" : "red")}} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span>Math Mod 2</span>
                            <div className="DigitalSAT-advanced-student-answers">
                                <table>
                                    <tbody>
                                        <tr>
                                            {currTest.mod4_answers.split(",").map((_,i)=>{
                                                return <td key={`calc-${i}`} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{i+1}</td>
                                            })}
                                        </tr>
                                        <tr>
                                            {currTest.mod4_answers.split(",").map((ans,i)=>{
                                                return <td key={`calc-stu-${i}`} style={{background:(currTest.mod4_graded[i] ? "" : "red")}} className={`DigitalSAT-advanced-td${i%2===0 ? " even" : " odd"}`}>{ans}</td>
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>
                    <div className="breakdown-container">
                        {currTest ? 
                            <div style={{display:"flex",flexWrap:"wrap"}}>
                                <div style={{margin:"0.5rem 1rem 1rem 1rem"}}>
                                    <div style={{textAlign:"left",fontSize:(screenWidth < 400 ? "1rem" : "1.25rem"),fontWeight:"bold",marginBottom:"0.2rem"}}>
                                        <span>RW Breakdown</span>
                                    </div>
                                    <table className="q-breakdown-table">
                                        <tbody>
                                            <tr style={{fontWeight:"bold", textAlign:"left", borderBottom:"2px solid black"}}>
                                                <td className="q-breakdown-td" style={{borderRight:"2px black solid"}}>Question Type</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px black solid"}}>Easy</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px black solid"}}>Medium</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px black solid"}}>Hard</td>
                                                <td className="q-breakdown-td">Total</td>
                                            </tr>
                                            {currTest.RW_breakdown.split("-").map((cat,i)=>{
                                                const parts = cat.split(",")
                                                return <tr key={`qtype-${i}`} style={{borderTop:"1px solid black"}}>
                                                    <td className="q-breakdown-td" style={{textAlign:"left",borderRight:"2px black solid",fontWeight:(i===15 ? "bold" : "")}}>{getQType(i)}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px black solid",fontWeight:(i===15 ? "bold" : "")}}>{parts[0]}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px black solid",fontWeight:(i===15 ? "bold" : "")}}>{parts[1]}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px black solid",fontWeight:(i===15 ? "bold" : "")}}>{parts[2]}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===15 ? "bold" : "")}}>{parts[3]}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{margin:"0.5rem 1rem 1rem 1rem"}}>
                                    <div style={{textAlign:"left",fontSize:(screenWidth < 400 ? "1rem" : "1.25rem"),fontWeight:"bold",marginBottom:"0.2rem"}}>
                                        <span>RW Breakdown</span>
                                    </div>
                                    <table className="q-breakdown-table">
                                        <tbody>
                                            <tr style={{fontWeight:"bold", textAlign:"left", borderBottom:"2px black solid"}}>
                                                <td className="q-breakdown-td" style={{borderRight:"2px solid black"}}>Text Type</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px solid black"}}>Easy</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px solid black"}}>Medium</td>
                                                <td className="q-breakdown-td" style={{borderRight:"1px solid black"}}>Hard</td>
                                                <td className="q-breakdown-td">Total</td>
                                            </tr>
                                            {currTest.text_type_breakdown.split("-").map((cat,i)=>{
                                                const parts = cat.split(",")
                                                return <tr key={`qtype-${i}`} style={{borderTop:"1px solid black"}}>
                                                    <td className="q-breakdown-td" style={{textAlign:"left",borderRight:"2px black solid",fontWeight:(i===6 ? "bold" : "")}}>{getTType(i)}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px solid black",fontWeight:(i===6 ? "bold" : "")}}>{parts[0]}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px solid black",fontWeight:(i===6 ? "bold" : "")}}>{parts[1]}</td>
                                                    <td className="q-breakdown-td" style={{borderRight:"1px solid black",fontWeight:(i===6 ? "bold" : "")}}>{parts[2]}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===6 ? "bold" : "")}}>{parts[3]}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{margin:"0.5rem 1rem 1rem 1rem"}}>
                                    <div style={{textAlign:"left",fontSize:(screenWidth < 400 ? "1rem" : "1.25rem"),fontWeight:"bold",marginBottom:"0.2rem"}}>
                                        <span>Math Breakdown</span>
                                    </div>
                                    <table className="q-breakdown-table">
                                        <tbody>
                                            <tr style={{fontWeight:"bold", textAlign:"left", borderBottom:"2px black solid"}}>
                                                <td className="q-breakdown-td" style={{borderRight:"2px black solid"}}>Question Type</td>
                                                <td className="q-breakdown-td">Easy</td>
                                                <td className="q-breakdown-td" style={{borderLeft:"1px black solid"}}>Medium</td>
                                                <td className="q-breakdown-td" style={{borderLeft:"1px black solid"}}>Hard</td>
                                                <td className="q-breakdown-td" style={{borderLeft:"1px black solid"}}>Total</td>
                                            </tr>
                                            {currTest.math_breakdown.split("-").map((cat,i)=>{
                                                const parts = cat.split(",")
                                                return <tr key={`qtype-${i}`} style={{borderTop:"1px solid black"}}>
                                                    <td className="q-breakdown-td" style={{textAlign:"left",borderRight:"2px solid black",fontWeight:(i===6 ? "bold" : "")}}>{getMathType(i)}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===6 ? "bold" : "")}}>{parts[0]}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===6 ? "bold" : ""),borderLeft:"1px solid black"}}>{parts[1]}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===6 ? "bold" : ""),borderLeft:"1px solid black"}}>{parts[2]}</td>
                                                    <td className="q-breakdown-td" style={{fontWeight:(i===6 ? "bold" : ""),borderLeft:"1px solid black"}}>{parts[3]}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>                                
                            </div> : <></>
                        }
                    </div>
                </div> : 
                <></>
            }
            <div>
                <NivoLine testList={chronologicalTestList} displayAmount={displayAmount} screenWidth={screenWidth}/>
                <div style={{padding:"0 1rem 1rem 1rem",textAlign:"left"}}>
                    <label htmlFor="select-test-number">Displaying </label>
                    <select id="select-test-number" onChange={(e)=>{setDisplayAmount(parseInt(e.target.value))}} value={displayAmount}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={100}>All</option>
                    </select>
                    <label htmlFor="select-test-number">&nbsp;&nbsp;Tests</label>
                </div>
            </div>
        </>
    )
}

function NivoLine({testList, displayAmount, screenWidth}){
  const customColors = ['#ff595e', '#12d078', '#037cfe']
  const graphRef = useRef(null)
  function parseDate(date){
    const parts = date.split("/")
    return `${parts[0]}/${parts[1]}`
  }

  const slicedList = testList.slice(Math.max(0,testList.length - displayAmount))
  var englishData = {"id":"English","data":[]}
  var mathData = {"id":"Math","data":[]}
  var totalData = {"id":"Total","data":[]}
  var testMap = {}
  for (var i = 0; i < slicedList.length; i++){
    const currTest = slicedList[i]
    testMap[currTest.date] = testMap[currTest.date] ?? []
    testMap[currTest.date].push([currTest.english_scaled,currTest.math_scaled,currTest.english_scaled+currTest.math_scaled])
  }
  Object.keys(testMap).forEach((key)=>{
    const val = testMap[key]
    if (val){
      var maxEnglish = val[0][0]
      var maxMath = val[0][1]
      var maxTotal = val[0][2]
      if (val.length > 1){
        for (var i = 1; i < val.length; i++){
          maxEnglish = Math.max(maxEnglish,val[i][0])
          maxMath = Math.max(maxMath,val[i][1])
          maxTotal = Math.max(maxTotal,val[i][2])
        }
      }
      englishData.data.push({
        "x":parseDate(key),
        "y":maxEnglish,
        "full_date":key
      })
      mathData.data.push({
        "x":parseDate(key),
        "y":maxMath,
        "full_date":key
      })
      totalData.data.push({
        "x":parseDate(key),
        "y":maxTotal,
        "full_date":key
      })
    }
  })

  const customToolTip = ({point}) => {
    const date = point.data.full_date
    var styles = {}
    if (graphRef && screenWidth < 600){
        if (point.x < graphRef.current.getBoundingClientRect().width/3){
            styles = {
                position:"absolute",
                transform:"translateY(-50%)",
                left:"20px"
            }
        } else {
            styles = {
                position:"absolute",
                transform:"translateY(-50%)",
                right:"20px"
            }
        }
    }

    return(
      <div className="custom-tool-tip" style={styles}>
        <div>
          <strong>Date: </strong> {date}
        </div>
        {testMap[date].length > 1 ?
          <div>
            {testMap[date].map((test,i)=>{
              return <div key={`ttt-${date}-${i}`}>
                <div><strong>Test {i+1}</strong></div>
                <div><strong>R/W: </strong>{test[0]}</div>
                <div><strong>Math: </strong>{test[1]}</div>
                <div><strong>Total: </strong>{test[0]+test[1]}</div>
              </div>
            })}
          </div> :
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{display:"flex"}}><div style={{background:customColors[2],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Total:</strong></td>
                  <td>{testMap[date][0][2]}</td>
                </tr>
                <tr>
                <td style={{display:"flex",paddingRight:"0.5rem"}}><div style={{background:customColors[0],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>R/W:</strong></td>
                  <td>{testMap[date][0][0]}</td>
                </tr>
                <tr>
                <td style={{display:"flex"}}><div style={{background:customColors[1],width:"1rem",height:"1rem",borderRadius:"1rem",marginRight:"0.25rem"}}/><strong>Math:</strong></td>
                  <td>{testMap[date][0][1]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
  
    return(
        <>
            <div className="SAT-Nivoline" ref={graphRef}>
                <ResponsiveLine
                    data={[englishData,mathData,totalData]}
                    tooltip={customToolTip}
                    margin={{ top: 50, right: (screenWidth < 600 ? 20 : 110), bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 1600,
                        stacked: false,
                        reverse: false
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Date',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Score',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={customColors}
                    curve="monotoneX"
                    lineWidth={3}
                    pointSize={7}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={7}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: (screenWidth < 600 ? 'top-left' : 'right'),
                            direction: (screenWidth < 600 ? 'row' : 'column'),
                            justify: false,
                            translateX: (screenWidth < 600 ? 0 : 100),
                            translateY: (screenWidth < 600 ? -30 : 0),
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: (screenWidth < 600 ? 60 : 80),
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        </>
    )
}