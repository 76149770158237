import React, {useRef, useState, useEffect} from 'react'
import axios from 'axios'
import "./GetAllStudents.css"
import SATAnswerTable from '../Components/SATAnswerTable'
import SATTestBreakdown from '../Components/SATTestBreakdown'
import DigitalSATHistory from '../Components/DigitalSATHistory'
import DigitalSATAnswerTable from '../Components/DigitalSATAnswerTable'
import ACT from '../Components/ACT'

export default function GetAllStudent(){
    const errRef = useRef()

    const [errMsg, setErrMsg] = useState('')
    const [students, setStudents] = useState([])
    const [currStudent, setCurrStudent] = useState('')
    const [SATTestList, setSATTestList] = useState([])
    const [ACTTestList, setACTTestList] = useState([])
    const [digitalSATTestList, setDigitalSATTestList] = useState([])
    const [digitalPSATTestList, setDigitalPSATTestList] = useState([])
    const [currTest, setCurrTest] = useState('')
    const [SATACT, setSATACT] = useState('')
    const [search, setSearch] = useState('')

    useEffect(()=>{
        setErrMsg('')
    }, [])

    const getStudents = async (e) => {
        e.preventDefault()
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/allStudents', {
                headers: {"JWT":("Bearer "+localStorage.getItem('user'))}
            })
            console.log(response)
            console.log(response.data)
            response.data.sort((a,b) => (a.first_name+a.last_name).toString().localeCompare((b.first_name+b.last_name).toString()))
            setStudents(response.data)
            setErrMsg('')
            console.log(response.data)
        } catch(err){
            console.log(err)
            console.log("error")
            setErrMsg('No server response. Please try again later.')
        }
    }

    const clickStudent = (studentEmail) => {
        for (var i = 0; i < students.length; i++){
            if (students[i].email === studentEmail){
                setCurrStudent(students[i])
                setSATTestList(students[i].SAT.sort(function(a,b){return b.date_as_int - a.date_as_int}))
                setACTTestList(students[i].ACT)
                setDigitalSATTestList(students[i].Digital_SAT.sort(function(a,b){return b.date_as_int - a.date_as_int}))
                setDigitalPSATTestList(students[i].PSAT.sort(function(a,b){return b.date_as_int - a.date_as_int}))
                setCurrTest('')
                return
            }
        }
    }

    const clickDigitalSATTest = (id, date) => {
        for (var i = 0; i < digitalSATTestList.length; i++){
            if (id === digitalSATTestList[i].test_id && date === digitalSATTestList[i].date){
                setCurrTest(digitalSATTestList[i])
                setSATACT("DigitalSAT")
                return
            }
        }
        setCurrTest('')
        setSATACT('')
    }

    const clickSATTest = (id, date) => {
        for (var i = 0; i < SATTestList.length; i++){
            if (id === SATTestList[i].test_id && date === SATTestList[i].date){
                setCurrTest(SATTestList[i])
                setSATACT("SAT")
                return
            }
        }
        setCurrTest('')
        setSATACT('')
    }

    const clickPSATTest = (id, date) => {
        for (var i = 0; i < digitalPSATTestList.length; i++){
            if (id === digitalPSATTestList[i].test_id && date === digitalPSATTestList[i].date){
                setCurrTest(digitalPSATTestList[i])
                setSATACT("PSAT")
                return
            }
        }
        setCurrTest('')
        setSATACT('')
    }

    const clickACTTest = (id, date) => {
        for (var i = 0; i < ACTTestList.length; i++){
            if (id === ACTTestList[i].test_id && date === ACTTestList[i].date){
                setCurrTest(ACTTestList[i])
                setSATACT("ACT")
                return
            }
        }
        setCurrTest('')
        setSATACT('')
    }

    function DisplayTest(props){
        const test = props.test
        if (props.test === undefined){return <></>}
        if (SATACT === "SAT"){
            return <StudentSATTest test={test}/>
        } else if (SATACT === "ACT"){
            return <></>
        } else if (SATACT === "DigitalSAT"){
            return <DigitalSATAnswerTable test={test}/>
        } else if (SATACT === "PSAT"){
            return <DigitalSATAnswerTable test={test} isPSAT={true}/>
        }
        return <></>
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color: 'darkred'}}>Use this query to view student tests</p>
            <button onClick={getStudents}>Get All Students</button>
            <div className="get-students-wrapper">
                <div className="get-students-container">
                    <label className="filter-label">Search: </label>
                    <input className="filter-bar" onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
                    <ul className="student-ul">
                        {students.filter(
                            (student) => {
                                return search === '' ? 
                                    student :
                                    (student.first_name + " " + student.last_name + " " + student.email).toLowerCase().includes(search)
                        }).map((student, i) => {
                            return <li key={student.email} value={student.email} onClick={()=>clickStudent(student.email)}>{student.first_name+" "+student.last_name+" ("+student.email+")"}</li>;
                        })}
                    </ul>
                </div>
                <div className="get-students-container">
                    <p>Digital SAT</p>
                    <ul className="student-ul">
                        {digitalSATTestList.map((test,i)=>{
                            return <li key={"dsat"+currStudent.email+i} value={test.test_id} onClick={()=>clickDigitalSATTest(test.test_id, test.date)}>{"("+test.date+") "+test.test_id}</li>
                        })}
                    </ul>
                    <p>SAT</p>
                    <ul className="student-ul">
                        {SATTestList.map((test, i) => {
                            return <li key={"sat"+currStudent.email+i} value={test.test_id} onClick={()=>clickSATTest(test.test_id, test.date)}>{'('+test.date+') '+test.test_id}</li>
                        })}
                    </ul>
                    <p>PSAT</p>
                    <ul className="student-ul">
                        {digitalPSATTestList.map((test,i)=>{
                            return <li key={"sat"+currStudent.email+i} value={test.test_id} onClick={()=>clickPSATTest(test.test_id, test.date)}>{'('+test.date+') '+test.test_id}</li>
                        })}
                    </ul>
                </div>
                <div className="get-students-container">
                    <p>ACT</p>
                    <ul className="student-ul">
                        {ACTTestList.map((test,i) => {
                            return <li key={"act"+currStudent.email+i} vlaue={test.test_id} onClick={()=>clickACTTest(test.test_id, test.date)}>{'('+test.date+') '+test.test_id}</li>
                        })}
                    </ul>
                </div>
            </div>
            <h2>{currStudent.first_name+" "+currStudent.last_name+" (Parent: "+currStudent.parent_email+") (Secret: "+currStudent.secret+")"}</h2>
            <DisplayTest student={currStudent} test={currTest}/>
            <SATTestHistory testList={SATTestList}/>
            <ACT testList={ACTTestList}/>
            <DigitalSATHistory testList={digitalSATTestList}/>
            {/* <DigitalSATHistory testList={digitalPSATTestList}/> */}
        </>
    );
}

function StudentSATTest(props){
    const currTest = props.test;
    console.log(props);
    console.log(props.test);

    var readingQNumbers = [];
    for (var i = 1; i <= 52; i++) readingQNumbers.push(i);

    var writingQNumbers = [];
    for (var j = 1; j <= 44; j++) writingQNumbers.push(j);

    var mathNoCalcQNumbers = [];
    for (var k = 1; k <= 20; k++) mathNoCalcQNumbers.push(k);

    var mathCalcQNumbers = [];
    for (var l = 1; l <= 38; l++) mathCalcQNumbers.push(l);

    if (currTest==="" || currTest === null){
        return(
            <>
            </>
        );
    } else{
        return(
            <>
                <h2>Date: {currTest.date} &nbsp;&nbsp;&nbsp;SAT Test ID: {currTest.test_id}</h2>
                <h2>Reading: {currTest.reading_scaled}/400 &nbsp;&nbsp;Writing: {currTest.writing_scaled}/400 &nbsp;&nbsp;Math: {currTest.math_scaled}/800</h2>
                <h2>Total: {currTest.reading_scaled+currTest.writing_scaled+currTest.math_scaled}/1600</h2>
                <div className="scroll-container">
                    <div>
                        <h3>Reading:</h3>
                        <table className="answer-key-table">
                            <tbody className="answer-key-table-body">
                                <tr className="answer-key-table-row">
                                    <td>Question</td>
                                    {readingQNumbers.map((i)=>{
                                        return <td key={i} value={i} className="answer-key-table-element">{i}</td>
                                    })}
                                </tr>
                                <tr className="answer-key-table-row">
                                    <td>Your Answer</td>
                                    {currTest.reading_answers.split(",").map((answer, i) => {
                                        return <td key={i} value={answer} className="answer-key-table-element" style={currTest.reading_graded === null ? {}:{backgroundColor: currTest.reading_graded[i] ? null:'red'}}>{answer}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h3>Writing: </h3>
                        <table className="answer-key-table">
                            <tbody className="answer-key-table-body">
                                <tr className="answer-key-table-row">
                                    <td>Question</td>
                                    {writingQNumbers.map((i)=>{
                                        return <td key={i} value={i} className="answer-key-table-element">{i}</td>
                                    })}
                                </tr>
                                <tr className="answer-key-table-row">
                                    <td>Your Answer</td>
                                    {currTest.writing_answers.split(",").map((answer, i) => {
                                        return <td key={i} value={answer} className="answer-key-table-element" style={currTest.writing_graded === null ? {}:{backgroundColor: currTest.writing_graded[i] ? null:'red'}}>{answer}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h3>No Calc:</h3>
                        <table className="answer-key-table">
                            <tbody className="answer-key-table-body">
                                <tr className="answer-key-table-row">
                                    <td>Question</td>
                                    {mathNoCalcQNumbers.map((i)=>{
                                        return <td key={i} value={i} className="answer-key-table-element">{i}</td>
                                    })}
                                </tr>
                                <tr className="answer-key-table-row">
                                    <td>Your Answer</td>
                                    {currTest.math_nocalc_answers.split(",").map((answer, i) => {
                                        return <td key={i} value={answer} className="answer-key-table-element" style={currTest.math_nocalc_graded === null ? {}:{backgroundColor: currTest.math_nocalc_graded[i] ? null:'red'}}>{answer}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h3>Calc:</h3>
                        <table className="answer-key-table">
                            <tbody className="answer-key-table-body">
                                <tr className="answer-key-table-row">
                                    <td>Question</td>
                                    {mathCalcQNumbers.map((i)=>{
                                        return <td key={i} value={i} className="answer-key-table-element">{i}</td>
                                    })}
                                </tr>
                                <tr className="answer-key-table-row">
                                    <td>Your Answer</td>
                                    {currTest.math_calc_answers.split(",").map((answer, i) => {
                                        return <td key={i} value={answer} className="answer-key-table-element" style={currTest.math_calc_graded === null ? {}:{backgroundColor: currTest.math_calc_graded[i] ? null:'red'}}>{answer}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <SATQTypeBreakdown test={currTest}/>
            </>
        )
    }
}

function SATQTypeBreakdown(props){
    const currTest = props.test;
    console.log(currTest);

    if (currTest === ""){
        return (
            <>
            </>
        );
    } else{
        const getTypes = (types) => {
            if (types === null){
                return(
                    <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </>
                );
            }
            return(
                <>
                    {types.map((q,i)=>{
                        return <td key={i} value={q}>{q[0]+"/"+q[1]}</td>
                    })}
                </>
            );
        }
        const getMath = (type) => {
            if (type === null) return("");
            else return(type[0]+"/"+type[1]);
        }
        return(
                <>
                <div className="scroll-container">
                    <div className="rowtable">
                        <div className="question-type-container">
                            <h3>Reading Question Types:</h3>
                            <table>
                                <tbody>
                                    <tr style={{textAlign: 'center'}}>
                                        <td> </td>
                                        <td style={{fontWeight: 'bold'}}>Level 1&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Level 2&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Level 3&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Total&nbsp;</td>
                                    </tr>
                                    <tr style={{backgroundColor: 'orange'}}>
                                        <td>Main Idea&nbsp;</td>
                                        {getTypes(currTest.main_idea_reading)}
                                    </tr>
                                    <tr style={{backgroundColor:'gold'}}>
                                        <td>Purpose&nbsp;</td>
                                        {getTypes(currTest.purpose)}
                                    </tr>
                                    <tr style={{backgroundColor: 'lightgreen'}}>
                                        <td>Evidence&nbsp;</td>
                                        {getTypes(currTest.evidence)}
                                    </tr>
                                    <tr style={{backgroundColor: 'lightblue'}}>
                                        <td>Vocab&nbsp;</td>
                                        {getTypes(currTest.vocab)}
                                    </tr>
                                    <tr style={{backgroundColor: 'salmon'}}>
                                        <td>Detail&nbsp;</td>
                                        {getTypes(currTest.detail)}
                                    </tr>
                                    <tr style={{backgroundColor: 'mistyrose'}}>
                                        <td>Inference&nbsp;</td>
                                        {getTypes(currTest.inference)}
                                    </tr>
                                    <tr style={{backgroundColor: 'plum'}}>
                                        <td>Data&nbsp;</td>
                                        {getTypes(currTest.data_reading)}
                                    </tr>
                                    <tr style={{backgroundColor: 'lightgray'}}>
                                        <td>Total&nbsp;</td>
                                        {getTypes(currTest.reading_level)}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="question-type-container">
                            <h3>Writing Question Types:</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td style={{fontWeight: 'bold'}}>Level 1&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Level 2&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Level 3&nbsp;</td>
                                        <td style={{fontWeight: 'bold'}}>Total&nbsp;</td>
                                    </tr>
                                    <tr style={{backgroundColor: 'lightgreen'}}>
                                        <td>Main Idea&nbsp;</td>
                                        {getTypes(currTest.main_idea_writing)}
                                    </tr>
                                    <tr style={{backgroundColor: 'orange'}}>
                                        <td>Punctuation&nbsp;</td>
                                        {getTypes(currTest.punctuation)}
                                    </tr>
                                    <tr style={{backgroundColor: 'salmon'}}>
                                        <td>Transition&nbsp;</td>
                                        {getTypes(currTest.transition)}
                                    </tr>
                                    <tr style={{backgroundColor: 'mistyrose'}}>
                                        <td>Style&nbsp;</td>
                                        {getTypes(currTest.style)}
                                    </tr>
                                    <tr style={{backgroundColor: 'gold'}}>
                                        <td>Idiom/Diction&nbsp;</td>
                                        {getTypes(currTest.idiom_diction)}
                                    </tr>
                                    <tr style={{backgroundColor: 'plum'}}>
                                        <td>Data&nbsp;</td>
                                        {getTypes(currTest.data_writing)}
                                    </tr>
                                    <tr style={{backgroundColor: 'lightblue'}}>
                                        <td>Subject/Verb Agreement&nbsp;</td>
                                        {getTypes(currTest.subject_verb)}
                                    </tr>
                                    <tr style={{backgroundColor: 'lightgray'}}>
                                        <td>Total&nbsp;</td>
                                        {getTypes(currTest.writing_level)}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                        <div>
                            <h3>Math Question Types:</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor: 'orange'}}>Algebra</td>
                                        <td style={{backgroundColor: 'orange'}}>{getMath(currTest.algebra)}</td>
                                        <td style={{backgroundColor: 'lightgray'}}>No Calculator&nbsp;</td>
                                        <td style={{backgroundColor: 'lightgray'}}>{currTest.math_nocalc_raw+"/"+20}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: 'lightgreen'}}>Modeling</td>
                                        <td style={{backgroundColor: 'lightgreen'}}>{getMath(currTest.modeling)}</td>
                                        <td style={{backgroundColor: 'lightgray'}}>Calculator</td>
                                        <td style={{backgroundColor: 'lightgray'}}>{currTest.math_calc_raw+"/"+38}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: 'mistyrose'}}>Geometry</td>
                                        <td style={{backgroundColor: 'mistyrose'}}>{getMath(currTest.geometry)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: 'lightblue'}}>Advanced Topics&nbsp;</td>
                                        <td style={{backgroundColor: 'lightblue'}}>{getMath(currTest.advanced_topics)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: 'plum'}}>Statistics</td>
                                        <td style={{backgroundColor: 'plum'}}>{getMath(currTest.statistics)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: 'salmon'}}>Data</td>
                                        <td style={{backgroundColor: 'salmon'}}>{getMath(currTest.data_math)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
        );
    }
}

function SATTestHistory(props){
    const testList = props.testList;

    if (testList === "" || testList.length === 0){
        return (
            <>
                <h2>SAT Test History</h2>
                <h5>No SAT tests in database</h5>
            </>
        )
    }

    return(
        <>
            <h2>SAT Test History</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>Test ID</td>
                        <td>Reading</td>
                        <td>Writing</td>
                        <td>Math</td>
                        <td>Total</td>
                    </tr>
                    {testList.map((test) => {
                        return(
                            <>
                                <tr>
                                    <td>{test.date}</td>
                                    <td>{test.test_id}</td>
                                    <td>{test.reading_scaled === 100 ? "Incomplete" : test.reading_scaled}</td>
                                    <td>{test.writing_scaled === 100 ? "Incomplete" : test.writing_scaled}</td>
                                    <td>{test.math_scaled === 200 ? "Incomplete" : test.math_scaled}</td>
                                    <td style={{fontWeight: 'bold'}}>{test.reading_scaled + test.writing_scaled + test.math_scaled}</td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
            <p style={{color:'darkred'}}>If a section is incomplete, the score is defaulted to 200 for Math and 100 for Reading and Writing.</p>
        </>
    )
}