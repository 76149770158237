import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {useTimer} from 'react-timer-hook'
import './StyleComponents/DigitalTesting.css'

export default function DigitalTesting(){
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [test, setTest] = useState([[],[],[],[]])
    const [showInstructions, setShowInstructions] = useState(false)
    const [showFormulas, setShowFormulas] = useState(false)
    const [showFRQInstructions, setShowFRQInstructions] = useState(false)
    const [testId, setTestId] = useState('')
    const [qIndex, setQIndex] = useState(-1)
    const [studentAnswers, setStudentAnswers] = useState([])
    const [flaggedQuestions, setFlaggedQuestions] = useState([])
    const [mIndex, setMIndex] = useState(-1)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [hideStartButton, setHideStartButton] = useState(false)
    const [secret, setSecret] = useState('')
    const [countLeaves, setCountLeaves] = useState(0)
    const [isDrawing, setIsDrawing] = useState(false)
    const [englishTime, setEnglishTime] = useState(32*60)
    const [mathTime, setMathTime] = useState(35*60)
    const [breakTime, setBreakTime] = useState(5*60)
    // var englishTime = 32*60
    // var mathTime = 35*60
    // var breakTime = 5*60
    const navigate = useNavigate()
    const imageRef = useRef(null)
    const canvasRef = useRef(null)
    const contextRef = useRef(null)

    const login = async (e) => {
        e.preventDefault()
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/login', {
                email: email,
                password: password,
            })
            localStorage.setItem('user', response.data);
            setSecret("")
            setIsLoggedIn(true)
        } catch(err){
            console.log(err)
            alert("Login Failed")
        }
    }

    const changeCanvas = ()=>{
        // console.log(imageRef.current)
        if (imageRef.current !== null){
            const canvas = canvasRef.current
            canvas.width = imageRef.current.offsetWidth*2
            canvas.height = imageRef.current.offsetHeight*2
            canvas.style.width = imageRef.current.offsetWidth+"px"
            canvas.style.height = imageRef.current.offsetHeight+"px"

            const context = canvas.getContext("2d")
            context.scale(2,2)
            context.strokeStyle = "yellow"
            context.lineWidth = 15
            context.globalAlpha = 0.6
            context.globalCompositeOperation = "xor"
            contextRef.current = context
        }
    }

    const startDrawing = ({nativeEvent}) => {
        contextRef.current.beginPath()
        if (nativeEvent.touches){
            const canvasRect = canvasRef.current.getBoundingClientRect()
            const canvasX = (nativeEvent.changedTouches[0].clientX - canvasRect.left)
            const canvasY = (nativeEvent.changedTouches[0].clientY - canvasRect.top)
            contextRef.current.moveTo(canvasX, canvasY)
        } else{
            const { offsetX, offsetY } = nativeEvent
            contextRef.current.moveTo(offsetX, offsetY)
        }
        setIsDrawing(true)
    }

    const finishDrawing = () => {
        contextRef.current.closePath()
        setIsDrawing(false)
    }

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d")
        context.clearRect(0, 0, canvas.width, canvas.height)
    }

    const draw = ({nativeEvent}) => {
        if (!isDrawing) return
        if (nativeEvent.touches){
            const canvasRect = canvasRef.current.getBoundingClientRect()
            const canvasX = (nativeEvent.changedTouches[0].clientX - canvasRect.left)
            const canvasY = (nativeEvent.changedTouches[0].clientY - canvasRect.top)
            contextRef.current.lineTo(canvasX, canvasY)
        } else{
            const { offsetX, offsetY } = nativeEvent
            contextRef.current.lineTo(offsetX, offsetY)
        }
        contextRef.current.stroke()
    }

    const {seconds, minutes, restart} = useTimer({ onExpire: ()=>{
        if (!showInstructions) confirmSubmitModule()
        else setShowInstructions(false)
    } })

    const alertUser = (e) => {
        e.preventDefault()
        e.returnValue = "Leaving this webpage will result in a faulty testing experience and will have a high chance to invalidate your score."
    }
    const detectLeave = (e) => {
        e.preventDefault()
        if (mIndex > -1 && document.visibilityState !== 'visible'){
            console.log("left this page")
            setCountLeaves(countLeaves+1)
            alert("You have left this testing page. If you continue to do so, your test may be invalidated.")
        }
        console.log(countLeaves)
    }
    useEffect(()=>{
        window.addEventListener('beforeunload', alertUser)
        window.onpopstate = alertUser
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.onpopstate = null
        }
    }, [])
    useEffect(()=>{
        document.addEventListener('visibilitychange', detectLeave)
        return () => {document.removeEventListener('visibilitychange', detectLeave)}
    })
    useEffect(()=>{
        setOpenConfirm(false)
    }, [qIndex, studentAnswers, mIndex])
    useEffect(()=>{
        if (mIndex > -1){
            const time = new Date()
            if (!showInstructions){
                time.setSeconds(time.getSeconds() + (mIndex < 2 ? englishTime : mathTime))
                restart(time)
            } else{
                time.setSeconds(time.getSeconds()+(breakTime))
                restart(time)
            }
        }
    },[mIndex, showInstructions])

    const getTest = async (e) => {
        e.preventDefault()
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/digitalSAT/verify', {
                "JWT": localStorage.getItem('user'),
                "secret_code": secret
            })
            if (response.data === undefined || response.data === null || response.data === ""){
                alert("Test not found")
                return null
            }
            if (test[0].length !== 0) return

            try{
                const userResponse = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                    headers: {
                        "JWT":("Bearer "+localStorage.getItem('user'))
                    }
                })
                const extraTime = parseFloat(userResponse.data.user.extra_time)
                if (extraTime && extraTime > 0){
                    setEnglishTime(englishTime*extraTime)
                    setMathTime(mathTime*extraTime)
                    setBreakTime(breakTime*extraTime)
                    // englishTime *= extraTime
                    // mathTime *= extraTime
                    // breakTime *= extraTime
                }
                setIsLoggedIn(true)
            } catch(err){
                console.log(err)
                alert("Login Failed")
            }

            const questions = response.data.questions
            for (var i = 0; i < questions.length; i++){
                if (questions[i].mr === "Reading") test[questions[i].module-1].push(questions[i])
                else if (questions[i].mr === "Math") test[questions[i].module+1].push(questions[i])
            }
            for (var y = 0; y < 4; y++){
                test[y].sort(function(a,b){return a.number - b.number})
            }
            setTest(test)
            setStudentAnswers([])
            setFlaggedQuestions([])
            for (var x = 0; x < 4; x++){
                const mod = []
                const bools = []
                for (var j = 0; j < test[x].length; j++){
                    mod.push("")
                    bools.push(false)
                }
                // if (x < 2) for (var j = 0; j < 33; j++) mod.push("")
                // else for (var k = 0; k < 27; k++) mod.push("")
                studentAnswers.push(mod)
                flaggedQuestions.push(bools)
            }
            setStudentAnswers(studentAnswers)
            setFlaggedQuestions(flaggedQuestions)
            setQIndex(0)
            setMIndex(0)
            setTestId(response.data.test_id)
            setHideStartButton(true)
            setShowInstructions(true)
            return response.data
        } catch{
            alert("No server response or your session has expired.")
        }
    }

    const submitForGrading = async () => { //Use ~ delimeter to separate answers
        const allStudentAnswers = []
        for (var mi = 0; mi < 4; mi++){
            for (var qi = 0; qi < studentAnswers[mi].length; qi++){
                allStudentAnswers.push(studentAnswers[mi][qi])
            }
        }
        try{
            const completeDate = new Date()
            console.log(testId.includes("PSAT"))
            //todo: post isPSAT
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/digitalSAT/test/grade', {
                "answers": allStudentAnswers.join(","),
                "JWT": localStorage.getItem('user'),
                "test_id": testId,
                "date": `${completeDate.getMonth()+1}/${completeDate.getDate()}/${completeDate.getFullYear()}`,
                "leave_count": countLeaves,
                "RWMod1": studentAnswers[0].join("~"),
                "RWMod2": studentAnswers[1].join("~"),
                "MathMod1": studentAnswers[2].join("~"),
                "MathMod2": studentAnswers[3].join("~"),
                "PSAT": (testId.includes("PSAT") ? "True" : "False")
            })
            if (response.data.error_code === 10) alert("Test not found in database.")
            else if (response.data.error_code === 7) alert("You are not logged in to take this test.")
            else if (response.data.error_code === 5) alert("No answers were submitted for grading.")
            else if (response.data.error_code === -1){
                console.log(response.data)
                return true
            }
            return false
        } catch(err){
            console.log(err)
            return false
        }
    }

    const changeMC = (e) => {
        studentAnswers[mIndex][qIndex] = e.target.value
        setStudentAnswers([...studentAnswers])
    }

    const changeFRQAnswer = (e) => {
        studentAnswers[mIndex][qIndex] = e.target.value
        setStudentAnswers([...studentAnswers])
    }
    
    const inc = () => {if (qIndex < test[mIndex].length -1) setQIndex(qIndex+1)}
    const dec = () => {if (qIndex > 0) setQIndex(qIndex-1)}

    const submitModule = () => {
        console.log(studentAnswers)
        setOpenConfirm(true)
    }

    const confirmSubmitModule = () => {
        if (mIndex === 3){
            const submitted = submitForGrading()
            if (submitted){
                alert("Congrats! You have finished your practice Digital SAT. \nYou may now log into your account to see your scores.")
                navigate("/Login")
            } else {
                alert("There seems to be a problem with connecting to our server.\nPlease try again in a minute.")
            }
        } else{
            setMIndex(mIndex+1)
            setQIndex(0)
            setShowInstructions(true)
        }
    }

    function flagQuestion(m,q) {
        const updatedFlaggedQuestions = [...flaggedQuestions]
        updatedFlaggedQuestions[m][q] = !updatedFlaggedQuestions[m][q]
        setFlaggedQuestions(updatedFlaggedQuestions)
    }
      

    return (
        <>
            <style>{'body { background-color: white; padding-top: 0; user-select: none;}'}</style>
            {showFormulas ? 
                <div className="overlay" onClick={()=>setShowFormulas(false)}>
                    <img src="FormulaSheet.png" alt="formulas"/>
                </div> :
                <></>
            }
            {showFRQInstructions ? 
                <div className="overlay" onClick={()=>setShowFRQInstructions(false)}>
                    <img src="FRQInstructions.png" alt="FRQInstructions"/>
                </div> :
                <></>
            }
            {hideStartButton ? <></> : 
                <>
                    <h1>Tenafly Prep Academy's</h1>
                    <h1>Digital Testing Platform</h1>
                    {
                        isLoggedIn ? 
                        <form id="secret-code-form" onSubmit={getTest}>
                            <label htmlFor="secret-input">Test secret code: </label>
                            <input id="secret-input" value={secret} onChange={(e)=>setSecret(e.target.value)}/>
                            <button type="submit">Get Test</button>
                        </form>
                        :
                        <form id="login-form" onSubmit={login}>
                            <span style={{color:"darkred"}}>Login using your account credentials</span>
                            <br/>
                            <br/>
                            <label htmlFor="email">Email: </label>
                            <input style={{marginBottom:"0.5rem"}} id="email" onChange={(e)=>setEmail(e.target.value)}/>
                            <br/>
                            <label htmlFor="password">Password: </label>
                            <input style={{marginBottom:"0.5rem"}} id="password" type="password" onChange={(e)=>setPassword(e.target.value)}/>
                            <br/>
                            <button type="submit">Login</button>
                        </form> 
                    }
                    
                </>
            }
            <br/>
            {mIndex > -1 ? 
                <div>
                    {showInstructions ? <></> : <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>{`${mIndex < 2 ? "Reading/Writing" : "Math"} Module ${mIndex%2+1}`}</span>}
                    <div className="timer">
                        Time Left <span>{minutes}</span>:<span>{seconds < 10 ? ("0"+seconds) : seconds}</span>
                    </div>
                </div>
                :
                <></>
            }
            {qIndex === -1 ? <></>:
                (showInstructions ? 
                <>
                    {mIndex < 2 ? 
                        <>
                            <h1>{"Reading/Writing Module " + (mIndex+1)}</h1>
                            <button onClick={()=>{
                                setShowInstructions(false)
                                const time = new Date()
                                time.setSeconds(time.getSeconds()+(englishTime))
                                restart(time)
                            }}>Start Reading/Writing</button>
                            <div style={{margin:"2rem 0.5rem 1rem 0.5rem"}}>
                                <span style={{fontWeight:"bold"}}>Testing Tools and Features:</span><br/>
                                <img src="testfeatures.png" style={{width:"min(100%,40rem)",border:"1px black solid"}}/>
                            </div>
                        </> : 
                        <>
                            <h1>{"Math Module " + (mIndex-1)}</h1>
                            <button onClick={()=>{
                                setShowInstructions(false)
                                const time = new Date()
                                time.setSeconds(time.getSeconds()+(mathTime))
                                restart(time)
                            }}>Start Math</button>
                            <div style={{margin:"2rem 0.5rem 1rem 0.5rem"}}>
                                <span style={{fontWeight:"bold"}}>Testing Tools and Features:</span><br/>
                                <img src="testfeatures.png" style={{width:"min(100%,40rem)",border:"1px black solid"}}/>
                            </div>
                        </>
                    }
                </> :
                <div className="digital-testing-wrapper">
                    <div className="digital-testing-container">
                        <div style={{display:"flex",marginBottom:"1rem",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{textAlign:"left",fontWeight:"bold"}}>{`Q${qIndex+1}.`}</span>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                className="digital-test-bookmark" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                                onClick={()=>flagQuestion(mIndex,qIndex)}
                            >
                                <path
                                    d="M6 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v20l-6-3-6 3V2z" 
                                    fill={flaggedQuestions[mIndex][qIndex] ? "red" : ""}
                                />
                            </svg>
                        </div>
                        <canvas
                            onMouseDown={startDrawing}
                            onTouchStart={startDrawing}
                            onMouseUp={finishDrawing}
                            onTouchEnd={finishDrawing}
                            onMouseMove={draw}
                            onTouchMove={draw}
                            onMouseOut={finishDrawing}
                            onTouchCancel={finishDrawing}
                            ref={canvasRef}
                            style={{backgroundColor:"transparent", position:"absolute", zIndex:"2",touchAction:"none"}}
                        />
                        <img src={test[mIndex][qIndex].url} alt="" onLoad={changeCanvas} ref={imageRef}></img>
                        <button onClick={clearCanvas}>Clear Drawings</button>
                        {mIndex > 1 ? 
                            <>
                                <button onClick={()=>setShowFormulas(true)}>Show Formulas</button>
                            </> :
                            <></>
                        }
                    </div>
                    <div className="digital-testing-container">
                        <h3>Pick/Type Your Answers Here</h3>
                        
                        { test[mIndex][qIndex].FRQ ? 
                            <div>
                                <label htmlFor={mIndex+"-"+qIndex}>Type your answer: </label><input id={mIndex+"-"+qIndex} value={studentAnswers[mIndex][qIndex]} onChange={changeFRQAnswer}></input>
                                <p style={{fontWeight: "bold", color: "darkred"}}>Please read the FRQ instructions carefully to avoid mistakes in grading.</p>
                                <button onClick={()=>setShowFRQInstructions(true)}>Show FRQ Instructions</button>
                            </div> : 
                            <>
                                <form>
                                    {["A","B","C","D"].map((answer,i) => {
                                        return <>
                                            <input
                                                type="radio"
                                                className="mc"
                                                id={mIndex+"-"+qIndex+"-"+i}
                                                value={answer}
                                                key={mIndex+"-"+qIndex+"-"+i}
                                                onChange={changeMC}
                                                checked={studentAnswers[mIndex][qIndex] === answer}
                                            >
                                            </input>
                                            <label 
                                                htmlFor={mIndex+"-"+qIndex+"-"+i}
                                                key={mIndex+","+qIndex+","+i}
                                            >
                                                {answer}
                                            </label>
                                            <br/>
                                        </>
                                    })}
                                </form>
                            </>
                        }
                        <button onClick={dec}>previous question</button>
                        <button onClick={inc}>next question</button>
                        <br/>
                        <button onClick={submitModule}>submit module</button>
                        <br/>
                        <br/>
                        <div style={{color:"darkred"}}>Text (201)-471-8366 if you encounter any bugs during test taking.</div>
                        {openConfirm ? 
                            <>
                                <p style={{color:"red",fontWeight:"bold"}}>Are you sure you want to submit?</p>
                                <p style={{color:"red",fontWeight:"bold"}}>You will not be allowed to come back to this module later.</p>
                                <button onClick={confirmSubmitModule}>Confirm Submit</button>
                            </> : 
                            <></>}
                    </div>
                    <div>
                        <h3>Your Answers</h3>
                        <div className="student-answers-wrapper">
                            {studentAnswers[mIndex].map((answer,i)=>{
                                return <>
                                    <div 
                                        key={mIndex+"/"+qIndex+"/"+i} 
                                        className="student-answers-element" 
                                        onClick={()=>setQIndex(i)}
                                        style={{color:(flaggedQuestions[mIndex][i] ? "red" : "")}}
                                    >
                                        <b style={{cursor:"pointer"}}>{(i+1)+". "}</b>{answer}
                                    </div>
                                </>
                            })}
                        </div>
                        <p style={{color: 'darkred'}}>**Click on the numbers to navigate to them.</p>
                    </div>
                </div>)
            }
        </>
    )
}
