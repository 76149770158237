import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './AdminTestingPage.css'
import './StyleComponents/Login.css'
import axios from 'axios'
import {useLogin} from '../LoginContext'

export default function Login() {
  const [loginEmail, setLoginEmail] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [registerEmail, setRegisterEmail] = useState("")
  const [registerPassword, setRegisterPassword] = useState("")
  const [registerPassword2, setRegisterPassword2] = useState("")
  const [registerSecret, setRegisterSecret] = useState("")
  const [forgotEmail, setForgotEmail] = useState("")
  const [forgotFirstName, setForgotFirstName] = useState("")
  const [forgotLastName, setForgotLastName] = useState("")
  const [resetEmail, setResetEmail] = useState("")
  const [resetCode, setResetCode] = useState("")
  const [resetPassword, setResetPassword] = useState("")
  const [resetPassword2, setResetPassword2] = useState("")
  const [whatDoing, setWhatDoing] = useState("Login")
  const [imgSrc, setImgSrc] = useState('')
  const {setIsLoggedIn} = useLogin()

  const navigate = useNavigate()

  useEffect(()=>{
    setImgSrc(Math.random()*2 <= 1 ? "cool-turkey.png" : "cool-turkey-2.png")
  },[])

  function clearAll(){
    setLoginEmail("")
    setLoginPassword("")
    setRegisterEmail("")
    setRegisterPassword("")
    setRegisterPassword2("")
    setRegisterSecret("")
    setForgotEmail("")
    setForgotFirstName("")
    setForgotLastName("")
    setResetEmail("")
    setResetCode("")
    setResetPassword("")
    setResetPassword2("")
  }
  const changeToLogin = () => {
    clearAll()
    setWhatDoing("Login")
  }
  const changeToRegister = () => {
    clearAll()
    setWhatDoing("Register")
  }
  const changeToForgot = () => {
    clearAll()
    setWhatDoing("Forgot")
  }
  const changeToReset = () => {
    clearAll()
    setWhatDoing("Reset")
  }

  const login = async (e) => {
    e.preventDefault()
    if (loginEmail === "" || loginPassword === ""){
      alert("One or more fields have been left blank")
      return
    }
    try{
      const response = await axios.post('https://www.tenaflyprepacademy.com/server/login',{
        email: loginEmail,
        password: loginPassword
      })
      localStorage.setItem('user', response.data)
      setIsLoggedIn(true)
      navigate("/Dashboard")
    } catch(err){
      console.log(err)
      alert("Something went wrong. Please try again later.")
    }
  }
  const register = async (e) => {
    e.preventDefault()
    console.log({"email":registerEmail,"password":registerPassword,"password verify":registerPassword2,"secret":registerSecret})
    if (registerEmail === "" || registerPassword === "" || registerPassword2 === "" || registerSecret === ""){
      alert("One or more fields have been left blank")
      return
    }
    if (registerPassword !== registerPassword2){
      alert("Passwords do not match.")
      return
    }
    try{
      const response = await axios.post('https://www.tenaflyprepacademy.com/server/register',{
        email: registerEmail,
        password: registerPassword,
        secret: registerSecret
      })
      const errorCode = response.data
      console.log(errorCode)
      if (errorCode === 0){
        alert("You have successfully registered!")
        changeToLogin()
      } else if (errorCode === 1){
        alert("Your email was not found in our database")
      } else if (errorCode === 5){
        alert("Your secret code was not recognized. Please double check your secret code sent to your email.")
      } else if (errorCode === 9){
        alert("You are already registered.")
      } else{
        alert("We have encountered an unknown error. If errors persist, please contact tenaflyacademy26@gmail.com")
      }
    } catch(err){
      console.log(err)
      alert("Something went wrong. Please try again later.")
    }
  }
  const passwordChangeRequest = async (e) => {
    e.preventDefault()
    console.log({"email":forgotEmail,"first-name":forgotFirstName,"last-name":forgotLastName})
    if (forgotEmail === "" || forgotFirstName === "" || forgotLastName === ""){
      alert("One or more fields have been left blank")
      return
    }
    try{
      const response = await axios.post('https://www.tenaflyprepacademy.com/server/student/forgotpassword',{
        email: forgotEmail,
        first_name: forgotFirstName,
        last_name: forgotLastName
      })
      const errorCode = response.data
      console.log(errorCode)
      if (errorCode === 10){
        alert("You have left one or more fields blank")
      } else if (errorCode >= 5){
        alert("You were not found in our database. Please double check your information.")
      } else if (errorCode === 0){
        alert("You have submitted a password reset request. Please do not close this tab and open a new tab to check your email for further instructions.")
        changeToReset()
      } else{
        alert("Unknown error. Please try again later")
      }
    } catch(err){
      console.log(err)
      alert("Something went wrong. Please try again later.")
    }
  }
  const changePassword = async (e) => {
    e.preventDefault()
    console.log({"email":resetEmail,"code":resetCode,"password":resetPassword,"password verify":resetPassword2})
    if (resetEmail === "" || resetCode === "" || resetPassword === "" || resetPassword2 === ""){
      alert("One or more fields have been left blank")
      return
    }
    try{
      const response = await axios.post('https://www.tenaflyprepacademy.com/server/student/newpassword',{
        email: resetEmail,
        code: resetCode,
        new_password: resetPassword
      })
      const errorCode = response.data
      console.log(errorCode)
      if (errorCode === 0){
        alert("You have successfully changed your password, return to login.")
        changeToLogin()
      } else if (errorCode > 4){
        alert("You didn't provide the correct information.")
      } else if (errorCode > 2){
        alert("The code you provided is not correct.")
      } else{
        alert("Unkown error ocurred. Please try again later")
      }
    }catch(err){
      console.log(err)
      alert("Something went wrong. Please try again later.")
    }
  }

  const getForm = () => {
    if (whatDoing === "Login"){
      return(
        <>
          <div id="login-form" className="login-form">
            <div>
              <br/>
              <span className="login-greeting">Welcome Back</span><br/>
              <form onSubmit={(e)=>login(e)}>
                <label htmlFor="login-email">Email: </label><br/>
                <input spellCheck="false" id="login-email" type="username" value={loginEmail} onChange={(e)=>setLoginEmail(e.target.value)}/><br/>
                <label htmlFor="login-password">Password: </label><br/>
                <input spellCheck="false" id="login-password" type="password" value={loginPassword} onChange={(e)=>setLoginPassword(e.target.value)}/><br/>
                <button className="login-btn" onClick={(e)=>login(e)}>Login</button>
              </form>
              <span onClick={()=>changeToForgot()} className="login-clickable">Forgot Password?</span><br/><br/>
              <span>Don't have an account? <span onClick={()=>changeToRegister()} className="login-clickable">Sign Up</span></span><br/><br/>
              <span>Need help? <span onClick={()=>navigate("/FAQ")} className="login-clickable">Click here for FAQ</span></span>
            </div>
          </div>
        </>
      )
    } else if (whatDoing === "Register"){
      return(
        <>
          <div id="register-form" className="login-form">
            <div>
              <br/>
              <span className="login-greeting">Create Account</span><br/>
              <span>To create an account, you must use the secret code emailed to you.</span>
              <form onSubmit={(e)=>register(e)}>
                <label htmlFor="register-email">Email: </label><br/>
                <input spellCheck="false" id="register-email" value={registerEmail} onChange={(e)=>setRegisterEmail(e.target.value)}/><br/>
                <label htmlFor="register-secret">Secret: </label><br/>
                <input spellCheck="false" id="register-secret" value={registerSecret} onChange={(e)=>setRegisterSecret(e.target.value)}/><br/>
                <label htmlFor="register-password">Password: </label><br/>
                <input spellCheck="false" id="register-password" value={registerPassword} type="password" onChange={(e)=>setRegisterPassword(e.target.value)}/><br/>
                <label htmlFor="register-password2">Confirm Password: </label><br/>
                <input spellCheck="false" id="register-password2" value={registerPassword2} type="password" onChange={(e)=>setRegisterPassword2(e.target.value)}/><br/>
                <button className="login-btn">Register</button>
              </form>
              <span onClick={()=>changeToLogin()} className="login-clickable">Back to Login</span>
            </div>
          </div>
        </>
      )
    } else if (whatDoing === "Forgot"){
      return(
        <>
          <div id="forgot-form" className="login-form">
            <div>
              <br/>
              <span className="login-greeting">Forgot Password Form</span><br/>
              <span>Please provide enough information to recover your password.</span><br/>
              <form onSubmit={(e)=>passwordChangeRequest(e)}>
                <label htmlFor="forgot-email">Email: </label><br/>
                <input spellCheck="false" id="forgot-email" value={forgotEmail} onChange={(e)=>setForgotEmail(e.target.value)}/><br/>
                <label htmlFor="forgot-first-name">First Name: </label><br/>
                <input spellCheck="false" id="forgot-first-name" value={forgotFirstName} onChange={(e)=>setForgotFirstName(e.target.value)}/><br/>
                <label htmlFor="forgot-last-name">Last Name: </label><br/>
                <input spellCheck="false" id="forgot-last-name" value={forgotLastName} onChange={(e)=>setForgotLastName(e.target.value)}/><br/>
                <button onClick={(e)=>passwordChangeRequest(e)} className="login-btn">Request New Password</button>
              </form>
              <span>Already have a password reset code? <span className="login-clickable" onClick={()=>changeToReset()}>Click to continue...</span></span><br/><br/>
              <span onClick={()=>changeToLogin()} className="login-clickable">Back to Login</span>
            </div>
          </div>
        </>
      )
    } else if (whatDoing === "Reset"){
      return(
        <>
          <div id="reset-form" className="login-form">
            <div>
              <br/>
              <span className="login-greeting">Reset Password</span><br/>
              <span>Please check your email for the code to reset your password</span><br/>
              <form onSubmit={(e)=>changePassword(e)}>
                <label htmlFor="reset-email">Email: </label><br/>
                <input spellCheck="false" id="reset-email" value={resetEmail} onChange={(e)=>setResetEmail(e.target.value)}/><br/>
                <label htmlFor="reset-code">Reset Code: </label><br/>
                <input spellCheck="false" id="reset-code" value={resetCode} onChange={(e)=>setResetCode(e.target.value)}/><br/>
                <label htmlFor="reset-password">Password: </label><br/>
                <input spellCheck="false" id="reset-password" value={resetPassword} type="password" onChange={(e)=>setResetPassword(e.target.value)}/><br/>
                <label htmlFor="reset-password2">Confirm Password: </label><br/>
                <input spellCheck="false" id="reset-password2" value={resetPassword2} type="password" onChange={(e)=>setResetPassword2(e.target.value)}/><br/>
                <button onClick={(e)=>changePassword(e)} className="login-btn">Reset Password</button>
              </form>
              <span>Need a reset code? <span onClick={()=>changeToForgot()} className="login-clickable">Click here...</span></span><br/><br/>
              <span onClick={()=>changeToLogin()} className="login-clickable">Back to Login</span>
            </div>
          </div>
        </>
      )
    } else{
      return <></>
    }
  }

  return (
    <>
      <div className="login-div">
        {getForm()}
        <div className="login-left">
          <div className="login-img-container">
            <div className="login-image-top-fade"/>
            <div className="login-image-bottom-fade"/>
            <img src="sakura-caligraphy.png" alt="iso" className="isometric-diorama-img"/>
          </div>
        </div>
      </div>
    </>
  );
}
