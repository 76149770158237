import React, {useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import './StyleComponents/PSATAdvert.css'
import CalendarPSAT2023 from './Components/CalendarPSAT2023'
import Contacts from './Components/Contacts'

export default function PSATAdvert(){
    const scheduleRef = useRef()
    const contactRef = useRef()
    const testingRef = useRef()
    const reviewRef = useRef()
    const topRef = useRef()
    const navigate = useNavigate()

    useEffect(()=>{   
        const options = {
            rootMargin: '0px',
            threshold: 0.1
        }
  
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)
  
        const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
        hiddenElements.forEach((element)=>observer.observe(element))

        return () => {
            observer.disconnect()
        }
    },[])

    function scrollTo(to){
        var reference = null
        if (to === "Testing") reference = testingRef
        else if (to === "Schedule") reference = scheduleRef
        else if (to === "Review") reference = reviewRef
        else if (to === "Top") reference = topRef
        else reference = contactRef
        const position = reference.current.getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.4
        window.scrollTo({top: position, behavior: "smooth"})
    }

    return(
        <>
            <h1 ref={topRef}><span className="blinking">NEW!</span> Digital PSAT 2023 Program</h1>
            <div className="soft-edge-container"><img src="StudentComputer.png" alt="student-computer" className="soft-edge-image"/></div>
            <div className="navigation-button-container">
                <div className="navigation-button" onClick={()=>scrollTo("Schedule")}>Schedule</div>
                <div className="navigation-button" onClick={()=>scrollTo("Testing")}>Testing</div>
                <div className="navigation-button" onClick={()=>scrollTo("Review")}>Test Review</div>
                <div className="navigation-button" onClick={()=>scrollTo("Contact")}>Contact</div>
            </div>
            <div className="section-title-bar" ref={scheduleRef}>
                <div className="nav-up" onClick={()=>scrollTo("Top")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                </div>
                <div className="section-title-container">
                    <img src="calendar-white.svg" alt="review-icon" className="icon"/>
                    <span className="section-title">Schedule</span>
                </div>
                <div className="nav-down" onClick={()=>scrollTo("Testing")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                </div>
            </div>
            <div className="calendar-wrapper">
                <CalendarPSAT2023 month={"September"}/>
                <CalendarPSAT2023 month={"October"}/>
            </div>
            <div style={{display:"flex",justifyContent:"center"}}>
                <table className="schedule-table">
                    <tbody>
                        <tr>
                            <td><div style={{background:"rgb(255, 120, 120)"}} className="color-coding-box"/> Practice Test 1</td>
                            <td>Opens 9/7 ~ Closes 9/13</td>
                        </tr>
                        <tr>
                            <td><div style={{background:"rgb(255, 198, 112)"}} className="color-coding-box"/> Practice Test 2</td>
                            <td>Opens 9/14 ~ Closes 9/20</td>
                        </tr>
                        <tr>
                            <td><div style={{background:"rgb(255, 255, 120)"}} className="color-coding-box"/> Practice Test 3</td>
                            <td>Opens 9/21 ~ Closes 9/27</td>
                        </tr>
                        <tr>
                            <td><div style={{background:"rgb(106, 176, 255)"}} className="color-coding-box"/> Test Review</td>
                            <td>Thursday September 28th<br/>
                                English: 7pm - 8pm <br/>
                                Math: 8pm - 9pm
                            </td>
                        </tr>
                        <tr>
                            <td><div style={{background:"rgb(125, 255, 120)"}} className="color-coding-box"/> Practice Test 4</td>
                            <td>Opens 9/28 ~ Closes 10/4</td>
                        </tr>
                        <tr>
                            <td><div style={{background:"rgb(106, 176, 255)"}} className="color-coding-box"/> Test Review</td>
                            <td>Thursday October 5th<br/>
                                English: 7pm - 8pm <br/>
                                Math: 8pm - 9pm
                            </td>
                        </tr>
                        <tr>
                            <td><div style={{background:"violet"}} className="color-coding-box"/> Vocab Lesson</td>
                            <td>Saturday September 30th<br/>
                                4:00pm - 4:30pm
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="section-title-bar" ref={testingRef}>
                <div className="nav-up" onClick={()=>scrollTo("Schedule")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                </div>
                <div className="section-title-container">
                    <img src="computer-white.svg" alt="review-icon" className="icon"/>
                    <span className="section-title">Practice Test</span>
                </div>
                <div className="nav-down" onClick={()=>scrollTo("Review")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                </div>
            </div>
            <div className="section-content-container">
                <p style={{fontSize:"1.2rem"}}>We created a digital test taking platform. Features include:</p>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <ul className="grid-list">
                        <li>Built in Timer</li>
                        <li>Automated Breaks</li>
                        <li>Cheat Detection</li>
                        <li>Highlighter/Note-Taking</li>
                        <li>Popup-Instructions</li>
                        <li>Quick & Easy Question Navigation</li>
                        <li>Fully Automated Grading</li>
                        <li>Instantaneous Score & Breakdown</li>
                    </ul>
                </div>
                <p>Students take the digital exam through their accounts on our website. Students may take the exam from home or come in person and use our tablets.</p>
                <img src="DigitalTestExample.png" alt="digital-test" className="digital-test-screenshot"/>
                <p>If you would like to know more about College Board's shift torwards Digital Testing, <span className="span-link" onClick={()=>navigate("/DigitalSAT")}>click here for more info.</span></p>
            </div>
            <div className="section-title-bar" ref={reviewRef}>
                <div className="nav-up" onClick={()=>scrollTo("Testing")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                </div>
                <div className="section-title-container">
                    <img src="writing-clipboard-white.svg" alt="review-icon" className="icon"/>
                    <span className="section-title">Test Review</span>
                </div>
                <div className="nav-down" onClick={()=>scrollTo("Contact")}>
                    <span>Scroll</span>
                    <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                </div>
            </div>
            <div className="test-review-content-container">
                <p>On Thursday <b>September 28th</b> and Thursday <b>October 5th</b>, we are hosting an online lecture through <b>Zoom</b> from 7pm to 9pm.</p>
                <p>We will be covering a variety of topics including but not limited to:</p>
                <ul>
                    <li>Test taking strategies</li>
                    <li>Common mistakes</li>
                    <li>Key knowledge for future tests</li>
                    <li>Understanding fundamentals</li>
                    <li>Study strategies</li>
                </ul>
                <p>On Saturday <b>September 30th (4:00pm - 4:30pm)</b> we will be hosting an online lecture for <b>Vocab Self-Study</b>. We will be showing students how to leverage AI to accelerate learning.</p>
                <p>Please <span className="span-link" onClick={()=>scrollTo("Contact")}>contact us</span> for pricing inquiries and/or signup for the PSAT 2023 Program. A student account will be created for you after signup. All we need is student First name, Last name, and Email.</p>
            </div>
            <div ref={contactRef}><Contacts/></div>
        </>
    )
}