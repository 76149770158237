import React, {useState, useRef, useEffect} from 'react'
import './StyleComponents/Help.css'
import Contacts from './Components/Contacts'

export default function Help(){
    const contactRef = useRef()
    const [expand0, setExpand0] = useState(false)
    const [expand1, setExpand1] = useState(false)
    const [expand2, setExpand2] = useState(false)
    const [expand3, setExpand3] = useState(false)

    useEffect(()=>{   
        const options = {
            rootMargin: '0px',
            threshold: 0.1
        }
  
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)
  
        const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
        hiddenElements.forEach((element)=>observer.observe(element))
  
        return () => {
            observer.disconnect()
        }
    },[])

    function expand(index){
        if (index === 0){
            setExpand0((prev)=>!prev)
            console.log(expand0)
        } else if (index === 1){
            setExpand1((prev)=>!prev)
        } else if (index === 2){
            setExpand2((prev)=>!prev)
        } else if (index === 3){
            setExpand3((prev)=>!prev)
        }
    }
    return(
        <>
            <h1>Troubleshooting FAQ</h1>
            <div className="faq-container">
                <div className="faq">
                    <div className="faq-q" onClick={()=>expand(0)}>
                        <span>How do I make an account?</span>
                        <img className={`faq-arrow${expand0 ? " expanded" : ""}`} src="scroll-up-arrow.svg" alt="down"/>
                    </div>
                    <div className={`faq-a${expand0 ? " expanded" : ""}`}>
                        <span>Follow the following steps to create an account.</span>
                        <ul>
                            <li>Contact us and provide us your name and email.</li>
                            <li>Check your email after we create your account. Please contact us if you did not receive an email for registration.</li>
                            <li>
                                <div>Register on our login page using your secret code provided in the email.</div>
                                <img className="faq-img" src="faq-login-reg.png" alt="faq-reg"/>
                                <img className="faq-img" src="faq-register.png" alt="faq-rreg"/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="faq">
                    <div className="faq-q" onClick={()=>expand(1)}>
                        <span>How do I take a digital test?</span>
                        <img className={`faq-arrow${expand1 ? " expanded" : ""}`} src="scroll-up-arrow.svg" alt="down"/>
                    </div>
                    <div className={`faq-a${expand1 ? " expanded" : ""}`}>
                        <span>Students that have an account and been assigned a digital test will receive an email notification with the following instructions.</span>
                        <ul>
                            <li>
                                <div>Navigate to the digital testing platform</div>
                                <img className="faq-img" src="faq-dt-button.png" alt="faq-dt"/>
                                <div style={{color:"darkred"}}>**Students who are not assigned to take a digital test will not see the clickable bar.</div>
                            </li>
                            <li>
                                <div>Log in with your student account credentials (same as your login credentials).</div>
                                <img className="faq-img" src="faq-dt-login.png" alt="faq-login"/>
                            </li>
                            <li>
                                <div>Enter the test code that was sent to you by email. Please contact us if you did not get an email with the test code.</div>
                                <img className="faq-img" src="faq-dt-secret.png" alt="faq-secret"/>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div className="faq">
                    <div className="faq-q" onClick={()=>expand(2)}>
                        <span>How do I check my scores?</span>
                        <img className={`faq-arrow${expand2 ? " expanded" : ""}`} src="scroll-up-arrow.svg" alt="down"/>
                    </div>
                    <div className={`faq-a${expand2 ? " expanded" : ""}`}>
                        <div>Students and parents can check test scores through their dashboards after signing in with their accounts.</div>
                        <img className="faq-img" src="faq-scores.png" alt="faq-scores"/>
                    </div>
                </div>
                <div className="faq">
                    <div className="faq-q" onClick={()=>expand(3)}>
                        <span>How do I use the Homework Platform?</span>
                        <img className={`faq-arrow${expand3 ? " expanded" : ""}`} src="scroll-up-arrow.svg" alt="down"/>
                    </div>
                    <div className={`faq-a${expand3 ? " expanded" : ""}`}>
                        <div>Students with accounts can access the Homework Platform through the dashboard.</div>
                        <img className="faq-img" src="faq-hw.png" alt="faq-hw"/>
                        <img className="faq-img" src="faq-hw-platform.png" alt="faq-hw-plat"/>
                    </div>
                </div>
            </div>
            <div ref={contactRef}><Contacts/></div>
        </>
    )
}