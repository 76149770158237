import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';

export default function DeleteTest(){
    const errRef = useRef()

    const [errMsg, setErrMsg] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')

    useEffect(()=>{
        setErrMsg('')}, [email]
    );

    const submitForm = async (e) =>{
        e.preventDefault();
        if (confirmEmail !== email){
            setErrMsg("Emails do not match.")
            return
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/delete/parent', {
                "email": email,
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            console.log(response.data);
            if (response.data) alert("Successfully deleted parent");
            else setErrMsg("Delete unsuccessful. Please double check information.");
        } catch{
            setErrMsg('No server response. Please try again later.');
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color:'darkred'}}>Please double check that all the information is correct.</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Parent Email: <input className="admin-input" onChange={e=>setEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Confirm Parent Email: <input className="admin-input" onChange={e=>setConfirmEmail(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Delete Parent</button>
            </form>
        </>
    );
}