import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'

export default function GetAllParents(){
    const errRef = useRef()

    const [parentList, setParentList] = useState([])
    const [errMsg, setErrMsg] = useState('')
    const [studentList, setStudentList] = useState([])

    useEffect(()=>{
        setErrMsg('')
    }, [])

    const getParents = async (e) => {
        e.preventDefault();
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/allParents", {
                headers: {"JWT": ("Bearer "+localStorage.getItem('user'))}
            })
            setParentList(response.data)
            console.log(response)
            setErrMsg('')
        } catch(err){
            setErrMsg("No server response. Please try again later.")
        }
    }

    const getStudentList = (email) => {
        for (var i = 0; i < parentList.length; i++){
            if (parentList[i].email === email){
                setStudentList(parentList[i].students)
                return
            }
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color: 'darkred'}}>Use this query to view all parents and their students</p>
            <button onClick={getParents}>Get All Parents</button>
            <div className="rowbox">
                <div className="student-list-container">
                    <ul className="student-ul">
                        {parentList.map((parent, i) => {
                            return <li key={i} onClick={(e)=>getStudentList(parent.email)}>{parent.email + " ("+parent.secret+")"}</li>
                        })}
                    </ul>
                </div>
                <div className="student-list-container">
                    <ul className="student-ul">
                        {studentList.map((student, i) =>{
                            return <li key={i}>{student.first_name + " " + student.last_name}</li>
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}