import React, { useState, useEffect } from "react";
import "./HomeworkCalendar.css";

export default function HomeworkCalendar({setSelectedDate, complete, incomplete}) {
  const [currentMonth, setCurrentMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const monthColor = ["lightblue","mistyrose","#77DD77","#E0B0FF","bisque","#FF8674","orange","gold","#E6E6FA","#C8B560","#C9C0BB","#3BB9FF"]
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
  const startingDayOfWeek = firstDayOfMonth.getDay()

  const daysInMonth = Array.from({ length: new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate() }, (_, index) => index + 1)
  const blankDays = Array.from({ length: startingDayOfWeek }, (_, index) => " ")
  const daysToShow = [...blankDays, ...daysInMonth]

  function getHomeworkToday(day){
    console.log(incomplete)
    console.log(complete)
    console.log(incomplete[`${currentMonth.getMonth()+1}/${day}/${currentMonth.getFullYear()}`])
    console.log(complete[`${currentMonth.getMonth()+1}/${day}/${currentMonth.getFullYear()}`])
    setSelectedDate((currentMonth.getMonth()+1)+"/"+day+"/"+currentMonth.getFullYear())
  }

  function getBackground(day){
    if (incomplete[`${currentMonth.getMonth()+1}/${day}/${currentMonth.getFullYear()}`]){
      return <div className="calendar-date-completed" onClick={()=>getHomeworkToday(day)}/>
    } else if (complete[`${currentMonth.getMonth()+1}/${day}/${currentMonth.getFullYear()}`]){
      return <div className="calendar-date-assigned" onClick={()=>getHomeworkToday(day)}/>
    }
    return <></>
  }

  return (
    <div className="calendar">
      <div className="calendar-header" style={{background:`${monthColor[currentMonth.getMonth()]}`}}>
        <button onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1))}>{'<'}</button>
        <span>{currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
        <button onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1))}>{'>'}</button>
      </div>
      <table className="calendar-days">
        <thead>
            <tr>
            {daysOfWeek.map((dayOfWeek, index) => (
                <th key={index}>{dayOfWeek}</th>
            ))}
            </tr>
        </thead>
        <tbody>
            {[...Array(6)].map((_, rowIndex) => (
            <tr key={rowIndex}>
                {[...Array(7)].map((_, colIndex) => {
                const dayIndex = rowIndex * 7 + colIndex;
                const day = daysToShow[dayIndex];

                return (
                    <td key={dayIndex} className="calendar-date">
                        {
                            day === undefined || day === " " ? <></> : 
                            <>
                                {getBackground(day)}
                                <span>{day}</span>
                            </>
                        }
                    </td>
                );
                })}
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  );
}