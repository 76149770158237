import Contacts from './Components/Contacts'
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Marquee from './Components/Marquee'
import './Components/SmallCard.css'
import './StyleComponents/HomeNav.css'
import './StyleComponents/HomeTitle.css'
import './StyleComponents/HomeStandardizedTesting.css'
import './StyleComponents/AboutUs.css'
import './StyleComponents/HomeCourses.css'
import './StyleComponents/HomeDigitalSAT.css'
import './StyleComponents/HomeStdTesting.css'

//cdeac0,efe9ae,fec3a6,ff928b,ffac81
//e4c1f9,a9def9,d0f4de,fcf6bd,ff99c8

export default function Home() {
  const aboutUsRef = useRef()
  const contactRef = useRef()
  const stdRef = useRef()
  const coursesRef = useRef()
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= 550)
  const firstImageRef = useRef()
  const secondImageRef = useRef()
  const thirdImageRef = useRef()
  const fourthImageRef = useRef()
  const firstTitleRef = useRef()
  const secondTitleRef = useRef()
  const thirdTitleRef = useRef()
  const fourthTitleRef = useRef()
  const images = [firstImageRef, secondImageRef, thirdImageRef, fourthImageRef]
  const titles = [firstTitleRef, secondTitleRef, thirdTitleRef, fourthTitleRef]
  const navigate = useNavigate()

  const handleResize = () => {
        setIsSmallWidth(window.innerWidth <= 550)
    }
    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return ()=>{window.removeEventListener('resize',handleResize)}
    },[])
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1)%4);
    }, 4000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    images.forEach((imageRef, index) => {
      if (imageRef.current) {
        if (index === currentImageIndex) {
          imageRef.current.classList.add('is-visible')
        } else {
          imageRef.current.classList.remove('is-visible')
        }
      }
    })
    titles.forEach((titleRef, index)=>{
      if (titleRef.current){
        if (index === currentImageIndex){
          titleRef.current.classList.add('is-visible')
        } else{
          titleRef.current.classList.remove('is-visible')
        }
      }
    })
  }, [currentImageIndex])

  useEffect(()=>{   
      const options = {
          rootMargin: '0px',
          threshold: 0.1
      }

      const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
          if (entry.isIntersecting) {
              entry.target.classList.add('visible')
          }
          })
      }, options)

      const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
      hiddenElements.forEach((element)=>observer.observe(element))

      return () => {
          observer.disconnect()
      }
  },[])

  const scrollTo = (to) => {
      var reference = null
      if (to === "About Us") reference = aboutUsRef
      else if (to === "Contact") reference = contactRef
      else if (to === "Testing") reference = stdRef
      else if (to === "Courses") reference = coursesRef
      else reference = aboutUsRef
      const position = reference.current.getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.1
      window.scrollTo({top: position, behavior: "smooth"})
  }

  return(
      <>
            <div className="full-screen-container">
                <div className="TPA-title-container">
                    <span className="TPA-title">Tenafly Prep Academy</span>
                </div>
                <div className="title-section-container">
                <div className="title-section-1">
                    <div className="changing-images-container">
                        <img src="TPA-front.jpg" alt="front" ref={firstImageRef} className="changing-image"/>
                        <img src="stock-classroom-2.jpg" alt="class-2" ref={secondImageRef} className="changing-image" />
                        <img src="stock-testing.jpg" alt="testing" ref={thirdImageRef} className="changing-image" />
                        <img src="stock-consulting.jpg" alt="consulting" ref={fourthImageRef} className="changing-image" />
                    </div>
                    <div className="changing-titles-container">
                        <span ref={firstTitleRef} className="changing-subtitle"></span>
                        <span ref={secondTitleRef} className="changing-subtitle">Tutoring</span>
                        <span ref={thirdTitleRef} className="changing-subtitle">Testing Center</span>
                        <span ref={fourthTitleRef} className="changing-subtitle">Consulting</span>
                    </div>
                </div>
                <div className="title-section-2">
                    <div>
                        <span className="latest-news-title">Latest News</span>
                        <ul>
                            <li style={{marginTop:"0.5rem"}}><span className="latest-news" onClick={()=>navigate("/Summer")}><b>Summer 2024 Program</b></span></li>
                            <li style={{marginTop:"0.5rem"}}><span className="latest-news" onClick={()=>navigate("/DigitalTestingIntro")}>Click to meet our new <b>Digital Testing Platform</b></span></li>
                            <li style={{marginTop:"0.5rem"}}><span className="latest-news" onClick={()=>navigate("/DigitalSAT")}>Click to learn more about the new <b>Digital SAT</b></span></li>
                            <li style={{marginTop:"0.5rem"}}><span className="latest-news" onClick={()=>navigate("/Vocab")}>Check out our <b>Vocab Training Platform</b></span></li>
                            {/* <li style={{marginTop:"0.5rem"}}><span className="latest-news" onClick={()=>navigate("/BookClub")}><b>Book Club</b> will start Thursday 11/2.</span></li> */}
                        </ul>
                        <Marquee/>
                    </div>
                    <div className="nav-container">
                        <div className="nav-text">
                            <div className="nav-box">
                                <span onClick={()=>scrollTo("About Us")}><img src="about.svg" alt="about" className="nav-icon"/>About Us</span>
                            </div>
                            <div className="nav-box">
                                <span onClick={()=>scrollTo("Testing")}><img src="writing-clipboard.svg" alt="sat/act" className="nav-icon"/>SAT/ACT</span>
                            </div>
                            <div className="nav-box">
                                <span onClick={()=>scrollTo("Courses")}><img src="subjects.svg" alt="subjects" className="nav-icon"/>Courses</span>
                            </div>
                            <div className="nav-box">
                                <span onClick={()=>scrollTo("Contact")}><img src="contacts.svg" alt="contacts" className="nav-icon"/>Contacts</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          <div className="front-page-content">
            <div className="about-us-container">
                <div className="about-us-title-container">
                    <div className="nav-up" onClick={()=>{window.scrollTo({top:0,behavior:'smooth'})}}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="about-us-title">
                        <img src="about-white.svg" alt="about" className="about-us-icon"/>
                        <h1 ref={aboutUsRef}>About Us</h1>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("Testing")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="about-us-content">
                    <div className="small-card-wrapper">
                        <div className="small-card">
                            <div className="small-card-body">
                            <h2 className="small-card-title">Standardized Testing Prep</h2>
                            <p className="smalll-card-description">We teach the SAT, ACT, SSAT, ISEE, etc.  We also provide proctored and timed practice testing.</p>
                            <p className="smalll-card-description">We use a custom grading system so you can see your scores the day of testing.</p>
                            <p className="smalll-card-description">Create an account and sign into our website to see your score breakdown.</p>
                            </div>
                            <button onClick={()=>navigate("/StandardizedTesting")}>Learn more...</button>
                        </div>
                        <div className="small-card">
                            <div className="small-card-body">
                            <h2 className="small-card-title">Tutoring</h2>
                            <p className="smalll-card-description">We teach middle school level through college level courses for almost any subject.</p>
                            <p className="smalll-card-description">Contact us about tutoring for any uncommon subject and we will see how we can accommodate you.</p>
                            <p className="smalll-card-description">We have courses to help build extracurricular activities.</p>
                            </div>
                            <button onClick={()=>navigate("/Courses")}>Check out our course catalog</button>
                        </div>
                        <div className="small-card">
                            <div className="small-card-body">
                            <h2 className="small-card-title">Academic Consulting</h2>
                            <p className="smalll-card-description">Our ultimate goal is to help our students grow as individuals.</p>
                            <p className="smalll-card-description">We help our students pick the most appropriate courses at their schools.  We guide our students through the college admissions process.</p>
                            <p className="smalll-card-description">Schedule a meeting with us to discuss a plan for a journey through high school</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="std-testing-container">
                <div className="std-testing-title-container">
                    <div className="nav-up" onClick={()=>scrollTo("About Us")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="std-testing-title">
                        <img src="writing-clipboard-white.svg" alt="writing" className="std-testing-icon"/>
                        <h1 ref={stdRef}>SAT/ACT Prep</h1>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("Courses")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="std-container">
                    <div className="std-subsection-container">
                        <div className="std-subsection-title-div">
                            <img src="thinking.svg" alt="icon" className="std-icon"/>
                            <span>Philosophy</span>
                        </div>
                        <div className="std-subsection-description-div">
                            <span>We are strong believers in teaching fundamentals. </span>
                            <span>Our nonstandard approach helps our students build great foundations so that they are flexible problem solvers. </span>
                            <span>We want our students to not only answer test questions correctly but also to justify their answers. </span>
                        </div>
                    </div>
                    <div className="std-divider">
                        <div className="orange-std-divider-circle"/>
                        <div className="teal-std-divider-circle"/>
                    </div>
                    <div className="std-subsection-container">
                        {isSmallWidth ? 
                            <div className="std-subsection-title-div">
                                <img src="lecture.svg" alt="icon" className="std-icon"/>
                                <span>Lecture</span>
                            </div> : 
                            <></>
                        }
                        <div className="std-subsection-description-div">
                            <span>→ Core concepts</span>
                            <br/>
                            <span>→ Problem solving techniques</span>
                            <br/>
                            <span>→ Time management strategies</span>
                        </div>
                        {isSmallWidth ? 
                            <></> :
                            <div className="std-subsection-title-div">
                                <span>Lecture</span>
                                <img src="lecture.svg" alt="icon" className="std-icon"/>
                            </div>
                        }                    
                    </div>
                    <div className="std-divider">
                        <div className="orange-std-divider-circle"/>
                        <div className="teal-std-divider-circle"/>
                    </div>
                    <div className="std-subsection-container">
                        <div className="std-subsection-title-div">
                            <img src="homework.svg" alt="icon" className="std-icon"/>
                            <span>Homework</span>
                        </div>
                        <div className="std-subsection-description-div">
                            <span>We assign targeted homework to students. </span>
                            <span>We built a <b>virtual homework platform</b> so that students can do their homework at their convenience as long as they have a device with internet access. </span>
                            <span>Parents with accounts linked to student accounts are able to track student progress with online assignments. </span>
                        </div>
                    </div>
                    <div className="std-divider">
                        <div className="orange-std-divider-circle"/>
                        <div className="teal-std-divider-circle"/>
                    </div>
                    <div className="std-subsection-container">
                        {isSmallWidth ? 
                            <div className="std-subsection-title-div">
                                <img src="computer.svg" alt="icon" className="std-icon"/>
                                <span>Practice Test</span>
                            </div> :
                            <></>
                        }
                        <div className="std-subsection-description-div">
                            <span>We built a virtual test taking platform for <b>Digital SAT</b> and <b>Digital PSAT</b> only. </span>
                            <span>Students may now take practice tests from home. </span>
                            <span>Students may also opt to come in person and take practice tests on our tablets. </span>
                            <br/><br/>
                            <a href="DigitalTestingIntro">More about our Digital Testing Platform...</a>
                            {/* <span onClick={()=>navigate("/DigitalTestingIntro")}>Click to learn more about our Digital Testing Platform...</span> */}
                        </div>
                        {isSmallWidth ? 
                            <></> : 
                            <div className="std-subsection-title-div">
                                <span>Practice Test</span>
                                <img src="computer.svg" alt="icon" className="std-icon"/>
                            </div>
                        }
                    </div>
                    <div className="std-divider">
                        <div className="orange-std-divider-circle"/>
                        <div className="teal-std-divider-circle"/>
                    </div>
                    <div className="std-subsection-container">
                        <div className="std-subsection-title-div">
                            <img src="review.svg" alt="icon" className="std-icon"/>
                            <span>Review</span>
                        </div>
                        <div className="std-subsection-description-div">
                            <span>We teach students test taking tips and tricks. </span>
                            <span>We drill logic flow charts so that students build great problem solving habits.</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="digital-sat-intro-container">
                <div className="digital-sat-intro-title-container">
                    <div className="nav-up" onClick={()=>scrollTo("Testing")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="digital-sat-intro-title">
                        <img src="computer-white.svg" alt="digital" className="computer-icon"/>
                        <h1>Digital SAT</h1>
                    </div>
                    <div className="nav-up" onClick={()=>scrollTo("Courses")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="digital-sat-intro-content">

                </div>
            </div> */}
            <div className="courses-container">
                <div className="courses-title-container">
                    <div className="nav-up" onClick={()=>scrollTo("Testing")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="courses-title">
                        <img src="subjects-white.svg" alt="courses" className="courses-icon"/>
                        <h1 ref={coursesRef}>Courses</h1>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("Contact")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="courses-content">
                    <div className="courses-nav">

                    </div>
                    <div className="courses-wrapper">
                        <div className="course">
                            <img src="science.svg" alt="shp" className="course-icon"/>
                            <div className="course-title">
                                <h2>Columbia SHP</h2>
                            </div>
                            <div className="course-description">
                                <span>Columbia Science Honors Program selects students gifted in STEM in the tri-state area.</span>
                                <span>SHP students get the opportunity to experience college level courses on topics that will not be found in high school.</span>
                                <span>We teach advanced sciences and math so that students are prepared for the entrance exam.</span>
                                <span>We also help students with the application process.</span>
                            </div>
                            {/* <button className="course-learn-more-button"></button> */}
                        </div>
                        <div className="course">
                            <img src="math.svg" alt="amc" className="course-icon"/>
                            <div className="course-title">
                                <h2>AMC</h2>
                            </div>
                            <div className="course-description">
                                <span>American Math Competition is the official test in US to scout math talented students.</span>
                                <span>The competition is hosted every year for 3 age groups:</span>
                                <ul>
                                    <li>AMC8: Grades 8 and lower</li>
                                    <li>AMC10: Grades 10 and lower</li>
                                    <li>AMC12: Grades 12 and lower</li>
                                </ul>
                                <span>We teach high level math concepts and problem solving.</span>
                            </div>
                            {/* <button className="course-learn-more-button"></button> */}
                        </div>
                        <div className="course">
                            <img src="coding.svg" alt="coding" className="course-icon"/>
                            <div className="course-title">
                                <h2>USACO</h2>
                            </div>
                            <div className="course-description">
                                <span>USA Computing Olympiad is the official test in US to scout programming talented students.</span>
                                <span>The competition is open to all ages. The competition tests students in problem solving, data structures, and time complexities</span>
                                <span>The test is hosted online every month of the Winter Season.</span>
                                <span>We teach students advanced data structures and common competitive programming algorithms.</span>
                            </div>
                            {/* <button className="course-learn-more-button"></button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div ref={contactRef}><Contacts/></div>
          </div>
      </>
  )
}