import React, {useEffect, useState, useRef} from 'react'
import './StyleComponents/DigitalTestingIntro.css'
import { useTimer } from 'react-timer-hook'
import loremipsum from '../Lorem'
import Contacts from './Components/Contacts'

export default function DigitalTestingIntro(){
    const canvasRef = useRef()
    const contextRef = useRef()
    const [hoveringContact, setHoveringContact] = useState(false)
    const [isDrawing, setIsDrawing] = useState(false)
    const [isBlinking, setIsBlinking] = useState(false)
    const [currQuestion, setCurrQuestion] = useState(0)
    const [flagColor, setFlagColor] = useState(true)
    const [qNum, setQNum] = useState(1)
    const [height, setHeight] = useState("5rem")
    const {seconds, minutes, restart} = useTimer({ onExpire: ()=>{
        setIsBlinking(true)
    }})

    useEffect(()=>{
        setIsBlinking(false)
        const time = new Date()
        time.setSeconds(time.getSeconds()+2*60)
        restart(time)
        
        const context = canvasRef.current.getContext("2d")
        context.strokeStyle = "yellow"
        context.lineWidth = 10
        context.globalAlpha = 0.1
        context.globalCompositeOperation = "xor"
        contextRef.current = context

        const questionTimer = setInterval(()=>{
            setCurrQuestion((prevQuestion)=>(prevQuestion+1)%9)
        }, 5000)
        const qTimer = setInterval(()=>{
            var randNum = Math.floor(Math.random()*28+1)
            while (randNum === qNum){
                randNum = Math.floor(Math.random()*28+1)
            }
            setQNum(randNum)
            setHeight(`${Math.random()*7+3}rem`)
        },3000)
        const changeFlag = setInterval(()=>{
            setFlagColor((prev)=>(!prev))
        }, 3000)

        return () => {
            clearInterval(questionTimer)
            clearInterval(qTimer)
            clearInterval(changeFlag)
        }
    },[])
    useEffect(()=>{   
        const options = {
            rootMargin: '0px',
            threshold: 0.1
        }
  
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)
  
        const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
        hiddenElements.forEach((element)=>observer.observe(element))
  
        return () => {
            observer.disconnect()
        }
    },[])

    const changeQuestion = (n) => {setCurrQuestion(n)}
    const getQuestion = () => {
        return(
            <>
                <span className="TD-question-number">Q{currQuestion+1}.</span>
                <br/>
                <span className="TD-question-description">{loremipsum.split(". ").slice(currQuestion*4,currQuestion*4+4).join(". ")+"."}</span>
            </>
        )
    }

    const startDrawing = ({nativeEvent}) => {
        const canvas = canvasRef.current
        const rect = canvas.getBoundingClientRect()

        const scaleX = canvas.width / rect.width
        const scaleY = canvas.height / rect.height
        const offsetX = ((nativeEvent.touches ? nativeEvent.touches[0].clientX : nativeEvent.clientX) - rect.left) * scaleX
        const offsetY = ((nativeEvent.touches ? nativeEvent.touches[0].clientY : nativeEvent.clientY) - rect.top) * scaleY

        contextRef.current.beginPath()
        contextRef.current.moveTo(offsetX, offsetY)
        setIsDrawing(true)
    }

    const finishDrawing = () => {
        contextRef.current.closePath()
        setIsDrawing(false)
    }

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d")
        context.clearRect(0, 0, canvas.width, canvas.height)
    }

    const draw = ({nativeEvent}) => {
        if (!isDrawing) return
        const canvas = canvasRef.current
        const rect = canvas.getBoundingClientRect()

        const scaleX = canvas.width / rect.width
        const scaleY = canvas.height / rect.height
        const offsetX = ((nativeEvent.touches ? nativeEvent.touches[0].clientX : nativeEvent.clientX) - rect.left) * scaleX
        const offsetY = ((nativeEvent.touches ? nativeEvent.touches[0].clientY : nativeEvent.clientY) - rect.top) * scaleY

        contextRef.current.lineTo(offsetX, offsetY)
        contextRef.current.stroke()
    }    

    return(
        <>
            <span className="DT-big-title">Introducing Our New Digital Testing Platform</span>
            <div className="DT-introduction-container">
                <div className="DT-introduction-text">
                    <span>Meet out our new Digital Testing Platform designed to simulate real test taking conditions. </span>
                    <span>Our platform is focused on training students for the Digital SAT. </span>
                    <br/>
                    <button 
                        className="contact-arrow-button" 
                        onPointerOver={()=>setHoveringContact(true)} 
                        onPointerLeave={()=>setHoveringContact(false)}
                        onClick={()=>window.scrollTo({top: document.body.scrollHeight, behavior: "smooth"})}
                    >
                        <span>Contact Us</span>
                        <div className="contact-arrow-img-container">
                            <img src="triple-arrow.svg" alt="arr" className={`contact-arrow-black${hoveringContact ? " invisible" : ""}`}/>
                            <svg className={`contact-arrow-white${hoveringContact ? " visible" : ""}`} viewBox="0 0 100 100">
                                <path className="first-arrow" d="M7.75 76 20.25 76 41.25 48 20.25 20 7.75 20 28.75 48 7.75 76Z" fill="#FFFFFF"/>
                                <path className="second-arrow" d="M44.25 20 31.75 20 52.75 48 31.75 76 44.25 76 65.25 48 44.25 20Z" fill="#FFFFFF"/>
                                <path className="third-arrow" d="M68.25 20 55.75 20 76.75 48 55.75 76 68.25 76 89.25 48 68.25 20Z" fill="#FFFFFF"/>
                            </svg>
                        </div>
                    </button>
                </div>
                <div className="DT-introduction-animation">
                    <div className="DT-sample-container">
                        <div className="DT-sample-question">
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <span>{`Q${qNum}.`}</span>
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    strokeWidth="1" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round"
                                    style={{width:"0.75rem",height:"0.75rem"}}
                                >
                                    <path d="M6 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v20l-6-3-6 3V2z"/>
                                </svg>
                            </div>
                            <div className="DT-sample-q" style={{height:(height)}}/>
                        </div>
                        <div className="DT-sample-mc">
                            <form>
                                <input type="radio" name="mc"/><label>A</label><br/>
                                <input type="radio" name="mc"/><label>B</label><br/>
                                <input type="radio" name="mc"/><label>C</label><br/>
                                <input type="radio" name="mc"/><label>D</label>
                            </form>
                            <button>Prev Question</button>
                            <button>Next Question</button>
                            <br/>
                            <button>Submit</button>
                        </div>
                        <div className="DT-sample-question-grid">
                            {Array.from({ length: 28 }, (_, i) => i + 1).map((n)=>{
                                return <div style={{color:(qNum===n ? "blue" : "black"),fontWeight:(qNum===n ? "bold" : "")}} key={`${n}dt`}>{`${n}. `}</div>
                            })}
                        </div>
                    </div>
                    <div className="DT-faded-edge"/>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="DT-introduction-container">
                <div className="DT-title-container">
                    <span className="DT-title">Features</span>
                </div>
                <div className="DT-introduction-subsection-container">
                    <span>Our Digital Testing Platform is now supporting Digital SAT and Digital PSAT. </span>
                    <span>We built a server to automate grading and store test scores. </span>
                    <span>We also built a platform on our website to allow our students to test from home. </span>
                    <span>Features of our platform include...</span>
                </div>
            </div>
            <div className="DT-intro-card-container">
                <div className="DT-intro-card-grid">
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <img src="computer.svg" alt="comp" className="DT-intro-computer-img"/>
                            <div style={{overflow:"hidden"}}>
                                <svg className="animated-dashed-lines">
                                    <line x1="-10px" y1="25%" x2="100%" y2="25%" stroke="black" strokeWidth="2" strokeDasharray="5,5">
                                        <animate attributeName="x1" from="-10px" to="0" dur="1s" repeatCount="indefinite" />
                                    </line>
                                    <line x1="0%" y1="75%" x2="120%" y2="75%" stroke="black" strokeWidth="2" strokeDasharray="5,5">
                                        <animate attributeName="x1" from="0%" to="-10px" dur="1s" repeatCount="indefinite" />
                                    </line>
                                </svg>
                            </div>
                            <img src="server.svg" alt="comp" className="DT-intro-computer-img"/>
                        </div>
                        <h3>Automation</h3>
                        <div style={{textAlign:"left"}}>
                            <span>We created our own server and client so that we can automate grading. </span>
                            <span>Students and parents will be able to see scores immediately on their accounts after test submission.</span>
                        </div>
                    </div>
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <img src="computer.svg" alt="comp" className="DT-intro-computer-img"/>
                            <span className={`DT-timer${isBlinking ? " blinking" : ""}`}><span>{minutes}</span>:<span>{seconds < 10 ? ("0"+seconds) : seconds}</span></span>
                        </div>
                        <h3>Built-in Timer</h3>
                        <div style={{textAlign:"left"}}>
                            <span>Our digital testing platform has an automatic running timer. </span>
                            <span>The timer runs continuously and progresses to the next module when time runs out. </span>
                            <span>Breaks also run on the same continuous timer. </span>
                        </div>
                        
                    </div>
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <div className="TD-intro-question">
                                {getQuestion()}
                            </div>
                            <div className="TD-intro-question-grid">
                                {["1. A","2. B","3.","4.","5. C","6. A","7. D","8.","9."].map((n,i)=>{
                                    return <div style={{cursor:"pointer",fontWeight:currQuestion===i ? "bold" : ""}} onClick={()=>changeQuestion(i)} key={`TD-question-grid ${n}`}>{n}</div>
                                })}
                            </div>
                        </div>
                        <h3>Navigation</h3>
                        <div style={{textAlign:"left"}}>
                            <span>Our digital testing interface is designed to be easy to understand. </span>
                            <span>Students can jump between questions at ease and track their answers with just a glance. </span>
                        </div>
                    </div>
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <div className="TD-people-container">
                                <div className="TD-person-container">
                                    <div className="TD-green-dot"/>
                                    <img src="person.svg" alt="person" className="TD-person-icon"/>
                                </div>
                                <div className="TD-person-container" style={{top:"2rem"}}>
                                    <div className="TD-green-dot"/>
                                    <img src="person.svg" alt="person" className="TD-person-icon"/>
                                </div>
                                <div className="TD-person-container">
                                    <div className="TD-red-dot"/>
                                    <img src="person.svg" alt="person" className="TD-person-icon"/>
                                </div>
                                
                            </div>
                        </div>
                        <h3>Cheat Detection</h3>
                        <div style={{textAlign:"left"}}>
                            <span>We try our best to prevent cheating with test security and detection of suspicious activity.</span>
                        </div>
                    </div>
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <img src="https://amplify-amplify09bc566f9d994-staging-234225-deployment.s3.amazonaws.com/Homework/Math/inferentialstatisticsmed11.png" alt="question" className="TD-question-image"/>
                            <canvas
                                ref={canvasRef}
                                style={{width:"100%",height:"6rem",border:"1px solid black",zIndex:"2", touchAction:"none"}}
                                onMouseDown={startDrawing}
                                onTouchStart={startDrawing}
                                onMouseUp={finishDrawing}
                                onTouchEnd={finishDrawing}
                                onMouseMove={draw}
                                onTouchMove={draw}
                                onMouseOut={finishDrawing}
                                onTouchCancel={finishDrawing}
                            />
                        </div>
                        <button onClick={clearCanvas}>Clear Drawings</button>
                        <h3>Note Taking</h3>
                        <div style={{textAlign:"left"}}>
                            <span>Draw on the sample question above.</span>
                        </div>
                    </div>
                    <div className="DT-intro-card">
                        <div className="DT-intro-animation-container">
                            <div className="TD-intro-question">
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                    <span>Q4.</span>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor" 
                                        strokeWidth="1" 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round"
                                        style={{
                                            width:"1rem",
                                            height:"1rem",
                                            fill:"red",
                                            fillOpacity:(flagColor ? "1" : "0"),
                                            strokeOpacity:"1",
                                            transition:"700ms ease"}}
                                    >
                                        <path d="M6 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v20l-6-3-6 3V2z"/>
                                    </svg>
                                </div>
                                <div className="DT-sample-q" style={{height:"4rem",borderRadius:"0.25rem",marginTop:"0.25rem"}}/>
                            </div>
                            <div className="TD-intro-question-grid">
                                <div>1. D</div>
                                <div>2. A</div>
                                <div>3. C</div>
                                <div style={{color:(flagColor ? "red" : ""),transition:"700ms ease", fontWeight:"bold"}}>4. B</div>
                                <div>5. </div>
                                <div>6. </div>
                                <div>7. </div>
                            </div>
                        </div>
                        <h3>Question Flagging</h3>
                        <div style={{textAlign:"left"}}>
                            <span>Students can flag questions to track questions they want to come back to. </span>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            {/* <img src="DigitalTestExample.png" alt="digital-test" className="DT-example-image"/> */}
            <Contacts/>
        </>
    )
}