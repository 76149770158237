import React, {useState} from 'react'
import axios from 'axios'

export default function ScheduleLesson(){
    const [students, setStudents] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const [assignedStudents, setAssignedStudents] = useState([])
    const [tests, setTests] = useState([])
    const [test, setTest] = useState('')
    const [testSecretCode, setTestSecretCode] = useState('')

    async function getStudents(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/allStudents', {
                headers: {"JWT":("Bearer "+localStorage.getItem('user'))}
            })
            response.data.sort((a,b)=>(a.first_name+a.last_name).localeCompare(b.first_name+b.last_name))
            setStudents(response.data)
        } catch (err){
            console.log(err)
        }
    }
    //todo searchable dropdown
    async function getTests(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/allDigitalSAT', {
                headers: {"JWT": ("Bearer "+localStorage.getItem('user'))}
            })
            response.data.sort((a,b)=>a.test_id.localeCompare(b.test_id))
            setTests(response.data)
        } catch(err){
            console.log(err)
        }
    }

    async function assign(){
        if (testSecretCode === ""){
            alert("Test code secret cannot be blank")
            return
        }
        if (test === "" || test === "Choose test..."){
            alert("Must select a test to assign")
            return
        }
        if (assignedStudents.length === 0){
            alert("No students were assigned a test")
            return
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/digitalSAT/assign', {
                "JWT": ("Bearer " + localStorage.getItem('user')),
                "student_emails": assignedStudents.map(s => s.email).join(","),
                "test_id": test,
                "secret_code": testSecretCode
            })
            if (response.data === 5) alert("Test not found")
            if (response.data === 10) alert("You do not have permission to assign tests")
            if (response.data === 0) alert("Test successfully assigned to students")
        } catch(err){
            console.log(err)
        }
    }

    const addStudent = (student) => {
        for (var i = 0; i < assignedStudents.length; i++){
            if (assignedStudents[i].email === student.email) return
        }
        assignedStudents.push(student)
        setAssignedStudents([...assignedStudents])
    }

    const removeStudent = (student) => {
        for (var i = 0; i < assignedStudents.length; i++){
            if (assignedStudents[i].email === student.email){
                assignedStudents.splice(i,1)
                setAssignedStudents([...assignedStudents])
                return
            }
        }
    }

    return(
        <>
            <p style={{color: "darkred"}}>Use this query to assign a Digital SAT to students before they take the online test.</p>
            <button onClick={getStudents}>Get Students</button>
            <div className="small-card-wrapper">
                <div>  
                    <label className="filter-label">Search: </label>
                    <input className="filter-bar" onChange={(e)=>setSearch(e.target.value)}/>
                    <ul className="student-ul">
                        {students.filter((student) => {
                            return search === '' ? 
                                student :
                                (student.first_name+" "+student.last_name+" "+student.email).toLowerCase().includes(search)
                        }).map((student, i) => {
                            return <li key={i} onClick={()=>addStudent(student)}>{student.first_name+" "+student.last_name+" ("+student.email+")"}</li>
                        })}
                    </ul>
                </div>
                <div>
                    <label className="filter-label">Search: </label>
                    <input className="filter-bar" onChange={(e)=>setSearch2(e.target.value)}/>
                    <ul className="student-ul">
                        {assignedStudents.filter((student) => {
                            return search2 === '' ? 
                                student :
                                (student.first_name+" "+student.last_name+" "+student.email).toLowerCase().includes(search2)
                        }).map((student, i) => {
                            return <li key={i} onClick={()=>removeStudent(student)}>{student.first_name+" "+student.last_name+" ("+student.email+")"}</li>
                        })}
                    </ul>
                </div>
            </div>
            <button onClick={getTests}>Get Tests</button>
            {tests.length === 0 ? <></> : 
                <div>
                    Select Test: 
                    <select onChange={(e)=>setTest(e.target.value)}> 
                        <option>Choose test...</option>
                        {tests.map((test,i)=>{
                            return <option key={"test"+i}>{test.test_id}</option>
                        })}
                    </select>
                </div>
            }
            <br/>
            <br/>
            <label>Test Secret Code: </label><input onChange={(e)=>setTestSecretCode(e.target.value)}/>
            <br/>
            <br/>
            <button onClick={assign}>Assign</button>
        </>
    )
}