import React from 'react'
import './ScrollUpButton.css'

export default function ScrollUpButton(){
    return(
        <>
            <div className="scroll-button-container" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
                <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="scroll-up-fixed"/>
            </div>
        </>
    )
}