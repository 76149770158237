import React, { useState, useEffect, useRef } from 'react'
import './AdminTestingPage.css'

export default function AdminTestingPage() {
  return(
    <>
      <br/>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M6 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v20l-6-3-6 3V2z"/>
      </svg>
    </>
  )
}
