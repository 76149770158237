import React, {useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom';
import './AdminTestingPage.css'
import './StyleComponents/Summer2023.css'
import './StyleComponents/HomeNav.css'
import Contacts from './Components/Contacts'
import Calendar from './Components/CalendarSummer2024'
import CodeDemo from './StyleComponents/CodeDemo'
import './Components/SubjectList.css'

export default function Summer2023(){
    const satRef = useRef()
    const coursesRef = useRef()
    const highschoolRef = useRef()
    const middleschoolRef = useRef()
    const scheduleRef = useRef()
    const contactRef = useRef()
    const digitalRef = useRef()
    const mathRef = useRef()
    const englishRef = useRef()
    const pythonRef = useRef()
    const debateRef = useRef()
    const artRef = useRef()
    const middleRef = useRef()
    const eightRef = useRef()
    const ninthRef = useRef()
    const tenthRef = useRef()
    const highRef = useRef()
    let interval = null
    const alphabet = "QWERTYUIOPLKJHGFDSAZXCVBNM"
    const navigate = useNavigate();

    useEffect(()=>{
        if (digitalRef){
            digitalRef.current.onpointerover = (e) => {
                var count = 0

                clearInterval(interval)
                const toScramble = "Digital SAT".split("")

                interval = setInterval(()=>{
                    e.target.innerText = toScramble.map((letter, index) => {
                        if (index < count) return toScramble[index]
                        return alphabet[Math.floor(Math.random()*26)]
                    }).join("")
                    
                    if (count >= toScramble.length) clearInterval(interval)

                    count += 1/3
                }, 30)
            }
        }
    },[])

    useEffect(()=>{   
        const options = {
            rootMargin: '0px',
            threshold: 0.1
        }
  
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)
  
        const hiddenElements = document.querySelectorAll('.small-card, .contacts-container, .std-testing-trapezoid, .course')
        hiddenElements.forEach((element)=>observer.observe(element))
  
        return () => {
            observer.disconnect()
        }
    },[])

    function scrollTo(to){
        var reference = null
        if (to === "SAT") reference = satRef
        else if (to === "HighSchool") reference = highschoolRef
        else if (to === "MiddleSchool") reference = middleschoolRef
        else if (to === "Other Courses") reference = coursesRef
        else if (to === "Schedule") reference = scheduleRef
        else if (to === "Contact") reference = contactRef
        else if (to === "Digital SAT") reference = digitalRef
        else if (to === "Math") reference = mathRef
        else if (to === "English") reference = englishRef
        else if (to === "Python") reference = pythonRef
        else if (to === "Debate") reference = debateRef
        else if (to === "Art") reference = artRef
        else if (to === "Middle") reference = middleRef
        else if (to === "8") reference = eightRef
        else if (to === "9") reference = ninthRef
        else if (to === "10") reference = tenthRef
        else if (to === "High") reference = highRef
        else reference = satRef
        const position = reference.current.getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.1
        window.scrollTo({top: position, behavior: "smooth"})
    }

    return(
        <>
            {/* <div className="colors"></div>
            <div className="colors2"></div> */}
            <div className="summer-title">
                {/* <img src="popsicle.svg" alt="pop" className="popsicle-left"/> */}
                <div className="summer-title-text">
                    <img src="palm-trees.svg" alt="palm" className="palm-trees-background"/>
                    <span>Tenafly Prep Academy's</span>
                    <span>Summer 2024 Program</span>
                </div>
                <img src="palm-trees.svg" alt="palm" className="palm-trees"/>
                {/* <img src="popsicle.svg" alt="pop" className="popsicle-right"/> */}
            </div>
            <div className="summer-date">
                <span>Date: July 8<sup>th</sup> ~ August 15<sup>th</sup></span>
                <div className="calendar-wrapper">
                    <Calendar month="July"/>
                    <Calendar month="August"/>
                </div>
            </div>
            <div className="summer-nav-container">
                <div className="summer-nav-text">
                    <div className="summer-nav-box">
                        <div className="summer-spinner"/>
                        <span onClick={()=>scrollTo("SAT")}><img src="computer.svg" alt="about" className="summer-nav-icon"/>Digital SAT</span>
                    </div>
                    <div className="summer-nav-box">
                        <div className="summer-spinner"/>
                        <span onClick={()=>scrollTo("HighSchool")}><img src="highschool.svg" alt="about" className="summer-nav-icon"/>High School</span>
                    </div>
                    <div className="summer-nav-box">
                        <div className="summer-spinner"/>
                        <span onClick={()=>scrollTo("MiddleSchool")}><img src="middleschool.svg" alt="sat/act" className="summer-nav-icon"/>Middle School</span>
                    </div>
                    <div className="summer-nav-box">
                        <div className="summer-spinner"/>
                        <span onClick={()=>scrollTo("Schedule")}><img src="calendar.svg" alt="subjects" className="summer-nav-icon"/>Schedule</span>
                    </div>
                    <div className="summer-nav-box">
                        <div className="summer-spinner"/>
                        <span onClick={()=>scrollTo("Contact")}><img src="contacts.svg" alt="contacts" className="summer-nav-icon"/>Contacts</span>
                    </div>
                </div>
            </div>
            <div className="summer-content-container">
                <div className="summer-content-title">
                    <div className="nav-up" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="summer-content-title-text">
                        <img src="computer-white.svg" alt="clipboard" className="summer-content-title-icon"/>
                        <h2 ref={satRef}>Digital SAT</h2>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("HighSchool")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="summer-content">
                    <div className="sat-content">
                        <div className="summer-digital-sat-container">
                            <h2 ref={digitalRef}>Digital SAT</h2>
                            <div className="digital-sat-descsription">
                                <span>The new Digital SAT has already arrived!</span>
                                <span>We built a <b>customized system</b> from scratch for our students to practice the Digital SAT.</span>
                                <span style={{marginBottom: '1rem'}}>Students can practice exactly how the Digital SAT will be administered even from home.</span>
                            </div>
                            <div className="sat-steps">
                                <div className="sat-step">
                                    <img src="lecture.svg" alt="lecture" className="sat-icon"/>
                                    <h3>1. Lecture</h3>
                                    <ul>
                                        <li>Key Concepts</li>
                                        <li>Test Taking Strategies</li>
                                        <li>Time Management</li>
                                    </ul>
                                </div>
                                <div className="sat-step">
                                    <img src="homework.svg" alt="homework" className="sat-icon"/>
                                    <h3>2. Homework</h3>
                                    <ul>
                                        <li>Digital Assignments</li>
                                        <li>Vocab</li>
                                    </ul>
                                </div>
                                <div className="sat-step">
                                    <img src="computer.svg" alt="computer" className="sat-icon"/>
                                    <h3>3. Practice Test</h3>
                                    <ul>
                                        <li>Timed Digital Tests</li>
                                        <li>Apply Knowledge</li>
                                        <li>Immediate Score Breakdowns</li>
                                    </ul>
                                </div>
                                <div className="sat-step">
                                    <img src="review.svg" alt="review" className="sat-icon"/>
                                    <h3>4. Review</h3>
                                    <ul>
                                        <li>Mistake Revision</li>
                                        <li>Login for Testing History</li>
                                    </ul>
                                </div>
                            </div>
                            <button className="summer-button" onClick={()=>navigate("/DigitalSAT")}>Learn more about Digital SAT</button>
                            <div style={{margin:"1rem"}}>
                                <div>
                                    <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>We are offering 3 different Digital SAT programs depending on your needs</span>
                                </div>
                            </div>
                            <div className="sat-steps">
                                <div className="sat-step">
                                    <img src="lecture-solo.svg" alt="lecture" className="sat-icon"/>
                                    <h3>1. In Person Lectures</h3>
                                    <ul>
                                        <li>Math + English</li>
                                        <li>Vocab Workshop</li>
                                        <li>Full Practice Tests</li>
                                        <li>Monday ~ Thursday</li>
                                        <li>Morning ~ Afternoon</li>
                                    </ul>
                                </div>
                                <div className="sat-step">
                                    <img src="online.svg" alt="homework" className="sat-icon"/>
                                    <h3>2. Virtual Lectures</h3>
                                    <ul>
                                        <li>English ONLY</li>
                                        <li>Vocab Workshop</li>
                                        <li>Tue and Thu ONLY</li>
                                        <li>Evening</li>
                                    </ul>
                                </div>
                                <div className="sat-step">
                                    <img src="student.svg" alt="computer" className="sat-icon"/>
                                    <h3>3. Individual Tutoring</h3>
                                    <ul>
                                        <li>SAT or ACT</li>
                                        <li>Full Practice Test + Review</li>
                                        <li>Flexible Scheduling</li>
                                    </ul>
                                </div>
                            </div>
                            <button className="summer-button" onClick={()=>scrollTo("Schedule")}>Click for Detailed Schedule</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summer-content-container">
                <div className="summer-content-title">
                    <div className="nav-up" onClick={()=>scrollTo("SAT")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="summer-content-title-text">
                        <img src="highschool-white.svg" alt="clipboard" className="summer-content-title-icon"/>
                        <h2 ref={highschoolRef}>High School</h2>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("MiddleSchool")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div style={{margin:"1rem"}}><span style={{fontSize:"1.5rem",textDecoration:"underline"}}>Tutoring Subjects</span></div>
                <div className="subjects-grid-container">
                    <div className="subjects-grid-container-div"><img src="math.svg" alt="math"/>AP Calculus</div>
                    <div className="subjects-grid-container-div"><img src="atom.svg" alt="math"/>AP Physics 1,2,C</div>
                    <div className="subjects-grid-container-div"><img src="chemistry.svg" alt="math"/>AP Chemistry</div>
                    <div className="subjects-grid-container-div"><img src="statistics.svg" alt="math"/>AP Statistics</div>
                    <div className="subjects-grid-container-div"><img src="math.svg" alt="math"/>Precalculus</div>
                    <div className="subjects-grid-container-div"><img src="math.svg" alt="math"/>Algebra 2</div>
                    <div className="subjects-grid-container-div"><img src="math.svg" alt="math"/>Algebra 1</div>
                    <div className="subjects-grid-container-div"><img src="atom.svg" alt="math"/>Physics</div>
                    <div className="subjects-grid-container-div"><img src="chemistry.svg" alt="math"/>Chemistry</div>
                    <div className="subjects-grid-container-div"><img src="biology.svg" alt="math"/>Biology</div>
                    <div className="subjects-grid-container-div"><img src="coding.svg" alt="math"/>Python, Java, C#</div>
                </div>
                <div style={{color:"darkred",marginBottom:"2rem"}}>*Flexible Scheduling*</div>
                <div style={{margin:"1rem"}}><span style={{fontSize:"1.5rem",textDecoration:"underline"}}>Extracurriculars</span></div>
                <div className="subjects-grid-container">
                    <div className="subjects-grid-container-div"><img src="books.svg" alt="math"/>Research</div>
                    <div className="subjects-grid-container-div"><img src="writing.svg" alt="math"/>Writing</div>
                    <div className="subjects-grid-container-div"><img src="writing.svg" alt="math"/>AP Writing</div>
                    <div className="subjects-grid-container-div"><img src="coding.svg" alt="math"/>USACO</div>
                    <div className="subjects-grid-container-div"><img src="math.svg" alt="math"/>AMC10</div>
                </div>
                <div style={{color:"darkred"}}>*Flexible Scheduling*</div>
            </div>
            <div className="summer-content-container">
                <div className="summer-content-title">
                    <div className="nav-up" onClick={()=>scrollTo("HighSchool")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="summer-content-title-text">
                        <img src="middleschool-white.svg" alt="clipboard" className="summer-content-title-icon"/>
                        <h2 ref={middleschoolRef}>Middle School</h2>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("Schedule")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="summer-content">
                    <div className="summer-nav-container">
                        <div className="summer-nav-text">
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("Math")}><img src="math.svg" alt="about" className="summer-nav-icon"/>Math</span>
                            </div>
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("English")}><img src="reading.svg" alt="about" className="summer-nav-icon"/>English</span>
                            </div>
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("Python")}><img src="coding.svg" alt="about" className="summer-nav-icon"/>Python Coding</span>
                            </div>
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("Debate")}><img src="robot.svg" alt="about" className="summer-nav-icon"/>Robotics</span>
                            </div>
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("Art")}><img src="art.svg" alt="about" className="summer-nav-icon"/>Art</span>
                            </div>
                        </div>
                    </div>
                    <span style={{color: 'darkred', paddingBottom:'1rem'}}>*6<sup>th</sup>/7<sup>th</sup> Grade*</span>
                    <div ref={mathRef} className="summer-course-container">
                        <h2>Math</h2>
                        <span>Students will be learning and drilling to remember core math concepts for Algebra/Geometry.</span>
                        <div className="summer-divider"/>
                    </div>
                    <div ref={englishRef} className="summer-course-container">
                        <h2>English</h2>
                        <span>Students will be honing their reading and writing skills with our activities: </span>
                        <ul>
                            <li>Book Club</li>
                            <li>Expository Writing</li>
                        </ul>
                        <div className="summer-divider"/>
                    </div>
                    <div ref={pythonRef} className="summer-course-container">
                        <h2>Python Coding (Elective)</h2>
                        <span>Students will learn fundamental programming topics such as:</span>
                        <ul>
                            <li>For and While loops</li>
                            <li>Creating functions</li>
                            <li>Using basic data structures</li>
                            <li>Object Oriented Programming</li>
                        </ul>
                        <CodeDemo/>
                        <div className="summer-divider"/>
                    </div>
                    <div ref={debateRef} className="summer-course-container">
                        <h2>Robotics (Elective)</h2>
                        <span>Students learn to code and problem solve. Students will ultimately participate in regional robotics competitions.</span>
                        <div className="summer-divider"/>
                    </div>
                    <div ref={artRef} className="summer-course-container">
                        <h2>Art (Elective)</h2>
                        <span>Students will learn fundamental techniques in any artistic style they wish.</span>
                    </div>
                </div>
            </div>
            <div className="summer-content-container">
                <div className="summer-content-title">
                    <div className="nav-up" onClick={()=>scrollTo("MiddleSchool")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-up" className="nav-up-arrow"/>
                    </div>
                    <div className="summer-content-title-text">
                        <img src="calendar-white.svg" alt="clipboard" className="summer-content-title-icon"/>
                        <h2 ref={scheduleRef}>Schedule</h2>
                    </div>
                    <div className="nav-down" onClick={()=>scrollTo("Contact")}>
                        <span>Scroll</span>
                        <img src="scroll-up-arrow-white.svg" alt="scroll-down" className="nav-down-arrow"/>
                    </div>
                </div>
                <div className="summer-content">
                    <div className="summer-nav-container">
                        <div className="summer-nav-text">
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("8")}>High School</span>
                            </div>
                            <div className="summer-nav-box">
                                <div className="summer-spinner"/>
                                <span onClick={()=>scrollTo("Middle")}>6th & 7th Grade</span>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="summer-course-schedules">
                        <div ref={ninthRef} className="summer-schedule">
                            <div className="summer-schedule-wrapper">
                                <div className="summer-schedule-time-table">
                                    <h2>High School</h2>
                                    <h3>SAT Program (Monday ~ Thursday)</h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>9:00 AM - 10:30 AM: </td>
                                                <td>Math/English</td>
                                            </tr>
                                            <tr>
                                                <td>10:30 AM - 12:00 PM:&nbsp;</td>
                                                <td>English/Math</td>
                                            </tr>
                                            <tr>
                                                <td>1:00 PM - 2:30 PM:</td>
                                                <td>Vocab Workshop</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span>Students will be assigned a <b>full practice test</b> through our <b>online</b> website application every weekend.</span>
                                    <h3>SAT/ACT Private Tutoring</h3>
                                    <span>Scheduling for Private Tutoring is flexible (Monday through Friday). Contact for more details.</span>
                                    <span>We offer digital testing for Mock Digital SAT.</span>
                                    <span>Unfortunately, we are unable to offer digital testing for the ACT and must be done in person.</span>
                                    <h3>Group and Private Tutoring</h3>
                                    <span>We offer tutoring for every STEM subject and more. Contact us if you would like to inquire on course availability and/or scheduling.</span>
                                    <div className="summer-button-container">
                                        <button className="summer-button" onClick={()=>scrollTo("SAT")}>SAT Course Details</button>
                                        <button className="summer-button" onClick={()=>scrollTo("Digital SAT")}>Digital SAT Details</button>
                                        <button className="summer-button" onClick={()=>scrollTo("HighSchool")}>Offered Subjects</button>
                                    </div>
                                </div>
                                <div className="summer-schedule-price">
                                    <h2>Pricing</h2>
                                    <div className="summer-button-container">
                                        <button className="summer-button" onClick={()=>scrollTo("Contact")}>Contact Us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="summer-divider"/>
                        </div>
                        <div ref={middleRef} className="summer-schedule">
                            <div className="summer-schedule-wrapper">
                                <div className="summer-schedule-time-table">
                                    <h2>6th & 7th Grade</h2>
                                    <span>Possible schedules for 6<sup>th</sup>/7<sup>th</sup> graders:</span>
                                    <h3>Full Day (Monday ~ Thursday)</h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>9:00AM - 10:25AM:</td>
                                                <td>Math</td>
                                            </tr>
                                            <tr>
                                                <td>10:35AM - 12:00PM:&nbsp;</td>
                                                <td>English</td>
                                            </tr>
                                            <tr>
                                                <td>1:00PM - 3:00PM:</td>
                                                <td>Elective</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h3>Half Day (Monday ~ Thursday)</h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>9:00AM - 10:25AM:</td>
                                                <td>Math</td>
                                            </tr>
                                            <tr>
                                                <td>10:35AM - 12:00PM:&nbsp;</td>
                                                <td>English</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="summer-button-container">
                                        <button className="summer-button" onClick={()=>scrollTo("MiddleSchool")}>View Courses</button>
                                        <button className="summer-button" onClick={()=>scrollTo("Python")}>View Electives</button>
                                    </div>
                                </div>
                                <div className="summer-schedule-price">
                                    <h2>Pricing</h2>
                                    <div className="summer-button-container">
                                        <button className="summer-button" onClick={()=>scrollTo("Contact")}>Contact Us</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={contactRef}><Contacts/></div>
        </>
    )
}