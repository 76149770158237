import React, {useState} from 'react'
import axios from 'axios'

export default function DeleteHomeworkBundle(){
    const [name, setName] = useState('')
    const [name2, setName2] = useState('')
    
    async function deleteHomeworkBundle(){
        try{
            if (name !== name2){
                alert("Names do not match")
                return
            }
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/homework/deletebundle",{
                "JWT": `Bearer ${localStorage.getItem('user')}`,
                "homework_bundle_id": name
            })
            if (response.data === 0) alert("Delete Successful")
            else if (response.data === 1) alert("Homework bundle not found.")
            console.log(response.data)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }
    return(
        <>
            <ul className="admin-forms">
                <li><label htmlFor="id">Bundle Name: </label><input onChange={(e)=>setName(e.target.value)} id="id"/></li>
                <li><label htmlFor="id2">Bundle Name confirmation: </label><input onChange={(e)=>setName2(e.target.value)} id="id2"/></li>
            </ul>
            <button onClick={()=>deleteHomeworkBundle()}>Delete Homework Bundle</button>
        </>
    )
}