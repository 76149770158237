import React, {useRef, useEffect} from 'react'
import './StyleComponents/DigitalSATIntro.css'
import Contacts from './Components/Contacts'

export default function DigitalSAT(){
    const summaryRef = useRef()
    const digitalTestingRef = useRef()
    const oldVSNewRef = useRef()
    const quickComparisonRef = useRef()
    const adaptiveTestingRef = useRef()
    const paperlessRef = useRef()

    function scrollTo(to){
        var target = null;
        if (to === "Summary") target = summaryRef
        else if (to === "DigitalTesting") target = digitalTestingRef
        else if (to === "OldVSNew") target = oldVSNewRef
        else if (to === "QuickComparison") target = quickComparisonRef
        else if (to === "AdaptiveTesting") target = adaptiveTestingRef
        else if (to === "Paperless") target = paperlessRef
        else target = summaryRef

        const position = target.current.getBoundingClientRect().top + window.pageYOffset - parseFloat(getComputedStyle(document.documentElement).fontSize)*2.5
        window.scrollTo({top: position, behavior: "smooth"})
    }

    useEffect(()=>{
        const options = {
          rootMargin: '0px',
          threshold: 0.3
        }
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible')
            }
          })
        }, options)
    
        const hiddenElements = document.querySelectorAll('.contacts-container')
        hiddenElements.forEach((element)=>observer.observe(element))
    
        return () => {
          observer.disconnect()
        }
    },[])

    return(
        <>
            <div className="main-side-wrapper">
                <div className="main-container">
                    <h1>New! Digital SAT!</h1>
                    <h3>The new Digital SAT is coming and improvements have been made!</h3>
                    <h2 ref={summaryRef}>Summary</h2>
                    <ul className="digital-sat-summary">
                        <li>Digital SAT rolls out March 2024.</li>
                        <li>Digital PSAT rolls out October 2023.</li>
                        <li>Digital SAT will be using adaptive testing.</li>
                        <li>The full test will be only 2 hrs and 14 minutes in total.</li>
                        <li>Each section is now rebranded as "Modules."</li>
                        <li>Reading and Writing are now combined into 1 section.</li>
                        <li>Questions will be significantly less "wordy."</li>
                        <li>Scores will still be scaled out of 800 for Reading/Writing and 800 for Math.</li>
                        <li>The Digital SAT is <b>not</b> a home test. Students still must go to testing centers.</li>
                    </ul>
                    <h2 ref={digitalTestingRef}>Digital Testing</h2>
                    <p>The Digital SAT is moving to become fully computerized.
                        However, students will still be required to go to testing centers.
                        Students may opt to bring their own laptops/tablets to take the exam or default to testing center supplied devices.
                        The test now only spans 2 hrs and 14 minutes of content compared to the previous 3 hrs.
                        Students will be taking the exam on SAT's digital test taking platform that includes features such as 
                        a lockout screen to prevent cheating, a built in timer, and a pop up calculator.
                    </p>
                    <h2 ref={oldVSNewRef}>New vs Old</h2>
                    <h3 ref={quickComparisonRef}>Quick Comparison</h3>
                    <div className="img-container">
                        <img src="SATCompareTable.svg" alt="comptable" className="SAT-compare-table"/>
                    </div>
                    <p>The new Digital SAT has rebranded its 4 sections to "Modules." 
                        Module 1 and 2 are both a mix of Reading and Writing. Module 3 and 4 are both Calculator permitted Math.
                        The SAT scaled scoring system will remain unchanged. Scores will still be scaled out of a total of 1600 that everyone is familiar with.
                        You may have noticed that the new Digital SAT is significantly fewer in questions and significantly shorter in duration.
                        College Board will be introducing <span>Adaptive Testing</span> to better quantify a students aptitude with significantly fewer questions.
                    </p>
                    <h3 ref={adaptiveTestingRef}>Adaptive Testing</h3>
                    <p>While adaptive testing is not new in the field of digital testing, it will certainly be new to high school students.
                        Here's how it works:
                    </p>
                    <ol className="adaptive-testing-steps">
                        <li>Students take Module 1 (Reading/Writing).</li>
                        <li>College Board's algorithm will immediately grade Module 1 to see what kinds of questions (easy, medium, hard) the students got wrong.</li>
                        <li>The algorithm will generate a customized Module 2 for the student based on their performance on Module 1.</li>
                        <ul className="adaptive-steps-nest">
                            <li>Students who performed well on Module 1 will be given fewer easy questions and more hard questions.</li>
                            <li>Students who performed poorly on Module 1 will be given fewer hard questions and more easy questions.</li>
                        </ul>
                        <li>Students take Module 2 (Reading/Writing)</li>
                        <li>Repeat for Module 3 and 4 (Math)</li>
                    </ol>
                    <p>Here are 2 examples of how Module 3 and Module 4 could look like:</p>
                    <h4>Example 1</h4>
                    <img src="mod-key.svg" alt="modkey" className="module-key"/>
                    <div className="img-container">
                        <img src="mod1-example-high.svg" alt="mod1high" className="module-example"/>
                    </div>
                    <p>In this example, the student performed extremely well on Module 3 getting only 1 question wrong and the single question was categorized as hard. 
                        This means that the following Module 4 will be customized to challenge the student. Module 4 will then be used to determine whether 
                        the student is exactly at the level of Module 3 or is above the level of Module 3. The question distribution for Module 4 could then look like this: 
                    </p>
                    <div className="img-container">
                        <img src="mod2-example-high.svg" alt="mod2high" className="module-example"/>
                    </div>
                    <p>Since this student performed well in Module 3, his/her score is already at a potential 450 minimum. 
                        Any questions he/she gets correct in Module 4 will only raise his/her score. 
                        The more hard questions the student answers correctly the higher the score will jump.
                        This means that only students that perform well in Module 3 will be given the chance to get an 800.
                    </p>
                    <h4>Example 2</h4>
                    <img src="mod-key.svg" alt="modkey" className="module-key"/>
                    <div className="img-container">
                        <img src="mod1-example-low.svg" alt="mod1low" className="module-example"/>
                    </div>
                    <p>In this example, the student did not perform well on Module 3. This means that the following Module 4 will cut back on hard questions. 
                        The student will be given more easy and medium questions to be able to pinpoint exactly what level the student is at. 
                        The question distribution for Module 4 could then look like this: 
                    </p>
                    <div className="img-container">
                        <img src="mod2-example-low.svg" alt="mod2low" className="module-example"/>
                    </div>
                    <p>Unfortunately for this student, even if he/she aces Module 4 with flying colors by getting everything right, his/her math scaled score is potentially capped
                        at around 600 due to underperforming in Module 3. This makes sense considering that the student never got a chance to perform well for hard questions.
                        These two examples show the power of adaptive testing's capability to determine a student's aptitude with fewer questions.
                    </p>
                    <h3 ref={paperlessRef}>No More Paper</h3>
                    <p>
                        Since College Board no longer needs to mail their test papers to testing centers prior to test dates, expect the following:
                    </p>
                    <ul>
                        <li>Easier test registration.</li>
                        <li>More test taking time availabilities.</li>
                        <li>Less systemic cheating.</li>
                        <li>Quicker results.</li>
                    </ul>
                    <p>It is important to note that the push to digital test taking does not mean that students may take the Digital SAT at home. 
                        Students will still need to register to take the test at testing centers. 
                        Existing testing centers for the Paper SAT will still be testing centers for the Digital SAT.
                    </p>
                </div>
                <div className="side-container">
                    <div className="sticky-container">
                        <h3>Table of Contents</h3>
                        <div className="sidebar-button-container">
                            <ul style={{listStyle:"none", paddingLeft:"2rem"}}>
                                <li><button onClick={()=>scrollTo("Summary")} className="sidebar-button">Summary</button></li>
                                <li><button onClick={()=>scrollTo("DigitalTesting")} className="sidebar-button">Digital Testing</button></li>
                                <li><button onClick={()=>scrollTo("OldVSNew")} className="sidebar-button">Old vs New</button></li>
                                <ul style={{listStyle:"none", paddingLeft:"1rem"}}>
                                    <li><button onClick={()=>scrollTo("QuickComparison")} className="sidebar-button">Quick Comparison</button></li>
                                    <li><button onClick={()=>scrollTo("AdaptiveTesting")} className="sidebar-button">Adaptive Testing</button></li>
                                    <li><button onClick={()=>scrollTo("Paperless")} className="sidebar-button">Paperless</button></li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Contacts/>
        </>
    )
}