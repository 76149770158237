import React,{useState,useEffect} from 'react'
import '../StyleComponents/DisplayHomework.css'

export default function DisplayHomework(props){
    const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= 600)
    const homeworkList = props.homeworkList
    const student = props.student
    const studentKey = `${student.first_name} ${student.last_name},${student.email}`

    const handleResize = () => {
        setIsSmallWidth(window.innerWidth <= 600)
    }
    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    },[props])

    function getHomeworkGrade(hw){
        const studentAnswers = hw.student_answers
        if (studentAnswers[studentKey]){
            var correct = 0
            const questions = hw.questions.map(q=>{return q.answer})
            const answers = studentAnswers[studentKey].split("~")
            for (var i = 0; i < questions.length; i++){
                if (questions[i] === answers[i]) correct++
            }
            var grade = correct/questions.length * 100
            grade = Math.round(grade)
            var gradeColor = "green"
            if (grade < 90) gradeColor = "orangered"
            if (grade < 60) gradeColor = "red"
            return(
                <span style={{color:gradeColor}}>{grade+"%"}</span>
            )
        }
        return(
            <span>Incomplete</span>
        )
    }

    return(
        <>
            <h1>Homework</h1>
            <table className="student-homework-table">
                <tbody>
                    <tr style={{fontWeight:"bold"}}>
                        <td style={{borderBottom:"2px solid black",borderRight:"1px solid black"}}>Homework Name &nbsp;</td>
                        <td style={{borderBottom:"2px solid black",borderRight:"1px solid black"}}>Due Date</td>
                        <td style={{borderBottom:"2px solid black",borderRight:"1px solid black"}}>Status</td>
                        <td style={{borderBottom:"2px solid black"}}>Grade</td>
                    </tr>
                    {homeworkList.map(hw=>{
                        return <tr>
                            <td style={{borderTop:"1px solid black",borderRight:"1px solid black"}}>{isSmallWidth ? `${hw.name.substring(0,9)}...` : hw.name}</td>
                            <td style={{borderTop:"1px solid black",borderRight:"1px solid black"}}>{hw.due_date}</td>
                            <td style={{borderTop:"1px solid black",borderRight:"1px solid black"}}><img src={hw.student_answers[studentKey] ? "green-check.svg":"red-x.svg"} alt="icon" className="student-homework-icon"/></td>
                            <td style={{borderTop:"1px solid black"}}>{getHomeworkGrade(hw)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    )
}