import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import '../StyleComponents/SchedulerLarge.css'

export default function SchedulerLarge(){
    const monthColor = ["lightblue","mistyrose","#77DD77","#E0B0FF","bisque","#FF8674","orange","gold","#E6E6FA","#C8B560","#C9C0BB","#3BB9FF"]
    const [isWeeklyCalendar, setIsWeeklyCalendar] = useState(false)
    const [lessons, setLessons] = useState({})
    const [currLesson, setCurrLesson] = useState({})
    const [studentsAttended, setStudentsAttended] = useState([])
    const [studentsAbsent, setStudentsAbsent] = useState([])
    const [dateToday, setDateToday] = useState(new Date())
    const [teacherNotes, setTeacherNotes] = useState('')
    const [displayPopup, setDisplayPopup] = useState(false)
    const [confirmVerifyPopup, setConfirmVerifyPopup] = useState(false)

    const detailRef = useRef()
    const [isDraggingDetail, setIsDraggingDetail] = useState(false)
    const [detailOffsetX, setDetailOffsetX] = useState(0)
    const [detailOffsetY, setDetailOffsetY] = useState(0)

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const daysInMonth = Array.from({ length: new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate() }, (_, i) => i + 1)
    const blankDays = Array.from({ length: (new Date(dateToday.getFullYear(), dateToday.getMonth(),1).getDay()) }, (_) => " ")
    const daysToShow = [...blankDays, ...daysInMonth]

    function hashTime(time){
        const parts = time.split(":")
        const hour = parseInt(parts[0])*60
        const minutes = parseInt(parts[1][0])*10+parseInt(parts[1][1])
        return hour + minutes + (parts[1][2] === "P" && hour < 12 ? 12*60 : 0)
    }
    function updateLessons(list){
        if (list){
            list.sort((a,b)=>{
                const diff = a.date_as_int - b.date_as_int
                return diff === 0 ? (hashTime(a.start_time) - hashTime(b.start_time)) : (diff) 
            })
            const map = {}
            for (var i = 0; i < list.length; i++){
                if (!(list[i].date in map)){
                    map[list[i].date] = []
                }
                map[list[i].date].push(list[i])
            }
            setLessons(map)
        }
    }
    async function getLessons(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers:{"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            updateLessons(response.data.user.lessons)
        } catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
        getLessons()
    },[])
    useEffect(()=>{
        if (currLesson){
            setTeacherNotes(currLesson.teacher_notes ? currLesson.teacher_notes : "")
        }
    },[currLesson])

    const getNextWeek = () => {
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()+7)
        setDateToday(newDate)
    }
    const getPrevWeek = () => {
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()-7)
        setDateToday(newDate)
    }
    const getDate = (index) => {
        const newDate = new Date(dateToday)
        newDate.setDate(dateToday.getDate()-dateToday.getDay()+index)
        return `${newDate.getMonth()+1}/${newDate.getDate()}`
    }
    const getDateWithYear = (index) => {
        const newDate = new Date(dateToday)
        newDate.setDate(dateToday.getDate()-dateToday.getDay()+index)
        return `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()}`
    }
    function getLessonPosition(time){
        var hour = parseInt(time.split(":")[0])
        const minutes = parseInt(time[time.length-3])+10*parseInt(time[time.length-4])
        if (time[time.length-2] === 'P' && hour !== 12) hour += 12
        hour -= 8
        hour += minutes/60
        return `calc(100%/14*${hour} + 0.1rem)`
    }
    function clickDate(day){
        closeLesson()
        setDateToday(new Date(dateToday.getFullYear(),dateToday.getMonth(),day))
    }
    function closeLesson(){
        setCurrLesson({})
        setDisplayPopup(false)
        setConfirmVerifyPopup(false)
        setStudentsAbsent([])
        setStudentsAttended([])
        setTeacherNotes('')
    }
    function openLesson(lesson,e){
        closeLesson()
        setCurrLesson(lesson)
        if (lesson.student_attendance){
            setStudentsAbsent(Object.keys(lesson.student_attendance))
        }
        setTeacherNotes(lesson.teacher_notes)
        detailRef.current.style.left = `${(e.touches ? e.changedTouches[0].clientX : e.clientX)-detailOffsetX+window.pageXOffset}px`
        detailRef.current.style.top = `${(e.touches ? e.changedTouches[0].clientY : e.clientY)-detailOffsetY+window.pageYOffset}px`
        setDisplayPopup(true)
    }

    async function verifyLesson(){
        const postData ={
            JWT:`Bearer ${localStorage.getItem('user')}`,
            students_attended:studentsAttended.join("~"),
            students_absent:studentsAbsent.join("~"),
            date:currLesson.date,
            start_time:currLesson.start_time,
            teacher_email:currLesson.teacher_email,
            teacher_notes:teacherNotes
        }
        console.log(postData)
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/verify/lesson',postData)
            if (response.data){
                getLessons()
                alert("Class has been verified")
                closeLesson()
            } else{
                alert("Unknown error. Please try again later.")
            }
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }

    const detailMouseDown = (e) => {
        if (e.target.tagName === "DIV"){
            setIsDraggingDetail(true)
            const rect = detailRef.current.getBoundingClientRect()
            setDetailOffsetX((e.touches ? e.changedTouches[0].clientX : e.clientX) - rect.left)
            setDetailOffsetY((e.touches ? e.changedTouches[0].clientY : e.clientY) - rect.top)
        }
    }
    const detailMouseMove = (e) => {
        if (isDraggingDetail){
            detailRef.current.style.left = `${(e.touches ? e.changedTouches[0].clientX : e.clientX)-detailOffsetX+window.pageXOffset}px`
            detailRef.current.style.top = `${(e.touches ? e.changedTouches[0].clientY : e.clientY)-detailOffsetY+window.pageYOffset}px`
        }
    }
    const detailMouseUp = () => {setIsDraggingDetail(false)}

    const handleAttendanceCheck = (student, isChecked) => {
        if (isChecked) {
            setStudentsAttended((prev) => [...prev, student]);
            setStudentsAbsent((prev) => prev.filter((s) => s !== student));
        } else {
            setStudentsAbsent((prev) => [...prev, student]);
            setStudentsAttended((prev) => prev.filter((s) => s !== student));
        }
    }
    return(
        <div>
            <button className="toggle-calendar-button" onClick={()=>setIsWeeklyCalendar(!isWeeklyCalendar)}>{isWeeklyCalendar ? "Switch to Monthly" : "Switch to Weekly"}</button>
            {isWeeklyCalendar ? 
                <div className="schedule-calendar-weekly-container">
                    <div className="weekly-calendar-header">
                        <button onClick={()=>getPrevWeek()}>Prev Week</button>
                        <span style={{fontSize:"2rem",fontWeight:"bold",padding:"1rem"}}>Week of {getDateWithYear(0)}~{getDateWithYear(6)}</span>
                        <button onClick={()=>getNextWeek()}>Next Week</button>
                    </div>
                    <div className="days-of-week-container">
                        <div></div>
                        {daysOfWeek.map((day,i)=>{
                            return <div key={`day${i}`}>
                                        <span>{day}</span>
                                        <br/>
                                        <span>{getDate(i)}</span>
                                </div>
                        })}
                    </div>
                    <div className="days-of-week-schedule-container">
                        <div className="days-of-week-column">
                            {Array.from({length: 28}).map((_,i)=>{
                                return <div key={`time${i}`} style={{width:"100%",height:"2rem",borderTop:`1px ${i%2===0 ? "black" : "lightgray"} solid`}}>
                                    {i%2===0 ? `${i > 8 ? (i/2+8-12) : (i/2+8)}:00${i > 6 ? "PM" : "AM"}` : ""}
                                </div>
                            })}
                        </div>
                        {Array.from({length:7}).map((_,i)=>{
                            return(
                                <div key={`day${i}`} className="days-of-week-column">
                                    {Array.from({length: 28}).map((_,j)=>{
                                        return <div key={`time${i}-${j}`} style={{width:"100%",height:"2rem",borderLeft:"1px black solid",borderTop:`1px ${j%2===0 ? "black" : "lightgray"} solid`}}/>
                                    })}
                                    {lessons[getDateWithYear(i)] ? lessons[getDateWithYear(i)].map((l)=>{
                                        return <div key={`lesson-${l.date}-${l.start_time}`}
                                                    className="weekly-calendar-lesson"
                                                    style={{position:"absolute",height:`calc(4rem*${l.duration}/60 - 0.2rem)`,top:getLessonPosition(l.start_time),left:"2%"}}
                                                    onClick={(e)=>openLesson(l,e)}
                                                >
                                                    {l.verified ? <img
                                                        src="green-check.svg"
                                                        alt="check"
                                                        key={`verified-${l.date}-${i}`}
                                                        className="verification-check"
                                                    /> : <></>}
                                                    {Object.keys(l.student_attendance).map((key,i)=>{
                                                        const parts = key.split(",")
                                                        return <div key={`lesson-${l.date}-${i}`}>
                                                            {`${parts[0]} ${parts[1][0]}.`}
                                                        </div>
                                                    })}
                                                </div>
                                    }) : <></>}
                                </div>
                            )
                        })}
                    </div>
                </div>:
                <div className="schedule-calendar-monthly-container">
                    <div className="scheduler-calendar-container">
                        <div className="scheduler-calendar-header" style={{background:`${monthColor[dateToday.getMonth()]}`}}>
                            <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1))}>{'<'}</button>
                            <span>{dateToday.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                            <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 1))}>{'>'}</button>
                        </div>
                        <table className="scheduler-calendar">
                            <tbody>
                                <tr style={{fontWeight:"bold"}}>
                                    <td>Sun</td>
                                    <td>Mon</td>
                                    <td>Tue</td>
                                    <td>Wed</td>
                                    <td>Thu</td>
                                    <td>Fri</td>
                                    <td>Sat</td>
                                </tr>
                                {[...Array(6)].map((_, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {[...Array(7)].map((_, colIndex) => {
                                        const dayIndex = rowIndex * 7 + colIndex;
                                        const day = daysToShow[dayIndex];
                                        const dateString = `${dateToday.getMonth()+1}/${day}/${dateToday.getFullYear()}`
                                        return (
                                            <td key={dayIndex} className="scheduler-calendar-date">
                                                {
                                                    day === undefined || day === " " ? <></> : 
                                                    <>
                                                        <span>{day}</span>
                                                        {lessons[dateString] ? 
                                                            <div className="calendar-blue-circle" onClick={()=>clickDate(day)}>
                                                                <div className="calendar-lesson-count">{lessons[dateString].length}</div>
                                                            </div> : ""}
                                                    </>
                                                }
                                            </td>
                                        );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="daily-schedule-container">
                        <h1>{daysOfWeek[dateToday.getDay()] + ", " + dateToday.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</h1>
                        {lessons[`${dateToday.getMonth()+1}/${dateToday.getDate()}/${dateToday.getFullYear()}`] ? 
                            (lessons[`${dateToday.getMonth()+1}/${dateToday.getDate()}/${dateToday.getFullYear()}`].map((l,i)=>{
                                return <Lesson key={`les-${i}`} l={l} i={i} openLesson={openLesson}/>
                            })) : 
                            <></>
                        }
                    </div>
                </div>
            }
            <br/><br/>
            <div style={{display:`${displayPopup ? "" : "none"}`}} 
                className="lesson-detail-popup-div"
                ref={detailRef}
                onMouseDown={detailMouseDown}
                onTouchStart={detailMouseDown}
                onMouseMove={detailMouseMove}
                onTouchMove={detailMouseMove}
                onMouseUp={detailMouseUp}
                onTouchEnd={detailMouseUp}
                onTouchCancel={detailMouseUp}
            >
                <div style={{borderBottom:"2px solid black",background:"rgba(255, 184, 117, 0.75)",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeLesson()}>Close</div>
                <span className="lesson-detail-title">Lesson Details</span><br/>
                {currLesson.verified ? 
                    <span style={{color:"green"}}>Verified <img style={{width:"1rem", height:"1rem"}} src="green-check.svg" alt="check"/></span> 
                    : <span style={{color:"darkred"}}>Unverified</span>}
                <div className="lesson-detail-container">
                    <div className="lesson-detail"><b>Date: </b>{daysOfWeek[dateToday.getDay()] + ", " + currLesson.date}</div>
                    <div className="lesson-detail"><b>Start Time: </b>{currLesson.start_time}</div>
                    <div className="lesson-detail"><b>Class Duration: </b>{`${Math.floor(currLesson.duration/60)} hrs ${currLesson.duration%60} mins`}</div>
                    <div className="lesson-detail"><b>Teacher: </b>{`${currLesson.teacher_first_name ? currLesson.teacher_first_name : ""} ${currLesson.teacher_last_name ? currLesson.teacher_last_name[0] : ""}.`}</div>
                    <div className="lesson-detail"><b>Student Attendance: </b></div>
                    {currLesson.student_attendance ? Object.entries(currLesson.student_attendance).map(([key,val],i)=>{
                        const parts = key.split(",")
                        return <div key={`attend-${currLesson.date}-${currLesson.start_time}-${i}`}>
                            <input
                                type="checkbox"
                                id={`student-${i}`}
                                onChange={(e)=>handleAttendanceCheck(key,e.target.checked)}
                            />
                            <label htmlFor={`student-${i}`}>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</label>
                            {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (currLesson.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                        </div>
                    }) : <></>}
                    <div className="lesson-detail"><b>Administrator Notes: </b>{`"${currLesson.admin_notes ? currLesson.admin_notes : ""}"`}</div>
                    <div className="lesson-detail">
                        <b>Teacher Notes: </b>
                        <textarea id="book-lesson-notes"
                            onChange={(e)=>setTeacherNotes(e.target.value)}
                            value={teacherNotes}
                            rows="4"
                            style={{width:"90%"}}
                        />
                    </div>
                    <button className="lesson-detail-button" onClick={()=>setConfirmVerifyPopup(true)}>Verify Lesson</button>
                    {confirmVerifyPopup ? <button className="lesson-detail-button" style={{color:"red"}} onClick={()=>verifyLesson()}>Confirm Verify</button> : <></>}
                </div>
            </div>
        </div>
    )
}

function Lesson({l, openLesson, i}){
    const [isExpanded, setIsExpanded] = useState(false)
    const contentRef = useRef()
    const collapseContentRef = useRef()
    const [contentHeight, setContentHeight] = useState(0)
    useEffect(() => {
        if (isExpanded){
            setContentHeight(contentRef.current.scrollHeight)
        } else{
            setContentHeight(2*collapseContentRef.current.scrollHeight)
        }
    }, [isExpanded])

    return(
        <>
            <div 
                ref={contentRef} 
                className={`lesson-detail-container${isExpanded ? " expanded" : ""}`}
                style={{height:`${contentHeight}px`, border:"1px black solid"}}
            >
                <div 
                    className="lesson-detail-bar"
                    onClick={()=>setIsExpanded(!isExpanded)}
                    style={{background:(l.verified ? "rgb(0, 230, 0)" : "darkred")}}
                />
                {isExpanded ? <div>
                    <h2>Class {i+1}</h2>
                    {l.verified ? <span style={{color:"green"}}>Verified <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/></span> : <span style={{color:"darkred"}}>Unverified</span>}
                    </div>: <></>
                }
                <div ref={collapseContentRef}>
                    <div className="lesson-detail"><b>Start Time: </b>{l.start_time}</div>
                    <div className="lesson-detail"><b>Duration: </b>{`${Math.floor(l.duration/60)} hr(s) ${l.duration%60} mins`}</div>
                </div>
                {isExpanded ? <div>
                    <div className="lesson-detail"><b>Teacher: </b>{`${l.teacher_first_name ? l.teacher_first_name : ""} ${l.teacher_last_name ? l.teacher_last_name[0] : ""}.`}</div>
                    <div className="lesson-detail"><b>Student Attendance:</b>
                        {l.student_attendance ? Object.entries(l.student_attendance).map(([key,val],i)=>{
                            const parts = key.split(",")
                            return <div key={`attend-${l.date}-${l.start_time}-${i}`}>
                                <span>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</span>
                                {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (l.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                            </div>
                        }) : <></>}
                    </div>
                    <div className="lesson-detail"><b>Administrator Notes: </b>{`"${l.admin_notes ? l.admin_notes : ""}"`}</div>
                    <div className="lesson-detail"><b>Teacher Notes: </b>{`"${l.teacher_notes ? l.teacher_notes : ""}"`}</div>
                    <button className="lesson-detail-button" onClick={(e)=>openLesson(l,e)}>Click for verification or deletion</button>
                </div> : <></>}
                </div>
        </>
    )
}