import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';

export default function MakeDigitalSATQuestion(){ //todo: dropdowns for question categorization
    const errRef = useRef();

    const [testId, setTestId] = useState('')
    const [url, setUrl] = useState('')
    const [difficulty, setDifficulty] = useState('')
    const [textType, setTextType] = useState('')
    const [category, setCategory] = useState('')
    const [answer, setAnswer] = useState('')
    const [mr, setMr] = useState('')
    const [number, setNumber] = useState('')
    const [module, setModule] = useState('')
    const [isFRQ, setIsFRQ] = useState('')
    const [errMsg, setErrMsg] = useState('')

    useEffect(()=>{
        setErrMsg('')
    },[testId, url, difficulty, textType, category, answer, mr, number, module, isFRQ]);

    const submitForm = async (e) => {
        e.preventDefault();
        if (answer === '' || url === '' || mr === ''){
            setErrMsg("Please check that the information is entered correctly")
            return
        }
        if (mr === "Reading" && category === ''){
            setErrMsg("Please check that the information is entered correctly")
            return
        }
        if (difficulty === ''){
            setErrMsg("Please enter a difficulty for the question")
            return
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/digitalSAT/question/add', {
                "test_id": testId,
                "module": module,
                "number": number,
                "difficulty": difficulty,
                "URL": url,
                "category": category,
                "text_type": textType,
                "mr": mr,
                "answer": answer,
                "FRQ": isFRQ,
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            console.log(response)
            if (!response.data) alert('Session expired, please sign in again.')
            else alert('Question successfully added to the database.')
        } catch{
            setErrMsg("No server response.  Please try again later.")
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Test ID: <input className="admin-input" onChange={e=>setTestId(e.target.value)}></input> 
                    </li>
                    <li className="admin-list">
                        Question image URL: <input className="admin-input" onChange={e => setUrl(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Module: {testId === '' ?
                        <></> : 
                        <select onChange = {(e)=>setModule(e.target.value)}>
                            <option></option>
                            <option>1</option>
                            <option>2</option>
                        </select>
                        }
                    </li>
                    <li className="admin-list">
                        Question Number: {testId === '' ? <></> : <input className="admin-input" onChange={e=>setNumber(e.target.value)}/>}
                    </li>
                    <li className="admin-list">
                        is FRQ: <select onChange={e=>setIsFRQ(e.target.value)}>
                            <option></option>
                            <option>True</option>
                            <option>False</option>
                        </select>
                    </li>
                    <li className="admin-list">
                        Question Answer: <input className="admin-input" onChange={e=>setAnswer(e.target.value)}></input>
                    </li>
                    <li className="admin-list">
                        Question Difficulty: <select onChange={(e)=>setDifficulty(e.target.value)}>
                            <option></option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </li>
                    <li className="admin-list">
                        Reading/Math: <select onChange={(e)=>setMr(e.target.value)}>
                            <option></option>
                            <option>Reading</option>
                            <option>Math</option>
                        </select>
                    </li>
                    <li className="admin-list">
                        Question Category: {mr === "Reading" ? <select onChange={e=>setCategory(e.target.value)}>
                            <option></option>
                            <option>Vocab (Fill In)</option> 
                            <option>Vocab (Context)</option>
                            <option>Summary</option>
                            <option>Segment</option>
                            <option>Reasoning</option>
                            <option>Objective</option>
                            <option>Main Idea</option>
                            <option>In-Text</option>
                            <option>Grammar (Transition)</option>
                            <option>Grammar (Punctuation)</option>
                            <option>Grammar (General)</option>
                            <option>Format</option>
                            <option>Evidence</option>
                            <option>Data</option>
                            <option>Core</option>
                            <option>Comparative Texts</option>
                        </select> :
                        <select onChange={e=>setCategory(e.target.value)}>
                            <option></option>
                            <option>Algebra</option>
                            <option>Geometry</option>
                            <option>Modeling</option>
                            <option>Data</option>
                            <option>Advanced Topics</option>
                            <option>Statistics</option>
                        </select>
                        }
                    </li>
                    <li className="admin-list">
                        Text Type: {mr === "Reading" ? <select onChange={e=>setTextType(e.target.value)}>
                            <option></option>
                            <option>Humanities</option>
                            <option>Social Science</option>
                            <option>Science</option>
                            <option>Literature</option>
                            <option>Poetry</option>
                            <option>General</option>
                        </select> :
                        <></>
                    }
                    </li>
                </ul>
                <button type="submit">Make Digital SAT Question</button>
            </form>
        </>
    );
}