import React, {useEffect} from "react";
import FlippableCard from "./Components/FlippableCard";
import Contacts from './Components/Contacts'

export default function StandardizedTesting() {
    useEffect(()=>{
        const options = {
            rootMargin: '0px',
            threshold: 0.3
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            }
            })
        }, options)

        const hiddenElements = document.querySelectorAll('.flippable-card-container, .contacts-container')
        hiddenElements.forEach((element)=>observer.observe(element))

        return () => {
            observer.disconnect()
        }
    },[])

  return (
    <>
      <h1>SAT/ACT</h1>
      <div className="flippable-card-wrapper">
        <FlippableCard 
          front={<>
              <h2>SAT</h2>
              <p>The Scholastic Assessment Test, created by CollegeBoard, is an aptitude test for <b>high schoolers</b>.</p>
              <p>The SAT is comprised of 4 sections: <b>Reading, Writing, Math (No Calculator), and Math (With Calculator)</b>.</p>
              <p>The SAT is always compared to the ACT due to universities giving the choice to the students which test they want to submit.</p>
              <h3>Pros</h3>
              <ul>
                  <li>No Science section.</li>
                  <li>Ample time.</li>
                  <li>Math section only requires knowledge up to Algebra 2</li>
              </ul>
              <h3>Cons</h3>
              <ul>
                  <li>Questions involve more critical thinking.</li>
                  <li>Math section requires knowledge of Statistics.</li>
              </ul>
              </>} 
          back={<>
              <h2>Understanding SAT Scores</h2>
              <p>The SAT is scored out of <b>1600</b> (The sum of the 3 subsections). The maximum scores for <b>Reading, Writing, and Math</b> are <b>400, 400, and 800</b> respectively. 
                  The minimum scores for Reading, Writing, and Math are 100, 100, and 200 respectivly.</p>
              <p>The following is an approximate <b>percentile conversion</b> of SAT scores:</p>
              <ul>
                  <li>Max Score: 1600</li>
                  <li>98th Percentile: 1500</li>
                  <li>90th Percentile: 1350</li>
                  <li>80th Percentile: 1250</li>
                  <li>70th Percentile: 1180</li>
                  <li>60th Percentile: 1100</li>
                  <li>50th Percentile: 1040</li>
                  <li>25th Percentile: 880</li>
              </ul>
              <p><b>To best understand how good an SAT score is, compare the score's percentile with a university's acceptance rate.</b></p>
              </> 
          }
          hasButton={true}
          button={"Click to see how SAT is scored"}
        />
        <FlippableCard 
            front={<>
                <h2>ACT</h2>
                <p>The American College Testing, created by ACT.org, is an aptitude test for <b>high schoolers</b>.</p>
                <p>The ACT is comprised of 4 sections: <b>English, Math, Reading, and Science</b>.</p>
                <p>The ACT is always compared to the SAT due to universities giving the choice to the students which test they want to submit.
                    </p>
                <h3>Pros</h3>
                <ul>
                    <li>Questions are straight forward.</li>
                    <li>Advance topics in math are knowledge based and never problem solving.</li>
                </ul>
                <h3>Cons</h3>
                <ul>
                    <li>Has a science section that tests science reasoning</li>
                    <li>Very short on time for every section.</li>
                    <li>Math section requires knowledge through PreCalculus.</li>
                </ul>
                </>} 
            back={<>
                <h2>Understanding ACT Scores</h2>
                <p>The ACT is scored out of <b>36</b> (The average of the 4 subsections). The maximum scores for <b>English, Math, Reading, Science</b> is <b>36</b>.</p>                
                <p><em>For example, a score distribution of 33 English, 32 Math, 28 Reading, and 29 Science will yield an average of 30.5 which rounds up to a final score of 31.</em></p>
                <p>The following is an approximate <b>percentile conversion</b> for ACT scores:</p>
                <ul>
                    <li>Max Score: 36</li>
                    <li>98th Percentile: 33</li>
                    <li>90th Percentile: 29</li>
                    <li>80th Percentile: 26</li>
                    <li>70th Percentile: 23</li>
                    <li>60th Percentile: 22</li>
                    <li>50th Percentile: 20</li>
                    <li>25th Percentile: 15</li>
                </ul>
                <p><b>To best understand how good an ACT score is, compare the score's percentile with a university's acceptance rate.</b></p>
                </>}
            hasButton={true}
            button={"Click to see how ACT is scored"}
        />
        <FlippableCard 
            front={<>
                <h2>PSAT/NMSQT</h2>
                <p>The Preliminary SAT/National Merit Scholarship Qualifying Test is commonly taken by <b>high school Juniors and Sophmores</b>.
                    As the later half of the name suggests, this is the time to shine for overachieving students to be awarded <b>scholarships</b>.</p>
                <p>The test is formatted just like the SAT. It is broken down into 4 sections: <b>Reading, Writing, Math (No Calc), Math (Calc)</b>.</p>
                <p>There is no alternative to this exam.</p>
            </>}
            back={<>
                <h2>Understanding PSAT Scores</h2>
                <p>The PSAT is scored out of <b>1520 points</b>. The maximum scores for <b>Reading, Writing, and Math</b> are <b>380, 380, and 760</b> respectively.
                    The minimum scores for Reading, Writing, and Math are 100, 100, and 200 respectively.</p>
                <p>The following is an approximate <b>percentile conversion</b> of PSAT scores:</p>
                <ul>
                    <li>Max Score: 1520</li>
                    <li>98th Percentile: 1350</li>
                    <li>90th Percentile: 1170</li>
                    <li>80th Percentile: 1090</li>
                    <li>70th Percentile: 1030</li>
                    <li>60th Percentile: 970</li>
                    <li>50th Percentile: 920</li>
                    <li>25th Percentile: 800</li>
                </ul>
                <p>Qualifying for National Merit Scholarship is based on many variables including but not limited to grade, percentile, and state.
                    The qualifying score for New Jersey students is approximately 1470.</p>
            </>}
            hasButton={true}
            button={"Click to see how PSAT is scored"}
        />
      </div>
      <br/>
      <Contacts/>
    </>
  )
}