import React from 'react'
import '../StyleComponents/SATScoreDisplay.css'

export default function SATScoreDisplay({test,nav,displayingMostRecent, testType}){
    if (test === undefined || test === null || test === '') return <></>
    function getColor(score){
        if (testType === "PSAT"){
            if (score >= 735) return "#38b000"
            else if (score >= 635) return "#9acd32"
            else if (score >= 535) return "#ffc300"
            else if (score >= 435) return "#f77f00"
            return "red"
        }else if (testType === "SAT" || testType === "PaperSAT"){
            if (score >= 775) return "#38b000"
            else if (score >= 675) return "#9acd32"
            else if (score >= 575) return "#ffc300"
            else if (score >= 475) return "#f77f00"
            return "red"
        } else{
            if (score >= 34) return "#38b000"
            else if (score >= 30) return "#9acd32"
            else if (score >= 25) return "#ffc300"
            else if (score >= 20) return "#f77f00"
            return "red"
        }
    }
    function getDate(date){
        const newDate = new Date(date)
        return newDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
    if (testType === "ACT"){
        return(
            <>
                <div className="most-recent-intro">
                    {displayingMostRecent ? <><span>Most Recent ACT:</span><br/></> : <></>}
                    <span className="score-date">{getDate(test.date)}</span>
                </div>
                <div className="most-recent-score-container">
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">English</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.scaled_scores[0]))}}>{test.scaled_scores[0]}</span><span style={{fontSize:"1.25rem"}}>/36</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Math</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.scaled_scores[1]))}}>{test.scaled_scores[1]}</span><span style={{fontSize:"1.25rem"}}>/36</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Reading</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.scaled_scores[2]))}}>{test.scaled_scores[2]}</span><span style={{fontSize:"1.25rem"}}>/36</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Science</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.scaled_scores[3]))}}>{test.scaled_scores[3]}</span><span style={{fontSize:"1.25rem"}}>/36</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Total</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.scaled_scores[4]))}}>{test.scaled_scores[4]}</span><span style={{fontSize:"1.25rem"}}>/36</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                </div>
            </>
        )
    } else if (testType === "PaperSAT"){
        return(
            <>
                <div className="most-recent-intro">
                    {displayingMostRecent ? <><span>Most Recent Paper SAT:</span><br/></> : <></>}
                    <span className="score-date">{getDate(test.date)}</span>
                </div>
                <div className="most-recent-score-container">
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Reading</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(2*test.reading_scaled))}}>{test.reading_scaled}</span><span style={{fontSize:"1.25rem"}}>/400</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Writing</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(2*test.writing_scaled))}}>{test.writing_scaled}</span><span style={{fontSize:"1.25rem"}}>/400</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Math</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor(test.math_scaled))}}>{test.math_scaled}</span><span style={{fontSize:"1.25rem"}}>/800</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                    <div className="section-score-div">
                        <div className="score-title-div">
                            <span className="score-title">Total</span>
                        </div>
                        <div className="scaled-score-container">
                            <span style={{color:(getColor((test.reading_scaled+test.writing_scaled+test.math_scaled)/2))}}>{test.reading_scaled+test.writing_scaled+test.math_scaled}</span><span style={{fontSize:"1.25rem"}}>/1600</span>
                        </div>
                        {displayingMostRecent ? 
                            <div className="score-see-details">
                                <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                            </div>
                        : <></>}
                    </div>
                </div>
            </>
        )
    }
    return(
        <>
            <div className="most-recent-intro">
                {displayingMostRecent ? <><span>Most Recent {testType === "SAT" ? "Digital SAT" : (testType === "PSAT" ? "PSAT" : "ACT")}:</span><br/></> : <></>}
                <span className="score-date">{getDate(test.date)}</span>
            </div>
            <div className="most-recent-score-container">
                <div className="section-score-div">
                    <div className="score-title-div">
                        <span className="score-title">R/W</span>
                    </div>
                    <div className="scaled-score-container">
                        <span style={{color:(getColor(test.english_scaled))}}>{test.english_scaled}</span><span style={{fontSize:"1.25rem"}}>/{testType === "PSAT" ? 760 : 800}</span>
                    </div>
                    {displayingMostRecent ? 
                        <div className="score-see-details">
                            <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                        </div>
                    : <></>}
                </div>
                <div className="section-score-div">
                    <div className="score-title-div">
                        <span className="score-title">Math</span>
                    </div>
                    <div className="scaled-score-container">
                        <span style={{color:(getColor(test.math_scaled))}}>{test.math_scaled}</span><span style={{fontSize:"1.25rem"}}>/{testType === "PSAT" ? 760 : 800}</span>
                    </div>
                    {displayingMostRecent ? 
                        <div className="score-see-details">
                            <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                        </div>
                    : <></>}
                </div>
                <div className="section-score-div">
                    <div className="score-title-div">
                        <span className="score-title">Total</span>
                    </div>
                    <div className="scaled-score-container">
                        <span style={{color:(getColor((test.math_scaled+test.english_scaled)/2))}}>{test.math_scaled+test.english_scaled}</span><span style={{fontSize:"1.25rem"}}>/{testType === "PSAT" ? 1520 : 1600}</span>
                    </div>
                    {displayingMostRecent ? 
                        <div className="score-see-details">
                            <span style={{cursor:"pointer"}} onClick={nav}>See Details...</span>
                        </div>
                    : <></>}
                </div>
            </div>
        </>
    )
}