import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'

export default function MakeStudentSchedule(){
    const errRef = useRef()

    const [errMsg, setErrMsg] = useState('')
    const [email, setEmail] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [parentPhoneNumber, setParentPhoneNumber] = useState('')

    useEffect(()=>{
        setErrMsg('')}, [email, firstName, lastName, phoneNumber]
    )

    const submitForm = async (e) =>{
        e.preventDefault()

        if (email === ''){
            setErrMsg('Email cannot be blank')
            return
        } else if (firstName === ''){
            setErrMsg('First name must be provided')
            return
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/create/student', {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "parent_email": parentEmail,
                "phone_number": phoneNumber,
                "parent_phone_number": parentPhoneNumber,
                "JWT":("Bearer "+localStorage.getItem('user'))
            })
            if (response.data){
                alert('Student has been added to the database.')
                console.log(response.data)
                return
            }
            setErrMsg('Something went wrong. Please try again later')
            console.log(response.data)
        } catch{
            setErrMsg('No server response. Please try again later.')
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color: 'darkred'}}>Please double check spelling upon submission</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list" style={{color: "red"}}>
                        First Name: <input className="admin-input" onChange={e=>setFirstName(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Last Name: <input className="admin-input" onChange={e=>setLastName(e.target.value)}/>
                    </li>
                    <li className="admin-list" style={{color: "red"}}>
                        Email: <input className="admin-input" onChange={e=>setEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Parent Email: <input className="admin-input" onChange={(e)=>setParentEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Phone Number: <input className="admin-input" onChange={e=>setPhoneNumber(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Parent Phone Number: <input className="admin-input" onChange={(e)=>setParentPhoneNumber(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Add Student</button>
            </form>
        </>
    )
}