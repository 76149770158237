import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import '../StyleComponents/SchedulerSmall.css'
import '../StyleComponents/SchedulerLarge.css'

export default function SchedulerSmall(){
    const monthColor = ["lightblue","mistyrose","#77DD77","#E0B0FF","bisque","#FF8674","orange","gold","#E6E6FA","#C8B560","#C9C0BB","#3BB9FF"]
    const [isWeeklyCalendar, setIsWeeklyCalendar] = useState(false)
    const [dateToday, setDateToday] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [weekDates, setWeekDates] = useState([new Date(), new Date(), new Date(), new Date(), new Date(), new Date(), new Date()])
    const [currFunction, setCurrFunction] = useState('')
    const [detailPopup, setDetailPopup] = useState(false)
    const [bookLessonPopup, setBookLessonPopup] = useState(false)
    const [createStudentPopup, setCreateStudentPopup] = useState(false)
    const [createTeacherPopup, setCreateTeacherPopup] = useState(false)
    const [confirmDeletePopup, setConfirmDeletePopup] = useState(false)
    const [confirmVerifyPopup, setConfirmVerifyPopup] = useState(false)
    const [openStudentDropdown, setOpenStudentDropdown] = useState(false)
    const [openTeacherDropdown, setOpenTeacherDropdown] = useState(false)
    const [teacherSearch, setTeacherSearch] = useState('')
    const [studentSearch, setStudentSearch] = useState('')
    const [showStudentTeacherDetail, setShowStudentTeacherDetail] = useState(false)
    const searchStudentRef = useRef()
    const searchTeacherRef = useRef()
    const confirmDeleteRef = useRef()

    const [bookLessonDate, setBookLessonDate] = useState('')
    const [bookLessonStartTime, setBookLessonStartTime] = useState('')
    const [bookLessonAMPM, setBookLessonAMPM] = useState("")
    const [bookLessonDuration, setBookLessonDuration] = useState('')
    const [bookLessonTeacher, setBookLessonTeacher] = useState('')
    const [bookLessonSearch, setBookLessonSearch] = useState('')
    const [bookLessonStudents, setBookLessonStudents] = useState([])
    const [bookLessonNotes, setBookLessonNotes] = useState('')
    const [createStudentFirstName, setCreateStudentFirstName] = useState('')
    const [createStudentLastName, setCreateStudentLastName] = useState('')
    const [createStudentPrimaryContact, setCreateStudentPrimaryContact] = useState('')
    const [createStudentEmail, setCreateStudentEmail] = useState('')
    const [createStudentParentEmail, setCreateStudentParentEmail] = useState('')
    const [createStudentPhoneNumber, setCreateStudentPhoneNumber] = useState('')
    const [createStudentParentPhoneNumber, setCreateStudentParentPhoneNumber] = useState('')
    const [createTeacherFirstName, setCreateTeacherFirstName] = useState('')
    const [createTeacherLastName, setCreateTeacherLastName] = useState('')
    const [createTeacherEmail, setCreateTeacherEmail] = useState('')
    const [createTeacherPhoneNumber, setCreateTeacherPhoneNumber] = useState('')

    const [lessons, setLessons] = useState({})
    const [students, setStudents] = useState([])
    const [teachers, setTeachers] = useState([])
    const [currStudent, setCurrStudent] = useState([])
    const [currTeacher, setCurrTeacher] = useState([])
    const [currLesson, setCurrLesson] = useState({})
    const [studentsAttended, setStudentsAttended] = useState([])
    const [studentsAbsent, setStudentsAbsent] = useState([])    

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    const daysInMonth = Array.from({ length: new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate() }, (_, i) => i + 1)
    const blankDays = Array.from({ length: (new Date(dateToday.getFullYear(), dateToday.getMonth(),1).getDay()) }, (_) => " ")
    const daysToShow = [...blankDays, ...daysInMonth]

    function hashTime(time){
        const parts = time.split(":")
        const hour = parseInt(parts[0])*60
        const minutes = parseInt(parts[1][0])*10+parseInt(parts[1][1])
        return hour + minutes + (parts[1][2] === "P" && hour < 12 ? 12*60 : 0)
    }
    function updateLessons(list){
        list.sort((a,b)=>{
            const diff = a.date_as_int - b.date_as_int
            return diff === 0 ? (hashTime(a.start_time) - hashTime(b.start_time)) : (diff) 
        })
        const map = {}
        for (var i = 0; i < list.length; i++){
            if (!(list[i].date in map)){
                map[list[i].date] = []
            }
            map[list[i].date].push(list[i])
        }
        setLessons(map)
    }
    async function getStudents(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/get/studentschedule/all',{
                headers:{"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            console.log(response.data)
            setStudents(response.data.students)
        }catch(err){
            console.log(err)
        }
    }
    async function getTeachers(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/get/teacher/all',{
                headers:{"JWT":`Bearer ${localStorage.getItem('user')}`}
            })
            console.log(response.data)
            setTeachers(response.data.teachers)
        } catch(err){
            console.log(err)
            alert("You probably need to log in.")
        }
    }
    async function getTeacher(email){
        const postData = {
            email:email,
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/get/teacher',postData)
            setTeacherSearch((response.data.first_name + " " + response.data.last_name).toLowerCase())
            setStudentSearch('')
            setCurrTeacher([response.data.first_name,
                response.data.last_name,
                response.data.email,
                response.data.phone_number])
            setCurrStudent([])
            updateLessons(response.data.lessons)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    async function getStudent(stu){
        const postData = {
            first_name:stu[0],
            last_name:stu[1],
            primary_contact:stu[2],
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/get/studentschedule',postData)
            setStudentSearch(`${response.data.first_name} ${response.data.last_name}`.toLowerCase())
            setTeacherSearch('')
            setCurrStudent([response.data.first_name,
                response.data.last_name,
                response.data.primary_contact,
                response.data.email,
                response.data.parent_email,
                response.data.phone_number,
                response.data.parent_phone_number])
            setCurrTeacher([])
            updateLessons(response.data.lessons)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    function toggleStudentDropdown(e){setOpenStudentDropdown(e && e.target === searchStudentRef.current)}
    function toggleTeacherDropdown(e){setOpenTeacherDropdown(e && e.target === searchTeacherRef.current)}
    function toggleConfirmDelete(e){setConfirmDeletePopup(e && e.target === confirmDeleteRef.current)}
    useEffect(()=>{
        getStudents()
        getTeachers()
        updateWeekDates(new Date())
        document.addEventListener("click",toggleConfirmDelete)
        document.addEventListener("click",toggleStudentDropdown)
        document.addEventListener("click",toggleTeacherDropdown)
        return () => {
            document.removeEventListener("click",toggleConfirmDelete)
            document.removeEventListener("click",toggleStudentDropdown)
            document.removeEventListener("click",toggleTeacherDropdown)
        }
    },[])

    function getFullDate(d){
        const day = parseInt(d.getDate())
        var suffix = "th"
        if (day%10 === 1 && Math.floor(day/10) !== 1) suffix = "st"
        else if (day%10 === 2 && Math.floor(day/10) !== 1) suffix = "nd"
        else if (day%10 === 3 && Math.floor(day/10) !== 1) suffix = "rd"
        return `${daysOfWeek[d.getDay()]}, ${months[parseInt(d.getMonth())]} ${day}${suffix}`
    }
    function getPrevWeek(){
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()-7)
        setDateToday(newDate)
        updateWeekDates(newDate)
    }
    function getNextWeek(){
        const newDate = new Date(dateToday)
        newDate.setDate(newDate.getDate()+7)
        setDateToday(newDate)
        updateWeekDates(newDate)
    }
    function selectDay(d){
        const newDate = new Date(d)
        setSelectedDate(newDate)
    }
    function updateWeekDates(start){
        const dayOfWeek = start.getDay()
        const newWeekDates = []
        for (var i = 0; i < 7; i++){
            const newDay = new Date(start)
            newDay.setDate(newDay.getDate()-dayOfWeek+i)
            newWeekDates.push(newDay)
        }
        setWeekDates(newWeekDates)
    }
    function checkDecoration(d){
        if (d.getDate() === selectedDate.getDate() &&
            d.getMonth() === selectedDate.getMonth() &&
            d.getFullYear() === selectedDate.getFullYear()
        ) return true
        return false
    }

    function openLesson(lesson,e){
        setCurrLesson(lesson)
        if (lesson.student_attendance){
            setStudentsAbsent(Object.keys(lesson.student_attendance))
        }
        setDetailPopup(true)
    }
    function openBookLesson(){
        if (bookLessonPopup) return
        if (createStudentPopup || createTeacherPopup){
            alert("Close the current popup before trying to open a new one.")
            return
        }
        setBookLessonPopup(true)
        setCurrFunction("book")
    }
    function openCreateStudent(){
        if (createStudentPopup) return
        if (bookLessonPopup || createTeacherPopup){
            alert("Close the current popup before trying to open a new one.")
            return
        }
        setCreateStudentPopup(true)
        setCurrFunction("stu")
    }
    function openCreateTeacher(){
        if (createTeacherPopup) return
        if (bookLessonPopup || createStudentPopup){
            alert("Close the current popup before trying to open a new one.")
            return
        }
        setCreateTeacherPopup(true)
        setCurrFunction("teach")
    }
    function closeLesson(){
        setCurrLesson({})
        setDetailPopup(false)
        setConfirmDeletePopup(false)
        setConfirmVerifyPopup(false)
        setStudentsAbsent([])
        setStudentsAttended([])
        setCurrFunction("")
    }
    function closeBookLesson(){
        setBookLessonAMPM("")
        setBookLessonDate('')
        setBookLessonDuration('')
        setBookLessonStartTime('')
        setBookLessonTeacher('')
        setBookLessonStudents([])
        setBookLessonNotes('')
        setBookLessonPopup(false)
        setBookLessonSearch('')
        setCurrFunction("")
    }
    function closeCreateStudent(){
        setCreateStudentEmail('')
        setCreateStudentFirstName('')
        setCreateStudentLastName('')
        setCreateStudentPrimaryContact('')
        setCreateStudentParentEmail('')
        setCreateStudentPhoneNumber('')
        setCreateStudentParentPhoneNumber('')
        setCreateStudentPopup(false)
        setCurrFunction("")
    }
    function closeCreateTeacher(){
        setCreateTeacherEmail('')
        setCreateTeacherFirstName('')
        setCreateTeacherLastName('')
        setCreateTeacherPhoneNumber('')
        setCreateTeacherPopup(false)
        setCurrFunction("")
    }

    const addStudent = (stu) => {
        if (!selectedStudent(stu)){
            setBookLessonStudents([...bookLessonStudents, stu])
        }
    }
    const selectedStudent = (stu) => {
        for (var i = 0; i < bookLessonStudents.length; i++){
            const student = bookLessonStudents[i]
            if (student[0] === stu[0] && student[1] === stu[1] && student[2] === stu[2]) return true
        }
        return false
    }
    const removeStudent = (stu) => {
        const list = bookLessonStudents.filter(s=>{return stu[0] !== s[0] || stu[1] !== s[1] || stu[2] !== s[2]})
        setBookLessonStudents(list)
    }
    const handleAttendanceCheck = (student, isChecked) => {
        if (isChecked) {
            setStudentsAttended((prev) => [...prev, student]);
            setStudentsAbsent((prev) => prev.filter((s) => s !== student));
        } else {
            setStudentsAbsent((prev) => [...prev, student]);
            setStudentsAttended((prev) => prev.filter((s) => s !== student));
        }
    }
    async function bookLesson(){
        const dateArray = bookLessonDate.split("/")
        if (dateArray.length !== 3 || parseInt(dateArray[2]) < 2023){
            alert("Lesson date is not valid")
            return
        }
        const date = new Date(dateArray[2],dateArray[0]-1,dateArray[1])
        if (bookLessonDate === null || bookLessonDate === '' || !(date instanceof Date && !isNaN(date))){
            alert("Lesson date is not valid")
            return
        } else if (bookLessonStartTime === null || bookLessonStartTime === ''){
            alert("Lessons start time cannot be blank")
            return
        } else if (bookLessonAMPM === null || bookLessonAMPM === ""){
            alert("You must choose whether the start time is AM or PM")
            return
        } else if (bookLessonDuration === null || bookLessonDuration === ''){
            alert("Lesson duration cannot be blank")
            return
        } else if (bookLessonTeacher === null || bookLessonTeacher === ''){
            alert("You must assign a teacher to the lesson")
            return
        }
        const postData = {
            date:bookLessonDate,
            start_time:(bookLessonStartTime+bookLessonAMPM),
            duration:bookLessonDuration,
            teacher_email:bookLessonTeacher,
            students:bookLessonStudents.map(stu=>stu.slice(0,3).join("~")).join(","),
            admin_notes:bookLessonNotes,
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/create/lesson',postData)
            if (response.data.error_message === ""){
                if (currStudent.length > 0){
                    getStudent(currStudent)
                } else if (currTeacher.length > 0){
                    getTeacher(currTeacher[2])
                }
                alert("Lesson successfully booked.")
                closeBookLesson()
            }
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    async function deleteLesson(){
        if (!detailPopup) return
        if (currLesson === null || currLesson === undefined || currLesson === '') return
        const postData = {
            date:currLesson.date,
            start_time:currLesson.start_time,
            teacher_email:currLesson.teacher_email,
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/delete/lesson',postData)
            if (response.data){
                if (currStudent.length > 0){
                    getStudent(currStudent)
                } else if (currTeacher.length > 0){
                    getTeacher(currTeacher[2])
                }
                alert("Lesson deleted")
                closeLesson()
            }
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    async function createStudent(){
        if (createStudentFirstName === ''){
            alert("First name cannot be left blank.")
            return
        } else if (createStudentLastName === ''){
            alert("Last name cannot be left blank")
            return
        } else if (createStudentPrimaryContact === ''){
            alert("Primary contact cannot be left blank. Please enter an email or phone number")
            return
        } else if (createStudentFirstName.includes(",") || createStudentFirstName.includes("~") ||
                createStudentLastName.includes(",") || createStudentLastName.includes("~") ||
                createStudentPrimaryContact.includes(",") || createStudentPrimaryContact.includes("~") ||
                createStudentEmail.includes(",") || createStudentEmail.includes("~") ||
                createStudentParentEmail.includes(",") || createStudentParentEmail.includes("~") ||
                createStudentPhoneNumber.includes(",") || createStudentPhoneNumber.includes("~") ||
                createStudentParentPhoneNumber.includes(",") || createStudentParentPhoneNumber.includes("~")){
            alert("Fields may not include commas (,) or tildas (~)")
            return
        }
        const postData = {
            first_name:createStudentFirstName,
            last_name:createStudentLastName,
            primary_contact:createStudentPrimaryContact,
            email:createStudentEmail,
            parent_email:createStudentParentEmail,
            phone_number:createStudentPhoneNumber,
            parent_phone_number:createStudentParentPhoneNumber,
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/create/student',postData)
            alert("Student has been created")
            getStudents()
            console.log(response.data)
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    async function createTeacher(){
        if (createTeacherFirstName === ''){
            alert("First name cannot be left blank")
            return
        } else if (createTeacherLastName === ''){
            alert("Last name cannot be left blank")
            return
        } else if (createTeacherEmail === ''){
            alert("Email cannot be left blank")
            return
        } else if (createTeacherFirstName.includes(",") || createTeacherFirstName.includes("~") ||
                createTeacherLastName.includes(",") || createTeacherLastName.includes("~") ||
                createTeacherEmail.includes(",") || createTeacherEmail.includes("~") ||
                createTeacherPhoneNumber.includes(",") || createTeacherPhoneNumber.includes("~")){
            alert("Fields may not include commas (,) or tildas (~)")
            return
        }
        const postData = {
            first_name:createTeacherFirstName,
            last_name:createTeacherLastName,
            email:createTeacherEmail,
            phone_number:createTeacherPhoneNumber,
            JWT:`Bearer ${localStorage.getItem('user')}`
        }
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/teacher/create',postData)
            console.log(response.data)
            alert("Teacher has been created. They have been emailed for registration details.")
            getTeachers()
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }
    async function verifyLesson(){
        const postData ={
            JWT:`Bearer ${localStorage.getItem('user')}`,
            students_attended:studentsAttended.join("~"),
            students_absent:studentsAbsent.join("~"),
            date:currLesson.date,
            start_time:currLesson.start_time,
            teacher_email:currLesson.teacher_email,
            teacher_notes:currLesson.teacher_notes
        }
        console.log(postData)
        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/schedule/verify/lesson',postData)
            if (response.data){
                if (currStudent.length > 0){
                    getStudent(currStudent)
                } else if (currTeacher.length > 0){
                    getTeacher(currTeacher[2])
                }
                alert("Class has been verified")
                closeLesson()
            } else{
                alert("Unknown error. Please try again later.")
            }
        } catch(err){
            console.log(err)
            alert("Something went wrong. Try again later.")
        }
    }

    return(
        <>
            <div className="bottom-menu-container">
                <div className="bottom-menu-button"
                    onClick={()=>setIsWeeklyCalendar(!isWeeklyCalendar)}
                >
                    <img style={{width:"1rem",height:"1rem"}} src="calendar-2.svg" alt="cross"/><br/>
                    <span>{isWeeklyCalendar ? "Weekly" : "Monthly"}</span>
                </div>
                <div className="bottom-menu-button" 
                    style={{background:(currFunction === "book" ? "rgb(207, 255, 207)" : "")}}
                    onClick={()=>openBookLesson()}
                >
                    <img style={{width:"1rem",height:"1rem"}} src="add-cross.svg" alt="cross"/><br/>
                    <span>Lesson</span>
                </div>
                <div className="bottom-menu-button" 
                    style={{background:(currFunction === "stu" ? "rgb(207, 255, 207)" : "")}}
                    onClick={()=>openCreateStudent()}
                >
                    <img style={{width:"1rem",height:"1rem"}} src="add-cross.svg" alt="cross"/><br/>
                    <span>Student</span>
                </div>
                <div className="bottom-menu-button" 
                    style={{background:(currFunction === "teach" ? "rgb(207, 255, 207)" : "")}}
                    onClick={()=>openCreateTeacher()}
                >
                    <img style={{width:"1rem",height:"1rem"}} src="add-cross.svg" alt="cross"/><br/>
                    <span>Teacher</span>
                </div>
            </div>
            {isWeeklyCalendar ? 
                <div>
                    <div className="day-selector-bar">
                        {weekDates.map((d,i)=>{
                            return <div 
                                className="day-selector" 
                                key={"mtwtfss-"+i} 
                                style={{cursor:"pointer",borderRadius:"10px",color:(checkDecoration(d) ? "green" : "black"),background:(checkDecoration(d) ? "rgba(221, 221, 221, 0.5)" : "")}}
                                onClick={()=>{selectDay(d)}}
                            >
                                <span>{daysOfWeek[d.getDay()].substring(0,3)}</span><br/>
                                <span style={{textDecoration:(checkDecoration(d) ? "underline" : "none")}}>{`${d.getMonth()+1}/${d.getDate()}`}</span>
                            </div>
                        })}
                    </div> 
                    <div style={{paddingTop:"0.5rem",display:"flex",justifyContent:"space-between"}}>
                        <button onClick={()=>getPrevWeek()}>{"<"}</button>
                        <span style={{fontWeight:"bold",fontSize:"1.25rem"}}>{getFullDate(selectedDate)}</span>
                        <button onClick={()=>getNextWeek()}>{">"}</button>
                    </div>
                </div>
                : 
                <div className="scheduler-calendar-container-small">
                    <div className="scheduler-calendar-header" style={{background:`${monthColor[dateToday.getMonth()]}`}}>
                        <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1))}>{'<'}</button>
                        <span>{dateToday.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                        <button onClick={() => setDateToday(new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 1))}>{'>'}</button>
                    </div>
                    <table className="scheduler-calendar-small">
                        <tbody>
                            <tr style={{fontWeight:"bold"}}>
                                <td>Sun</td>
                                <td>Mon</td>
                                <td>Tue</td>
                                <td>Wed</td>
                                <td>Thu</td>
                                <td>Fri</td>
                                <td>Sat</td>
                            </tr>
                            {[...Array(6)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                    {[...Array(7)].map((_, colIndex) => {
                                    const dayIndex = rowIndex * 7 + colIndex;
                                    const day = daysToShow[dayIndex];
                                    const dateString = `${dateToday.getMonth()+1}/${day}/${dateToday.getFullYear()}`
                                    return (
                                        <td key={dayIndex} className="scheduler-calendar-date-small">
                                            {
                                                day === undefined || day === " " ? <></> : 
                                                <>
                                                    <span>{day}</span>
                                                    {lessons[dateString] ? 
                                                        <div className="calendar-blue-circle" onClick={()=>setSelectedDate(new Date(dateToday.getFullYear(),dateToday.getMonth(),day))}>
                                                            <div className="calendar-lesson-count">{lessons[dateString].length}</div>
                                                        </div> : ""}
                                                </>
                                            }
                                        </td>
                                    );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span style={{fontWeight:"bold",fontSize:"1.5rem"}}>{getFullDate(selectedDate)}</span>
                </div>
            }
            <br/>
            <div style={{display:"flex",width:"100%",justifyContent:"center"}}>
                <div style={{width:"80%"}}>
                    <div className="search-dropdown-container" style={{width:"100%"}}>
                        <div className="search-dropdown-input">
                            <input
                                ref={searchTeacherRef}
                                value={teacherSearch}
                                onChange={(e)=>setTeacherSearch(e.target.value.toLowerCase())}
                                placeholder="Search Teacher"
                                type="text"
                                className="dropdown-search"
                                onClick={()=>{
                                    setTeacherSearch("")
                                    setOpenTeacherDropdown(true)
                                }}
                                style={{width:"100%"}}
                            />
                        </div>
                        <div style={{display:(openTeacherDropdown ? "" : "none")}} className="search-dropdown">
                            {teachers.filter((teach)=>{return teach.join(" ").toLowerCase().includes(teacherSearch)})
                            .map((teach,i)=>{
                                return <div 
                                    key={`search-teach-${i}`}
                                    className="search-results"
                                    onClick={()=>getTeacher(teach[2])}
                                >
                                    {`${teach[0]} ${teach[1]}`}
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="search-dropdown-container" style={{width:"100%"}}>
                        <div className="search-dropdown-input">
                            <input
                                ref={searchStudentRef}
                                value={studentSearch}
                                onChange={(e)=>setStudentSearch(e.target.value.toLowerCase())}
                                placeholder="Search Student"
                                type="text"
                                className="dropdown-search"
                                onClick={()=>{
                                    setStudentSearch("")
                                    setOpenStudentDropdown(true)
                                }}
                                style={{width:"100%"}}
                            />
                        </div>
                        <div style={{display:(openStudentDropdown ? "" : "none")}} className="search-dropdown">
                            {students.filter((stu)=>{return stu.join(" ").toLowerCase().includes(studentSearch)})
                            .map((stu,i)=>{
                                return <div 
                                    key={`search-stu-${i}`}
                                    className="search-results"
                                    onClick={()=>getStudent(stu)}
                                >
                                    {`${stu[0]} ${stu[1]} (${stu[2]})`}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <img src="info.svg" alt="info" onClick={()=>setShowStudentTeacherDetail(true)}/>
            </div>
            <div style={{marginTop:"1rem",paddingBottom:"5rem"}}>
                {lessons[`${selectedDate.getMonth()+1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`] ? 
                    (lessons[`${selectedDate.getMonth()+1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`].map((l,i)=>{
                        return <Lesson key={`les-${dateToday.getMonth()}-${dateToday.getDate()}-${i}`} i={i} l={l} openLesson={openLesson}/>
                    })) : 
                    <></>
                }
            </div>
            {detailPopup ? 
                <div className="scheduler-backdrop">
                    <div className="scheduler-small-popup-container">
                        <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeLesson()}>Close</div>
                        <span className="lesson-detail-title">Lesson Details</span><br/>
                        {currLesson.verified ? 
                            <span style={{color:"green"}}>Verified <img style={{width:"1rem", height:"1rem"}} src="green-check.svg" alt="check"/></span> 
                            : <span style={{color:"darkred"}}>Unverified</span>}
                        <div className="lesson-detail-container">
                            <div className="lesson-detail"><b>Date: </b>{daysOfWeek[dateToday.getDay()] + ", " + currLesson.date}</div>
                            <div className="lesson-detail"><b>Start Time: </b>{currLesson.start_time}</div>
                            <div className="lesson-detail"><b>Class Duration: </b>{`${Math.floor(currLesson.duration/60)} hrs ${currLesson.duration%60} mins`}</div>
                            <div className="lesson-detail"><b>Teacher: </b>{`${currLesson.teacher_first_name ? currLesson.teacher_first_name : ""} ${currLesson.teacher_last_name ? currLesson.teacher_last_name[0] : ""}.`}</div>
                            <div className="lesson-detail"><b>Student Attendance: </b></div>
                            {currLesson.student_attendance ? Object.entries(currLesson.student_attendance).map(([key,val],i)=>{
                                const parts = key.split(",")
                                return <div key={`attend-${currLesson.date}-${currLesson.start_time}-${i}`}>
                                    <input
                                        type="checkbox"
                                        id={`student-${i}`}
                                        onChange={(e)=>handleAttendanceCheck(key,e.target.checked)}
                                    />
                                    <label htmlFor={`student-${i}`}>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</label>
                                    {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (currLesson.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                                </div>
                            }) : <></>}
                            <div className="lesson-detail"><b>Administrator Notes: </b>{`"${currLesson.admin_notes ? currLesson.admin_notes : ""}"`}</div>
                            <div className="lesson-detail"><b>Teacher Notes: </b>{`"${currLesson.teacher_notes ? currLesson.teacher_notes : ""}"`}</div>
                            <button className="lesson-detail-button" onClick={()=>setConfirmVerifyPopup(true)}>Verify Lesson</button>
                            {confirmVerifyPopup ? <button className="lesson-detail-button" style={{color:"red"}} onClick={()=>verifyLesson()}>Confirm Verify</button> : <></>}<br/>
                            <button className="lesson-detail-button" ref={confirmDeleteRef} onClick={()=>setConfirmDeletePopup(true)}>Delete Lesson</button>
                            {confirmDeletePopup ? <button className="lesson-detail-button" style={{color:"red"}} onClick={()=>deleteLesson()}>Confirm Delete</button>:<></>}
                        </div>     
                    </div>               
                </div> : <></>
            }
            {bookLessonPopup ? 
                <div className="scheduler-backdrop">
                    <div className="scheduler-small-popup-container">
                        <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeBookLesson()}>Close</div>
                        <div>
                            <span className="lesson-detail-title">Create Lesson</span>
                            <div className="lesson-detail-container">
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-date"><span style={{color:"red"}}>*</span>Date: </label>
                                    <input id="book-lesson-date" 
                                        placeholder="mm/dd/yyyy" 
                                        value={bookLessonDate} 
                                        onChange={(e)=>setBookLessonDate(e.target.value)}
                                    />
                                </div>
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-start"><span style={{color:"red"}}>*</span>Start Time: </label>
                                    <input id="book-lesson-start" 
                                        placeholder="xx:xx" 
                                        onChange={(e)=>setBookLessonStartTime(e.target.value)}
                                        value={bookLessonStartTime}
                                    />
                                    <select value={bookLessonAMPM} onChange={(e)=>setBookLessonAMPM(e.target.value)}>
                                        <option value="">----</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </div>
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-duration"><span style={{color:"red"}}>*</span>Duration: </label>
                                    <input id="book-lesson-duration" 
                                        onChange={(e)=>setBookLessonDuration(e.target.value)}
                                        value={bookLessonDuration}
                                    />
                                    <label htmlFor="book-lesson-duration"> minutes</label>
                                </div>
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-teacher"><span style={{color:"red"}}>*</span>Teacher: </label>
                                    <select id="book-lesson-teacher" onChange={(e)=>setBookLessonTeacher(e.target.value)} value={bookLessonTeacher}>
                                        <option value="">------------</option>
                                        {teachers.map((teach,i)=>{
                                            return <option key={`book-lesson-teach${i}`} value={teach[2]}>{`${teach[0]} ${teach[1][0]}.`}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-student-finder"><span style={{color:"red"}}>*</span>Add Students: </label>
                                    <input 
                                        id="book-lesson-student-finder" 
                                        placeholder="search"
                                        onChange={(e)=>setBookLessonSearch(e.target.value.toLowerCase())}
                                        value={bookLessonSearch}
                                        className="search-student-input"
                                    />
                                    <div>
                                        <div className="student-search-dropdown">
                                            {students.filter((f)=>{return f[7].toLowerCase().includes(bookLessonSearch)})
                                            .map((stu,i)=>{
                                                return <div 
                                                        key={`search-stu-${i}`}
                                                        onClick={()=>addStudent(stu)}
                                                        style={{textWrap:"nowrap",background:(selectedStudent(stu) ? "lightblue" : "transparent")}}
                                                    >
                                                        {`${stu[0]} ${stu[1]} (${stu[2]})`}
                                                    </div>})}
                                        </div> 
                                    </div>
                                </div>
                                <div className="lesson-detail">
                                    <span>Selected Students: </span>
                                    <div className="student-search-dropdown">
                                        {bookLessonStudents.map((stu,i)=>{
                                            return <div
                                                    key={`stu-list-${i}`}
                                                    onClick={()=>removeStudent(stu)}
                                                    style={{textWrap:"nowrap"}}
                                            >
                                                {`${stu[0]} ${stu[1]} (${stu[2]})`}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="lesson-detail">
                                    <label htmlFor="book-lesson-notes">Notes: </label><br/>
                                    <textarea id="book-lesson-notes"
                                        onChange={(e)=>setBookLessonNotes(e.target.value)}
                                        value={bookLessonNotes}
                                        rows="4"
                                        style={{width:"90%"}}
                                    />
                                </div>
                                <button className="lesson-detail-button" onClick={()=>bookLesson()}>Book Lesson</button><br/>
                                <button className="lesson-detail-button" onClick={()=>closeBookLesson()}>Discard Changes</button>
                            </div> 
                        </div>
                    </div>
                </div> : <></>}
            {createStudentPopup ? 
                <div className="scheduler-backdrop">
                    <div className="scheduler-small-popup-container">
                        <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeCreateStudent()}>Close</div>
                        <span className="lesson-detail-title">Create Student</span>
                        <div className="lesson-detail-container">
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-first"><span style={{color:"red"}}>*</span>First Name: </label>
                                <input id="create-stu-first" 
                                    value={createStudentFirstName} 
                                    onChange={(e)=>setCreateStudentFirstName(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-last"><span style={{color:"red"}}>*</span>Last Name: </label>
                                <input id="create-stu-last"
                                    value={createStudentLastName}
                                    onChange={(e)=>setCreateStudentLastName(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-primary"><span style={{color:"red"}}>*</span>Primary Contact: </label>
                                <input id="create-stu-primary"
                                    value={createStudentPrimaryContact}
                                    onChange={(e)=>setCreateStudentPrimaryContact(e.target.value)}
                                    placeholder="email or phone number"
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-email">Student Email: </label>
                                <input id="create-stu-last"
                                    value={createStudentEmail}
                                    onChange={(e)=>setCreateStudentEmail(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-phone">Student Phone Number: </label>
                                <input id="create-stu-phone"
                                    value={createStudentPhoneNumber}
                                    onChange={(e)=>setCreateStudentPhoneNumber(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-pemail">Parent Email: </label>
                                <input id="create-stu-pemail"
                                    value={createStudentParentEmail}
                                    onChange={(e)=>setCreateStudentParentEmail(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-stu-pphone">Parent Phone Number: </label>
                                <input id="create-stu-pphone"
                                    value={createStudentParentPhoneNumber}
                                    onChange={(e)=>setCreateStudentParentPhoneNumber(e.target.value)}
                                />
                            </div>
                            <button className="lesson-detail-button" onClick={()=>createStudent()}>Submit</button><br/>
                            <button className="lesson-detail-button" onClick={()=>closeCreateStudent()}>Discard Changes</button>
                        </div>
                    </div>
                    
                </div> : <></>
            }
            {createTeacherPopup ? 
                <div className="scheduler-backdrop">
                    <div className="scheduler-small-popup-container">
                        <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>closeCreateTeacher()}>Close</div>
                        <span className="lesson-detail-title">Create Teacher</span>
                        <div className="lesson-detail-container">
                            <div className="lesson-detail">
                                <label htmlFor="create-teach-first"><span style={{color:"red"}}>*</span>First Name: </label>
                                <input id="create-teach-first"
                                    value={createTeacherFirstName}
                                    onChange={(e)=>setCreateTeacherFirstName(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-teach-last"><span style={{color:"red"}}>*</span>Last Name: </label>
                                <input id="create-teach-last"
                                    value={createTeacherLastName}
                                    onChange={(e)=>setCreateTeacherLastName(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-teach-email"><span style={{color:"red"}}>*</span>Email: </label>
                                <input id="create-teach-email"
                                    value={createTeacherEmail}
                                    onChange={(e)=>setCreateTeacherEmail(e.target.value)}
                                />
                            </div>
                            <div className="lesson-detail">
                                <label htmlFor="create-teach-phone">Phone Number: </label>
                                <input id="create-teach-phone"
                                    value={createTeacherPhoneNumber}
                                    onChange={(e)=>setCreateTeacherPhoneNumber(e.target.value)}
                                />
                            </div>
                            <button className="lesson-detail-button" onClick={()=>createTeacher()}>Submit</button><br/>
                            <button className="lesson-detail-button" onClick={()=>closeCreateTeacher()}>Discard Changes</button>
                        </div>
                    </div>
                </div> : <></>
            }
            {showStudentTeacherDetail ? 
                <div className="scheduler-backdrop">
                    <div className="scheduler-small-popup-container">
                        <div style={{borderBottom:"2px solid black",background:"lightblue",fontWeight:"bold",height:"2rem",justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>setShowStudentTeacherDetail(false)}>Close</div>
                        {currTeacher.length > 0 ?
                            <div className="lesson-detail-container">
                                <h2>Teacher Details</h2>
                                <div className="lesson-detail">
                                    <span><b>First Name: </b> {currTeacher[0]}</span>
                                </div>
                                <div className="lesson-detail">
                                    <span><b>Last Name: </b> {currTeacher[1]}</span>
                                </div>
                                <div className="lesson-detail">
                                    <span><b>Email: </b> {currTeacher[2]}</span>
                                </div>
                                <div className="lesson-detail">
                                    <span><b>Phone Number: </b> {currTeacher[3]}</span>
                                </div>
                                <button className="lesson-detail-button" onClick={()=>setShowStudentTeacherDetail(false)}>Close</button>
                            </div> :
                            (currStudent.length > 0 ? 
                                <div className="lesson-detail-container">
                                    <h2>Student Details</h2>
                                    <div className="lesson-detail">
                                        <span><b>First Name: </b> {currStudent[0]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Last Name: </b> {currStudent[1]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Primary Contact: </b> {currStudent[2]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Student Email: </b> {currStudent[3]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Parent Email: </b> {currStudent[4]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Student Phone Number: </b> {currStudent[5]}</span>
                                    </div>
                                    <div className="lesson-detail">
                                        <span><b>Parent Phone Number: </b> {currStudent[6]}</span>
                                    </div>
                                    <button className="lesson-detail-button" onClick={()=>setShowStudentTeacherDetail(false)}>Close</button>
                                </div> : <></>
                            )
                        }
                    </div>
                </div> : <></>
            }
        </>
    )
}

function Lesson({l, openLesson, i}){
    const [isExpanded, setIsExpanded] = useState(false)
    const contentRef = useRef()
    const collapseContentRef = useRef()
    const [contentHeight, setContentHeight] = useState(0)
    useEffect(() => {
        if (isExpanded){
            setContentHeight(contentRef.current.scrollHeight)
        } else{
            setContentHeight(2*collapseContentRef.current.scrollHeight)
        }
    }, [isExpanded])

    return(
        <>
            <div 
                ref={contentRef} 
                className={`lesson-detail-container${isExpanded ? " expanded" : ""}`}
                style={{height:`${contentHeight}px`, border:"1px black solid"}}
            >
                <div 
                    className="lesson-detail-bar" 
                    onClick={()=>setIsExpanded(!isExpanded)}
                    style={{background:(l.verified ? "rgb(0, 230, 0)" : "darkred")}}
                />
                {isExpanded ? <div>
                    <h2>Class {i+1}</h2>
                    {l.verified ? <span style={{color:"green"}}>Verified <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/></span> : <span style={{color:"darkred"}}>Unverified</span>}
                    </div>: <></>
                }
                <div ref={collapseContentRef}>
                    <div className="lesson-detail"><b>Start Time: </b>{l.start_time}</div>
                    <div className="lesson-detail"><b>Duration: </b>{`${Math.floor(l.duration/60)} hr(s) ${l.duration%60} mins`}</div>
                </div>
                {isExpanded ? <div>
                    <div className="lesson-detail"><b>Teacher: </b>{`${l.teacher_first_name ? l.teacher_first_name : ""} ${l.teacher_last_name ? l.teacher_last_name[0] : ""}.`}</div>
                    <div className="lesson-detail"><b>Student Attendance:</b>
                        {l.student_attendance ? Object.entries(l.student_attendance).map(([key,val],i)=>{
                            const parts = key.split(",")
                            return <div key={`attend-${l.date}-${l.start_time}-${i}`}>
                                <span>{`${parts[0]} ${parts[1]} (${parts[2]}) `}</span>
                                {val ? <img src="green-check.svg" alt="check" style={{width:"1rem",height:"1rem"}}/> : (l.verified ? <img src="red-x.svg" alt="x" style={{width:"1rem",height:"1rem"}}/> : "")}
                            </div>
                        }) : <></>}
                    </div>
                    <div className="lesson-detail"><b>Administrator Notes: </b>{`"${l.admin_notes ? l.admin_notes : ""}"`}</div>
                    <div className="lesson-detail"><b>Teacher Notes: </b>{`"${l.teacher_notes ? l.teacher_notes : ""}"`}</div>
                    <button className="lesson-detail-button" onClick={(e)=>openLesson(l,e)}>Click for verification or deletion</button>
                </div> : <></>}
                </div>
        </>
    )
}