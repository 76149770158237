import React, {useState} from 'react'
import axios from 'axios'
import './MakeHomeworkBundle.css'

export default function MakeHomeworkBundle(){
    const [name, setName] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [homeworkList, setHomeworkList] = useState([])
    const [assignedHomeworks, setAssignedHomeworks] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const [showDeets, setShowDeets] = useState(false)
    const [currHomework, setCurrHomework] = useState('')

    async function submit(){
        try{
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/homework/createbundle",{
                "JWT": `Bearer ${localStorage.getItem('user')}`,
                "name": name,
                "due_date": dueDate,
                "questions": assignedHomeworks.map(hw=>hw.url).join(",")
            })
            if (response.data === 0) alert("Successfully created homework bundle.")
            else if (response.data === 3) alert("Homework bundle name has not been provided")
            else if (response.data === 4) alert("Due date has not been provided")
            else if (response.data === 1) alert("This homework bundle name is already in use")
            else if (response.data === 2) alert("A homework question was not found in the database.")
            else alert("Server was responsive but there was an unknown error.")
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    async function getHomeworkQuestions(){
        try{
            const response = await axios.get("https://www.tenaflyprepacademy.com/server/getAllHomeworkQuestions", {
                headers: {"jwt":`Bearer ${localStorage.getItem('user')}`}
            })
            response.data.sort((a,b)=>a.question_id.localeCompare(b.question_id))
            setHomeworkList(response.data)
        } catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    function assignHomework(hw){
        for (var i = 0; i < assignedHomeworks.length; i++){
            if (assignedHomeworks[i].url === hw.url) return
        }
        var list = [...assignedHomeworks]
        list.push(hw)
        setAssignedHomeworks(list)
    }

    function unassignHomework(hw){
        for (var i = 0; i < assignedHomeworks.length; i++){
            if (assignedHomeworks[i].url === hw.url){
                var list = [...assignedHomeworks]
                list.splice(i,1)
                setAssignedHomeworks(list)
                return
            }
        }
    }

    function selectHomework(hw){
        console.log(hw)
        setCurrHomework(hw)
        setShowDeets(true)
    }

    return(
        <>  
            {showDeets ? 
                <div className="homework-overlay" onClick={()=>setShowDeets(false)}>
                    {
                        currHomework ? 
                        <div className="homework-info-container">
                            <div className="homework-details-container">
                                <p>{`URL: ${currHomework.url}`}</p>
                                <p>{`Title: ${currHomework.question_id}`}</p>
                                <p>{`Difficulty: ${currHomework.difficulty === 1 ? "Easy" : (currHomework.difficulty === 2 ? "Medium" : "Hard")}`}</p>
                                <p>{`Answer: ${currHomework.answer}`}</p>
                                <p>{`Category: ${currHomework.category}`}</p>
                                <p>{`Type: ${currHomework.type}`}</p>
                            </div>
                            <img src={currHomework.url} alt="hwq" className="homework-image"/>
                        </div> :
                        <></>
                    }
                </div> :
                <></>
            }
            
            <ul className="admin-forms">
                <li className="admin-list"><label htmlFor="name">Unique Bundle Name: </label><input id="name" className="admin-input" onChange={(e)=>setName(e.target.value)}/></li>
                <li className="admin-list"><label htmlFor="due_date">Due Date (DD/MM/YYY no leading zeros): </label><input id="due_date" className="admin-input" onChange={(e)=>setDueDate(e.target.value)}/></li>
            </ul>
            <div className="choose-questions-wrapper">
                <div className="unassigned-homework-container">
                    <button onClick={(e)=>getHomeworkQuestions()}>Get All Homework Questions</button>
                    <div><label htmlFor="s1">Search: </label><input id="s1" onChange={(e)=>setSearch(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {homeworkList.filter((hw)=>{
                            return search === '' ? hw : 
                            (hw.question_id.toLowerCase().includes(search) || 
                            hw.category === search || 
                            hw.type === search)
                        }).map((hw,i)=>{
                            return <li key={`${i}a`}>
                                <span style={{cursor:"pointer"}} onClick={()=>assignHomework(hw)}>{hw.question_id}</span>
                                <img onClick={()=>selectHomework(hw)} className="magnify-icon" src="magnify.svg" alt="mag"/>
                            </li>
                        })}
                    </ul>
                </div>
                <div className="assigned-homework-container">
                    <span style={{fontWeight:"bold"}}>To be assigned:</span>
                    <div><label htmlFor="s2">Search: </label><input id="s2" onChange={(e)=>setSearch2(e.target.value.toLowerCase())}/></div>
                    <ul style={{listStyle:"none"}}>
                        {assignedHomeworks.filter((hw)=>{
                            return search2 === '' ? hw :
                            (hw.question_id.toLowerCase().includes(search2) ||
                            hw.category === search2 ||
                            hw.type === search2)
                        }).map((hw,i)=>{
                            return <li key={`${i}u`}>
                                <span style={{cursor:"pointer"}} onClick={()=>unassignHomework(hw)}>{hw.question_id}</span>
                                <img onClick={()=>selectHomework(hw)} className="magnify-icon" src="magnify.svg" alt="mag"/>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
            <button onClick={()=>submit()}>Create Homework Bundle</button>
        </>
    )
}