import React, {useState} from 'react'
import axios from 'axios'

export default function DeleteHomeworkQuestion(){
    const [url, setUrl] = useState('')
    const [url2, setUrl2] = useState('')
    
    async function deleteHomework(){
        if (url === '' || (url !== url2)){
            alert("URL doesn't match")
            return
        }
        try{
            const response = await axios.post("https://www.tenaflyprepacademy.com/server/homework/deletequestion",{
                "JWT": `Bearer ${localStorage.getItem('user')}`,
                "url": url
            })
            if (response.data === 1) alert("Homework not found in the database")
            else if (response.data === 0) alert("Homework successfully deleted.")
            else alert("Unknown error.")
        }catch(err){
            alert("Something went wrong. Please try again later.")
        }
    }

    return(
        <>
            <ul className="admin-forms">
                <li><label htmlFor="u1">Url: </label><input onChange={(e)=>setUrl(e.target.value)} id="u1"/></li>
                <li><label htmlFor="u2">Confirm url: </label><input onChange={(e)=>setUrl2(e.target.value)} id="u2"/></li>
            </ul>
            <button onClick={()=>deleteHomework()}>Delete Homework Question</button>
        </>
    )
}