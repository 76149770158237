import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';

export default function UpdateStudent(){
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [parentEmail, setParentEmail] = useState('');

    useEffect(()=>{
        setErrMsg('')}, [studentEmail, firstName, lastName, parentEmail]
    );

    const submitForm = async (e) =>{
        e.preventDefault();

        if (studentEmail === ''){
            setErrMsg('Student email cannot be blank');
            return;
        } else if (firstName === '' || lastName === ''){
            setErrMsg('Please put student name');
            return;
        }

        try{
            const response = await axios.post('https://www.tenaflyprepacademy.com/server/student/update', {
                "first_name": firstName,
                "last_name": lastName,
                "email": studentEmail,
                "parent_email": parentEmail,
                "JWT":("Bearer "+localStorage.getItem('user'))
            });
            alert('Student has been updated')
            console.log(response.data);

        } catch{
            setErrMsg('No server response. Please try again later.');
        }
    }

    return(
        <>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <p style={{color:'darkred'}}>Only for existing students. Please double check spelling upon submission.</p>
            <form onSubmit={submitForm}>
                <ul className="admin-forms">
                    <li className="admin-list">
                        Student Email: <input className="admin-input" onChange={e=>setStudentEmail(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Student First Name: <input className="admin-input" onChange={e=>setFirstName(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Student Last Name: <input className="admin-input" onChange={e=>setLastName(e.target.value)}/>
                    </li>
                    <li className="admin-list">
                        Parent Email: <input className="admin-input" onChange={e=>setParentEmail(e.target.value)}/>
                    </li>
                </ul>
                <button typ="submit">Update Student</button>
            </form>
        </>
    );
}