import React from 'react'

export default function DigitalSATHistory(props){
    const testList = props.testList
    if (testList === "" || testList.length === 0){
        return(
            <>
                <h2>Digital SAT Test History</h2>
                <h3>No Tests in our Database</h3>
            </>
        )
    }
    return(
        <>
            <h2>Digital SAT Test History</h2>
            <table className="question-type-table">
                <tbody>
                    <tr style={{fontWeight:'bold'}}>
                        <td>Date</td>
                        <td>Reading/Writing</td>
                        <td>Math</td>
                        <td>Total</td>
                    </tr>
                    {testList.map((t,i)=>{
                        return (
                            <tr>
                                <td>{t.date}</td>
                                <td>{t.english_scaled}</td>
                                <td>{t.math_scaled}</td>
                                <td>{t.english_scaled+t.math_scaled}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}