import React, {useState} from 'react'
import './Marquee.css'

export default function Marquee(){
    const list = ["Math", "Programming", "Physics", "Chemistry", "Biology", "Statistics", "Writing", "Debate", "SAT"]
    const icons = ["math.svg","coding.svg","atom.svg","chemistry.svg","biology.svg","statistics.svg","writing.svg","public-speaking.svg","test.svg"]
    const [pause, setPause] = useState(false)

    return(
        <>
            <div className="marquee-container">
                <div
                    onPointerEnter={()=>setPause(true)}
                    onPointerLeave={()=>setPause(false)}
                    className={`marquee-components-container ${pause ? "paused" : ""}`}
                >
                    {list.map((e,i)=>{
                        return <div key={`${e} 1`}>
                            <img src={icons[i]} alt={`${e} 1`}/>
                            <span key={`${e} 1`}>{e}</span>
                        </div>
                    })}
                    {list.map((e,i)=>{
                        return <div key={`${e} 2`}>
                            <img src={icons[i]} alt={`${e} 2`}/>
                            <span key={`${e} 2`}>{e}</span>
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}