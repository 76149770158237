import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import GetAllStudents from './AdminComps/GetAllStudents';
import GetAllTests from './AdminComps/GetAllTests';
import MakeACTAnswer from './AdminComps/MakeACTAnswer';
import MakeSATAnswer from './AdminComps/MakeSATAnswer';
import MakeStudent from './AdminComps/MakeStudent';
import StudentACT from './AdminComps/StudentACT';
import StudentSAT from './AdminComps/StudentSAT';
import UpdateStudent from './AdminComps/UpdateStudent';
import DeleteTest from './AdminComps/DeleteTest';
import DeleteStudent from './AdminComps/DeleteStudent'
import DeleteParent from './AdminComps/DeleteParent'
import MakeParent from './AdminComps/MakeParent'
import GetAllParents from './AdminComps/GetAllParents';
import MakeDigitalSATQuestion from './AdminComps/MakeDigitalSATQuestion';
import AssignDigitalSAT from './AdminComps/AssignDigitalSAT';
import AssignHomework from './AdminComps/AssignHomework'
import MakeHomeworkBundle from './AdminComps/MakeHomeworkBundle'
import MakeHomeworkQuestion from './AdminComps/MakeHomeworkQuestion'
import DeleteHomeworkBundle from './AdminComps/DeleteHomeworkBundle'
import DeleteHomeworkQuestion from './AdminComps/DeleteHomeworkQuestion'
import GetAllHomeworkBundles from './AdminComps/GetAllHomeworkBundles'
import GetDigitalSATAnswers from './AdminComps/GetDigitalSATAnswers'
import GiveVocabSubmissions from './AdminComps/GiveVocabSubmissions'
import MakeTeacher from './AdminComps/MakeTeacher'
import MakeStudentSchedule from './AdminComps/MakeStudentSchedule'
import ScheduleStudents from './AdminComps/ScheduleLesson'
import './StyleComponents/Dashboard.css'


export default function Admin() {
    const[whatDo, setWhatDo] = useState('');
    const[doing, setDoing] = useState('');
    const navigate = useNavigate();
    // eslint-disable-next-line
    useEffect(()=>{validate()},[whatDo]);


    async function validate(){
        try{
            const response = await axios.get('https://www.tenaflyprepacademy.com/server/user',{
                headers: {
                    "JWT": "Bearer "+localStorage.getItem("user")
                }
            });
            if (response.data.user.role !== 7){
                alert('Unauthorized');
                navigate('/Login');
                return;
            }
        } catch(err){
            alert('Please log in');
            navigate('/Login');
            return;
        }
    }

    const changeWhatDo = (e) => {
        if (e.target.value === "Add Student"){
            setWhatDo('MakeStudent')
            setDoing(<MakeStudent/>)
        } else if (e.target.value === "Add Parent"){
            setWhatDo("MakeParent")
            setDoing(<MakeParent/>)
        } else if (e.target.value === "Add Teacher"){
            setWhatDo("MakeTeacher")
            setDoing(<MakeTeacher/>)
        } else if (e.target.value === "Add Student (Schedule)"){
            setWhatDo("MakeStudentSchedule")
            setDoing(<MakeStudentSchedule/>)
        } else if (e.target.value === "Schedule Students"){
            setWhatDo("ScheduleStudents")
            setDoing(<ScheduleStudents/>)
        } else if (e.target.value === 'Update Student'){
            setWhatDo('UpdateStudent')
            setDoing(<UpdateStudent/>)
        } else if (e.target.value === 'Get All Students'){
            setWhatDo("GetAllStudents")
            setDoing(<GetAllStudents/>)
        } else if (e.target.value === 'Get All Parents'){
            setWhatDo("GetAllParents")
            setDoing(<GetAllParents/>)
        } else if (e.target.value === 'Get All Tests'){
            setWhatDo('GetAllTests')
            setDoing(<GetAllTests/>)
        } else if (e.target.value === 'Create ACT Answer Key'){
            setWhatDo('MakeACTAnswer')
            setDoing(<MakeACTAnswer/>)
        } else if (e.target.value === 'Create SAT Answer Key'){
            setWhatDo('MakeSATAnswer')
            setDoing(<MakeSATAnswer/>)
        } else if (e.target.value === 'Input Digital SAT Questions'){
            setWhatDo('MakeDigitalSATQuestion')
            setDoing(<MakeDigitalSATQuestion/>)
        }else if (e.target.value === 'Input Student SAT Answers'){
            setWhatDo('StudentSAT')
            setDoing(<StudentSAT/>)
        } else if (e.target.value === 'Input Student ACT Answers'){
            setWhatDo('StudentACT')
            setDoing(<StudentACT/>)
        } else if (e.target.value === "Delete Test"){
            setWhatDo('DeleteTest')
            setDoing(<DeleteTest/>)
        } else if (e.target.value === "Delete Student"){
            setWhatDo('DeleteStudent')
            setDoing(<DeleteStudent/>)
        } else if (e.target.value === "Delete Parent"){
            setWhatDo('DeleteParent')
            setDoing(<DeleteParent/>)
        } else if (e.target.value === "Assign Digital SAT to Students"){
            setWhatDo("AssignDigitalSAT")
            setDoing(<AssignDigitalSAT/>)
        } else if (e.target.value === "Get Digital SAT Answers"){
            setWhatDo("GetDigitalSATAnswers")
            setDoing(<GetDigitalSATAnswers/>)
        }else if (e.target.value === "Create Homework Question"){
            setWhatDo("CreateHomeworkQuestion")
            setDoing(<MakeHomeworkQuestion/>)
        } else if (e.target.value === "Create Homework Bundle"){
            setWhatDo("CreateHomeworkBundle")
            setDoing(<MakeHomeworkBundle/>)
        } else if (e.target.value === "Assign Homework"){
            setWhatDo("Assign Homework")
            setDoing(<AssignHomework/>)
        } else if (e.target.value === "Get All Homework Bundles"){
            setWhatDo("GetAllHomeworkBundles")
            setDoing(<GetAllHomeworkBundles/>)
        } else if (e.target.value === "Delete Homework Question"){
            setWhatDo("DeleteHomeworkQuestion")
            setDoing(<DeleteHomeworkQuestion/>)
        } else if (e.target.value === "Delete Homework Bundle"){
            setWhatDo("DeleteHomeworkBundle")
            setDoing(<DeleteHomeworkBundle/>)
        } else if (e.target.value === "Give Vocab Submissions"){
            setWhatDo("GiveVocabSubmissions")
            setDoing(<GiveVocabSubmissions/>)
        }else{
            setWhatDo('');
            setDoing(<></>);
        }
    }

    return(
        <>
            <h1> Admin Page </h1>
            <div className="dashboard-links-div">
                <span>Click for: </span>
                <button onClick={()=>navigate("/Scheduler")}>Scheduler</button>
            </div>
            <div>
                Slect a service: 
                <select onChange={changeWhatDo}>
                    <option></option>
                    <option>Add Student</option>
                    <option>Add Parent</option>
                    <option>Add Teacher</option>
                    <option>Add Student (Schedule)</option>
                    <option>Schedule Students</option>
                    <option>Update Student</option>
                    <option>Get All Students</option>
                    <option>Get All Parents</option>
                    <option>Get All Tests</option>
                    <option>Get All Homework Bundles</option>
                    <option>Create ACT Answer Key</option>
                    <option>Create SAT Answer Key</option>
                    <option>Input Digital SAT Questions</option>
                    <option>Input Student SAT Answers</option>
                    <option>Input Student ACT Answers</option>
                    <option>Delete Test</option>
                    <option>Delete Student</option>
                    <option>Delete Parent</option>
                    <option>Assign Digital SAT to Students</option>
                    <option>Get Digital SAT Answers</option>
                    <option>Create Homework Question</option>
                    <option>Delete Homework Question</option>
                    <option>Create Homework Bundle</option>
                    <option>Delete Homework Bundle</option>
                    <option>Assign Homework</option>
                    <option>Give Vocab Submissions</option>
                </select>
            </div>
            <div>
                {doing}
            </div>
        </>
    )
}